.old-theme .table-style {
  width: 100%;
  overflow: hidden;
  overflow-x: auto;

  table {
    width: 100%;
    min-width: 870px;
    border-collapse: collapse;
    border: 0;
    border-right: 1px solid #ebebeb;
    border-left: 1px solid #ebebeb;
    vertical-align: middle;
    text-align: center;

    thead {
      th {
        background-color: $orange;
        padding: 22px 8px;
        font-size: 20px;
        color: $white;
        text-align: center;

        .text-large & {
          font-size: 22px;
        }
      }

      th:first-child {
        padding-left: 18px;
      }
      th:last-child {
        padding-right: 18px;
      }
    }

    tbody {
      td {
        background-color: #fff;
        border-bottom: 1px solid #ebebeb;
        padding: 18px 8px;
        font-size: 20px;

        .text-large & {
          font-size: 22px;
        }
      }

      td:first-child {
        padding-left: 18px;
      }
      td:last-child {
        padding-right: 18px;
      }

      tr:nth-of-type(even) td {
        background-color: #fafafa;
      }
    }
  }
}
