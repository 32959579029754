.career-page {
  html & .jspVerticalBar {
    &::before,
    &::after {
      background-color: #fff !important;
    }
  }

  &.-life-tij #footer,
  &.-jobs-opening #footer {
    margin-top: -108px;

    @media (max-width: 1023px) {
      margin-top: -40px;
    }
  }

  .old-theme {
    .title-career {
      background: #2f3844 url("../img/career/bg-title-career.jpg") 50% 50% /
        cover no-repeat;
      position: relative;

      .page-center {
        min-height: 360px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 40px 0;
      }

      h2 {
        margin-right: 130px;
        font-size: 60px;
        line-height: 0.7em;
        font-family: "db_ozone_x-bold";
        color: $orange;

        .text-large & {
          font-size: 62px;
        }
      }

      p {
        font-size: 22px;
        line-height: 1.6em;
        color: $white;

        .text-large & {
          font-size: 24px;
        }
      }
    }

    .remark {
      font-size: 22px;
      line-height: 1.5;

      .text-large & {
        font-size: 24px;
      }

      section + section {
        border-top: 1px solid #dce1e5;
        padding-top: 40px;
        margin-top: 40px;
      }

      h3 {
        margin-bottom: 5px;
        font-size: 1.1em;
        line-height: 1;
        font-family: "db_ozone_x-medium";
      }

      p strong {
        font-family: "db_ozone_x-medium";
        color: $orange;
      }
    }

    .group-box-content {
      position: relative;

      & > div {
        .img img {
          object-position: top;
        }
      }
    }

    .btn-load-more {
      width: 216px;
    }

    .list-content-employees {
      margin-top: 46px;

      .group-list {
        margin-top: -12px;
      }

      .row {
        width: calc(100% + 12px);
        display: flex;
        margin: 12px 0 0 -12px;
      }

      a {
        width: calc(25% - 6px);
        display: block;
        position: relative;
        margin: 0 0 0 12px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .row:nth-of-type(odd) {
        a:nth-of-type(1) {
          width: calc(50% - 6px);
        }
      }
      .row:nth-of-type(even) {
        a:nth-of-type(2) {
          width: calc(50% - 6px);
        }
      }

      .icon-play::before {
        width: 64px;
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid $white;
        border-radius: 50%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: 26px;
        text-indent: 5px;
        color: $white;
        z-index: 13;
      }
    }

    .content-job {
      background-color: #f5f8fb;
      padding-bottom: 80px;

      .page-center {
        flex-wrap: nowrap;
      }

      .title-page {
        h2 {
          font-family: "db_ozone_x-bold";
        }
      }

      .menu-left a {
        padding-top: 5px;
        font-size: 24px;

        .text-large & {
          font-size: 26px;
        }
      }

      .box-search-job {
        border: 1px solid #dce1e5;
        padding: 26px 22px;
        margin: 0;

        .icon-search {
          position: relative;

          &:before {
            position: absolute;
            left: 0;
            top: 18px;
            color: #b4b9bf;
          }
        }

        .inputtext-style {
          background-color: transparent;
          border: none;
          border-bottom: 1px solid #ebebeb;
          padding-right: 340px !important;
          padding-left: 40px;
          font-size: 30px;

          .text-large & {
            font-size: 32px;
          }
        }

        .sorting {
          display: flex;
          align-items: flex-end;
          position: absolute;
          right: 22px;
          top: 24px;

          label {
            margin: 0 20px 0 0;
            font-family: "db_ozone_x-bold";
          }

          .select-box {
            width: 240px;

            &:before {
              height: 36px;
            }

            .select-style {
              background: #f4f4f4;
              border: 2px solid #ebebeb;
              border-radius: 2px;
              padding-top: 6px;
              padding-bottom: 6px;
              padding-left: 20px;
            }
          }
        }
      }

      .primary-content {
        width: 870px;
        min-width: 870px;
      }

      .table-job {
        margin-top: 25px;

        h3 {
          margin-bottom: 36px;
          font-size: 30px;
          font-family: "db_ozone_x-medium";
          color: $orange;
          text-transform: uppercase;

          .text-large & {
            font-size: 32px;
          }
        }

        th:nth-of-type(1) {
          width: 160px;
        }
        th:nth-of-type(2) {
          width: 24px;
        }
        th:nth-of-type(3) {
          width: 200px;
        }
        th:nth-of-type(4) {
          width: 200px;
        }
        th:nth-of-type(5) {
          width: 70px;
        }
        th:nth-of-type(6) {
          width: 74px;
        }

        td:nth-of-type(1),
        td:nth-of-type(4) {
          text-align: left;
        }

        td:nth-of-type(1) {
          color: $orange;
        }

        .btn-style {
          width: 74px;
          min-width: inherit;
          padding: 6px 0;
          font-size: 16px;
        }
      }
    }

    .interns-detail {
      .page-center {
        display: flex;
        justify-content: space-between;

        & > div {
          width: 50%;
        }
      }

      .left {
        .img {
          position: relative;

          &:before,
          &:after {
            content: "";
            width: 126px;
            height: 8px;
            display: block;
            background-color: $orange;
            position: absolute;
            right: 8.5%;
            top: 0;
          }

          &:before {
            top: 17%;
          }
          &:after {
            top: 81%;
          }

          img {
            position: relative;
            margin: 0;
            z-index: 13;
          }
        }

        p {
          margin-top: -5%;
          padding: 0 12.3% 0 9.6%;
          font-size: 22px;
          line-height: 1.4em;

          .text-large & {
            font-size: 24px;
          }
        }
      }

      .right {
        margin-top: 50px;
        font-size: 22px;

        .text-large & {
          font-size: 24px;
        }

        h3 {
          margin-bottom: 30px;
          font-size: 74px;
          line-height: 0.7em;
          font-family: "db_ozone_x-bold";

          .text-large & {
            font-size: 76px;
          }

          span {
            color: $orange;
          }
        }
      }

      .editor {
        line-height: 1.4em;

        & > ol > li {
          margin-top: 40px;
          font-family: "db_ozone_x-bold";

          &:first-child {
            margin-top: 0;
          }
        }
        ol ul {
          margin-left: -18px;

          li {
            padding-left: 20px;
            margin-top: 10px;
            line-height: 1.2em;
            font-family: "db_ozone_x";

            &:before {
              width: 6px;
              height: 6px;
              background: none;
              border: 1px solid $orange;
              top: 9px;
            }
          }
        }
        ol ol li {
          margin-top: 10px;
          font-family: "db_ozone_x";
        }

        a {
          color: $orange;
        }

        .flex-content {
          display: flex;

          span:first-child {
            min-width: 180px;
          }
        }
      }

      .btn-apply {
        width: 182px;
        margin: 40px 0 0;
      }
    }

    .slide-interns {
      margin-top: 76px;

      .inner {
        border: 1px solid $orange;
        padding: 36px 46px;
        margin: 0 76px;

        a {
          width: calc(100% + 244px);
          display: block;
          position: relative;
          margin: 20px 0 30px -122px;
        }

        .icon-play::before {
          width: 84px;
          height: 84px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 2px solid $white;
          border-radius: 50%;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          font-size: 32px;
          text-indent: 5px;
          color: $white;
          z-index: 13;
        }

        h3 {
          font-size: 28px;
          font-family: "db_ozone_x-bold";

          .text-large & {
            font-size: 30px;
          }
        }
        p {
          font-size: 24px;

          .text-large & {
            font-size: 26px;
          }

          strong {
            font-family: "db_ozone_x-bold";
          }
        }
      }

      .owl-dots {
        position: relative;
        bottom: 0;
        margin-top: 30px;

        .owl-dot {
          background-color: #000;
        }
        .owl-dot.active {
          background-color: $orange;
        }
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .old-theme .content-job .page-center {
    flex-wrap: wrap !important;
  }

  .career-page .old-theme .title-career {
    .page-center {
      display: block;
    }

    p {
      margin-top: 20px;
    }
  }
}

@media screen and (max-width: 767px) {
  .career-page .old-theme .content-job {
    .box-search-job {
      .inputtext-style {
        padding-right: 0 !important;
      }

      .sorting {
        position: relative;
        right: auto;
        top: auto;
        margin-top: 20px;

        .select-box {
          width: 100%;
        }
      }
    }

    .table-job {
      h3 {
        margin-bottom: 20px;
      }
    }
  }

  .old-theme .interns-detail {
    .page-center {
      flex-wrap: wrap;

      & > div {
        width: 100%;
      }

      .left {
        order: 2;
      }
    }

    .btn-style {
      width: 100%;
    }
  }

  .old-theme .slide-interns {
    margin-top: 40px;

    .inner {
      padding: 20px;
      margin: 0 20px;

      a {
        width: calc(100% + 80px);
        margin-left: -40px;
      }
    }
  }
}

@media screen and (max-width: 479px) {
  .career-page .old-theme .slide-group-btn {
    margin-bottom: 80px;
  }

  .career-page .old-theme .title-career {
    .page-center {
      min-height: inherit;
      padding: 60px 0;
    }

    h2 {
      font-size: 45px;

      .text-large & {
        font-size: 47px;
      }
    }
  }

  .career-page .old-theme .list-content-employees {
    .row {
      width: calc(100% + 6px);
      margin-left: -6px;
    }
    .row + .row {
      margin-top: 6px;
    }

    a {
      width: calc(25% - 3px);
      margin-left: 6px;
    }

    .row:nth-of-type(odd) {
      a:nth-of-type(1) {
        width: calc(50% - 3px);
      }
    }
    .row:nth-of-type(even) {
      a:nth-of-type(2) {
        width: calc(50% - 3px);
      }
    }

    .icon-play:before {
      width: 40px;
      height: 40px;
      font-size: 14px;
    }
  }

  .old-theme .interns-detail {
    .left {
      margin-top: 40px;

      p {
        font-size: 20px;

        .text-large & {
          font-size: 22px;
        }
      }
    }

    .right {
      h3 {
        font-size: 50px;

        .text-large & {
          font-size: 52px;
        }
      }
    }

    .editor {
      .flex-content {
        display: block;

        span {
          display: block;

          br {
            display: none;
          }
        }
        span:first-child {
          width: 100%;
        }
      }
    }
  }

  .old-theme .slide-interns {
    .inner {
      margin: 0;

      h3 {
        font-size: 24px;

        .text-large & {
          font-size: 26px;
        }
      }
      p {
        font-size: 20px;

        .text-large & {
          font-size: 22px;
        }
      }

      a {
        width: 100%;
        margin-left: 0;
      }

      .icon-play:before {
        width: 60px;
        height: 60px;
        font-size: 20px;
      }
    }

    img {
      height: 160px;
      object-fit: cover;
    }
  }
}
