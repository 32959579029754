html .old-theme .owl-nav {
  width: 100%;
  max-width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  pointer-events: none;
  opacity: 1;
  transition: 0.25s ease;

  .disabled {
    opacity: 0.1 !important;
  }

  .owl-prev,
  .owl-next {
    width: 54px;
    height: 54px;
    background: url("../img/icons/arrow-left-black.svg") 50% 50% no-repeat;
    position: absolute;
    left: 0;
    top: 0;
    text-indent: -9999px;
    pointer-events: all;
  }

  .owl-next {
    background-image: url("../img/icons/arrow-right-black.svg");
    left: auto;
    right: 0;
  }
}

html .old-theme .owl-dots {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  bottom: 24px;
  pointer-events: none;

  .owl-dot {
    width: 9px;
    height: 9px;
    background-color: #000;
    border-radius: 50%;
    pointer-events: all;
  }

  .owl-dot + .owl-dot {
    margin-left: 10px;
  }

  .owl-dot.active {
    background-color: #fff;
  }
}

html .old-theme .owl-carousel .owl-video-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
