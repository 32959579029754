@charset "UTF-8";
@font-face {
  font-family: "db_ozone_x";
  src: url("./fonts/db_ozone_x/db_ozone_x_v3.2.1-webfont.eot");
  src: url("./fonts/db_ozone_x/db_ozone_x_v3.2.1-webfont.eot?#iefix") format("embedded-opentype"), url("./fonts/db_ozone_x/db_ozone_x_v3.2.1-webfont.woff2") format("woff2"), url("./fonts/db_ozone_x/db_ozone_x_v3.2.1-webfont.woff") format("woff"), url("./fonts/db_ozone_x/db_ozone_x_v3.2.1-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "db_ozone_x-italic";
  src: url("./fonts/db_ozone_x/db_ozone_x_it_v3.2.1-webfont.eot");
  src: url("./fonts/db_ozone_x/db_ozone_x_it_v3.2.1-webfont.eot?#iefix") format("embedded-opentype"), url("./fonts/db_ozone_x/db_ozone_x_it_v3.2.1-webfont.woff2") format("woff2"), url("./fonts/db_ozone_x/db_ozone_x_it_v3.2.1-webfont.woff") format("woff"), url("./fonts/db_ozone_x/db_ozone_x_it_v3.2.1-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "db_ozone_x-medium";
  src: url("./fonts/db_ozone_x/db_ozone_x_med_v3.2.1-webfont.eot");
  src: url("./fonts/db_ozone_x/db_ozone_x_med_v3.2.1-webfont.eot?#iefix") format("embedded-opentype"), url("./fonts/db_ozone_x/db_ozone_x_med_v3.2.1-webfont.woff2") format("woff2"), url("./fonts/db_ozone_x/db_ozone_x_med_v3.2.1-webfont.woff") format("woff"), url("./fonts/db_ozone_x/db_ozone_x_med_v3.2.1-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "db_ozone_x-medium_italic";
  src: url("./fonts/db_ozone_x/db_ozone_x_med_it_v3.2.1-webfont.eot");
  src: url("./fonts/db_ozone_x/db_ozone_x_med_it_v3.2.1-webfont.eot?#iefix") format("embedded-opentype"), url("./fonts/db_ozone_x/db_ozone_x_med_it_v3.2.1-webfont.woff2") format("woff2"), url("./fonts/db_ozone_x/db_ozone_x_med_it_v3.2.1-webfont.woff") format("woff"), url("./fonts/db_ozone_x/db_ozone_x_med_it_v3.2.1-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "db_ozone_x-bold";
  src: url("./fonts/db_ozone_x/db_ozone_x_bd_v3.2.1-webfont.eot");
  src: url("./fonts/db_ozone_x/db_ozone_x_bd_v3.2.1-webfont.eot?#iefix") format("embedded-opentype"), url("./fonts/db_ozone_x/db_ozone_x_bd_v3.2.1-webfont.woff2") format("woff2"), url("./fonts/db_ozone_x/db_ozone_x_bd_v3.2.1-webfont.woff") format("woff"), url("./fonts/db_ozone_x/db_ozone_x_bd_v3.2.1-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "db_ozone_x-bold_italic";
  src: url("./fonts/db_ozone_x/db_ozone_x_bd_it_v3.2.1-webfont.eot");
  src: url("./fonts/db_ozone_x/db_ozone_x_bd_it_v3.2.1-webfont.eot?#iefix") format("embedded-opentype"), url("./fonts/db_ozone_x/db_ozone_x_bd_it_v3.2.1-webfont.woff2") format("woff2"), url("./fonts/db_ozone_x/db_ozone_x_bd_it_v3.2.1-webfont.woff") format("woff"), url("./fonts/db_ozone_x/db_ozone_x_bd_it_v3.2.1-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
* {
  -webkit-tap-highlight-color: transparent;
  text-rendering: optimizeLegibility;
  outline: none;
}

html .old-theme {
  font-size: 62.5%;
}

html.text-large .old-theme {
  font-size: 72.5%;
}

.old-theme #wrapper * {
  box-sizing: border-box;
}
.old-theme #wrapper *::before, .old-theme #wrapper *::after {
  box-sizing: border-box;
}
.old-theme h1,
.old-theme h2,
.old-theme h3,
.old-theme h4,
.old-theme h5,
.old-theme h6 {
  margin: 0;
  font-weight: normal;
  font-size: 1em;
  line-height: 1;
}
.old-theme ul,
.old-theme ol {
  padding: 0;
  margin: 0;
  list-style: none;
}
.old-theme blockquote {
  margin: 0 0 18px 18px;
  color: #666;
  font-style: italic;
}
.old-theme strong {
  font-weight: normal !important;
}
.old-theme em {
  font-style: italic;
}
.old-theme p {
  margin: 0;
}
.old-theme p + p {
  margin-top: 15px;
}
.old-theme a {
  transition: color 0.15s ease;
  overflow-wrap: break-word;
  cursor: pointer;
}
.old-theme a:link, .old-theme a:visited {
  color: inherit;
  text-decoration: none;
}
.desktop .old-theme a:hover, .old-theme a:active {
  color: #ed7725;
}
.old-theme label,
.old-theme button {
  cursor: pointer;
}
.old-theme img {
  max-width: 100%;
  display: block;
  margin: 0 auto;
}
.old-theme .img-left {
  float: left;
  margin: 20px 20px 20px 0;
}
.old-theme .img-right {
  float: right;
  margin: 20px 0 20px 20px;
}
.old-theme .align-right {
  text-align: right;
}
.old-theme .align-left {
  text-align: left;
}
.old-theme .align-center {
  text-align: center;
}
.old-theme .align-justify {
  text-align: justify;
}
.old-theme .flex-column {
  flex-direction: column;
  align-items: center;
}
.old-theme .flex-center {
  justify-content: center;
}
.old-theme .flex-end {
  display: flex;
  justify-content: flex-end;
}
.old-theme .flex-end .btn-style {
  margin: 0;
}
.old-theme .clear-bold {
  font-weight: 400;
}
.old-theme .clear, .old-theme .form-style .items .item::after, .old-theme .clear-float::after {
  content: "";
  height: 0px;
  display: block;
  padding: 0;
  margin: 0;
  line-height: 0;
  clear: both;
  overflow: hidden;
  visibility: hidden;
}
.old-theme .desktop-style {
  display: block !important;
}
.old-theme .mobile-style {
  display: none !important;
}
.old-theme .page-center {
  width: 1180px;
  max-width: calc(100% - 30px);
  height: 100%;
  position: relative;
  margin: 0 auto;
}
.old-theme #wrapper {
  min-height: 100vh;
  height: 100%;
  position: relative;
  margin: 0 auto;
  overflow: hidden;
}
.old-theme #header,
.old-theme #container,
.old-theme #footer {
  width: 100%;
  position: relative;
  padding: 0;
  margin: 0;
  overflow: hidden;
}
.old-theme #container {
  min-height: calc(100vh - 136px);
  margin-top: 136px;
  overflow: visible;
}
.old-theme #header {
  height: 142px;
  max-height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 131313;
  overflow: visible;
  transition: 0.25s ease;
}
.old-theme #header::before {
  content: "";
  width: 100%;
  height: 94px;
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 42px;
  z-index: 13;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16);
}
.old-theme #header .page-center {
  width: calc(100% - 180px);
  height: 94px;
  z-index: 13;
}
.old-theme #header .head-top {
  width: 100%;
  height: 42px;
  background-color: #ed7725;
  position: relative;
  z-index: 13131313;
}
.old-theme #header .head-top .page-center {
  height: 100%;
  display: flex;
  align-items: center;
}
.old-theme #header .menu-top {
  max-width: calc(100% - 154px);
  height: 100%;
  position: relative;
  padding-right: 30px;
  margin-left: 0;
  font-size: 20px;
}
.old-theme #header .menu-top .group-btn {
  width: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
  overflow-x: auto;
}
.old-theme #header .menu-top::after {
  content: "h";
  width: 30px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: #ed7725;
  position: absolute;
  right: 0;
  top: 0;
  color: #fff;
}
.old-theme #header .menu-top a {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  white-space: nowrap;
  color: #fff;
}
.old-theme #header .menu-top a::after {
  content: "";
  width: 0;
  height: 0;
  display: none;
  border-bottom: 10px solid #fff;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  position: absolute;
  left: 50%;
  bottom: -3px;
  transform: translateX(-50%);
}
.old-theme #header .menu-top a.active::after {
  display: block;
}
.old-theme #header .menu-top a + a {
  margin-left: 40px;
}
.old-theme #header .text-size,
.old-theme #header .language {
  display: flex;
}
.old-theme #header .text-size a,
.old-theme #header .language a {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(255, 255, 255, 0.5);
  position: relative;
  font-size: 17px;
  color: #fff;
  transition: 0.125s ease;
  overflow: hidden;
}
.old-theme #header .text-size a::before,
.old-theme #header .language a::before {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 100%;
  transition: 0.1s ease;
}
.old-theme #header .text-size a span,
.old-theme #header .language a span {
  display: block;
  position: relative;
  z-index: 13;
}
.desktop .old-theme #header .text-size a:hover, .old-theme #header .text-size a.active,
.desktop .old-theme #header .language a:hover,
.old-theme #header .language a.active {
  border-color: #fff;
  color: #ed7725;
}
.desktop .old-theme #header .text-size a:hover::before, .old-theme #header .text-size a.active::before,
.desktop .old-theme #header .language a:hover::before,
.old-theme #header .language a.active::before {
  top: 0;
}
.old-theme #header .text-size a + a,
.old-theme #header .language a + a {
  margin-left: -1px;
}
.old-theme #header .language {
  margin-left: 16px;
}
.old-theme #header h1 {
  height: 100%;
  display: inline-block;
  font-size: 30px;
}
.old-theme #header h1 a {
  height: 100%;
  display: flex;
  align-items: center;
}
.old-theme #header h1 a::before {
  content: "";
  width: 94px;
  height: 67px;
  display: block;
  background: url("../img/share/logo-tij.png") 0 0/100% no-repeat;
}
.old-theme #header h1 span {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  padding: 5px 0 0 22px;
  margin-left: 28px;
}
.old-theme #header h1 span::before {
  content: "";
  width: 1px;
  height: 42px;
  display: block;
  background-color: #eee;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.old-theme #header h1 span small {
  width: 100%;
  display: block;
  font-size: 0.7em;
}
.old-theme #header .group-btn {
  height: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
}
.old-theme #header .group-btn .btn-search {
  max-width: 18px;
  height: 20px;
  position: absolute;
  right: 146px;
  top: 37px;
  padding-top: 2px;
  padding-left: 20px;
  font-size: 22px;
  font-family: "db_ozone_x-bold";
  color: #000;
  white-space: nowrap;
  transition: 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  overflow: hidden;
}
.old-theme #header .group-btn .btn-search::before {
  position: absolute;
  left: 0;
  top: 2px;
  font-size: 18px;
  color: #ed7725;
}
.desktop .old-theme #header .group-btn .btn-search:hover {
  max-width: 100%;
  padding-left: 32px;
}
.old-theme #header .group-btn .hamburger {
  width: 123px;
  height: 24px;
  position: relative;
  margin-left: 22px;
}
.old-theme #header .group-btn .hamburger::before {
  content: "";
  width: 1px;
  height: 42px;
  display: block;
  background-color: #eee;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.old-theme #header .group-btn .hamburger i {
  height: 23px;
  display: block;
  position: absolute;
  left: 22px;
  top: 0;
  font-size: 24px;
  color: #ed7725;
  transition: 0.15s ease;
}
.old-theme #header .group-btn .hamburger .icon-hamburger-open {
  transform: scale(0);
}
.open-menu .old-theme #header .group-btn .hamburger .icon-hamburger {
  transform: scale(0);
}
.open-menu .old-theme #header .group-btn .hamburger .icon-hamburger-open {
  transform: scale(1);
}
.old-theme #header .group-btn .hamburger span {
  display: block;
  position: absolute;
  left: 64px;
  top: 2px;
  font-size: 22px;
  transition: 0.15s ease;
}
.old-theme #header .group-btn .hamburger span.open {
  transform: translateX(-10px);
  opacity: 0;
}
.open-menu .old-theme #header .group-btn .hamburger span {
  transform: translateX(-10px);
  opacity: 0;
}
.open-menu .old-theme #header .group-btn .hamburger span.open {
  transform: translateX(0);
  opacity: 1;
}
.old-theme #header .box-mainmenu {
  width: 100vw;
  min-height: calc(100vh - 94px);
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  position: relative;
  transform: translateY(-100%);
  overflow: hidden;
  transition: 0.35s cubic-bezier(1, 0, 0, 1);
}
.old-theme #header .box-mainmenu .img {
  width: calc(100% - 510px);
  position: relative;
}
.old-theme #header .box-mainmenu .img div {
  width: 100%;
  height: 100%;
  background: 50% 0/cover no-repeat;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  transform: scale(1.1);
  visibility: hidden;
  transition: 0.25s ease;
}
.old-theme #header .box-mainmenu .img div.show {
  opacity: 1;
  transform: scale(1);
  visibility: visible;
}
.old-theme #header .box-mainmenu .mainmenu {
  min-width: 510px;
  background-color: #6e7175;
  position: relative;
  font-size: 30px;
  font-family: "db_ozone_x-medium";
  color: #fff;
}
.old-theme #header .box-mainmenu .mainmenu ul {
  width: calc(100% - 176px);
  margin: 60px auto;
}
.old-theme #header .box-mainmenu .mainmenu ul li {
  text-transform: uppercase;
}
.old-theme #header .box-mainmenu .mainmenu ul li > a {
  width: 100%;
  display: block;
  position: relative;
  padding: 15px 0;
}
.old-theme #header .box-mainmenu .mainmenu ul li > a::before {
  content: "g";
  position: absolute;
  left: -45px;
  top: 16px;
  font-size: 24px !important;
  color: #ed7725;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.old-theme #header .box-mainmenu .mainmenu ul li > a::after {
  content: "";
  width: 30px;
  height: 2px;
  display: none;
  background-color: #c2c2c2;
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -1px;
  transition: 0.25s ease;
}
.desktop .old-theme #header .box-mainmenu .mainmenu ul a:hover,
.old-theme #header .box-mainmenu .mainmenu ul a.active {
  color: #ed7725;
}
.desktop .old-theme #header .box-mainmenu .mainmenu ul a:hover::after,
.old-theme #header .box-mainmenu .mainmenu ul a.active::after {
  width: 50px;
  background-color: #fff;
  right: 0;
}
.old-theme #header .box-mainmenu .mainmenu .submenu {
  width: 260px;
  height: 100%;
  background-color: #898989;
  position: absolute;
  left: -240px;
  top: 0;
  padding: 140px 25px 0 25px;
  font-size: 2.4rem;
  opacity: 0;
  visibility: hidden;
  transition: 0.25s ease;
}
.old-theme #header .box-mainmenu .mainmenu .submenu a {
  display: block;
}
.old-theme #header .box-mainmenu .mainmenu .submenu a + a {
  margin-top: 40px;
}
.old-theme #header .box-mainmenu .mainmenu .show-sub > a {
  color: #ed7725;
}
.old-theme #header .box-mainmenu .mainmenu .show-sub > a::before {
  left: -55px;
  opacity: 1;
  visibility: visible;
}
.old-theme #header .box-mainmenu .mainmenu .show-sub > a::after {
  width: 50px;
  background-color: #fff;
  right: 0;
}
.old-theme #header .box-mainmenu .mainmenu .show-sub .submenu {
  left: -260px;
  opacity: 1;
  visibility: visible;
}
.old-theme #header .section-search {
  width: 100vw;
  height: calc(100vh - 94px);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #6e7175;
  position: absolute;
  left: 0;
  top: 94px;
  transform: translateY(-100%);
  overflow: hidden;
  transition: 0.35s cubic-bezier(1, 0, 0, 1);
}
.old-theme #header .section-search .form-style {
  width: 920px;
  max-width: calc(100% - 30px);
}
.old-theme #header .section-search .inputtext-style {
  background: none;
  border: none;
  border-bottom: 1px solid #fff;
  border-radius: 0;
  padding: 0;
  text-align: center;
  font-size: 60px;
  line-height: 1.6;
  font-family: "db_ozone_x-medium";
  color: #fff;
}
.old-theme #header .section-search .inputtext-style::-webkit-input-placeholder {
  color: #fff;
}
.old-theme #header .section-search .inputtext-style::-moz-placeholder {
  color: #fff;
}
.old-theme #header .section-search .inputtext-style:-ms-input-placeholder {
  color: #fff;
}
.old-theme #header .section-search .inputtext-style:-moz-placeholder {
  color: #fff;
}
.old-theme #header .section-search .textarea-style::-webkit-input-placeholder {
  color: #fff;
}
.old-theme #header .section-search .textarea-style::-moz-placeholder {
  color: #fff;
}
.old-theme #header .section-search .textarea-style:-ms-input-placeholder {
  color: #fff;
}
.old-theme #header .section-search .textarea-style:-moz-placeholder {
  color: #fff;
}
.old-theme .open-menu {
  overflow: hidden;
}
.old-theme .open-menu #header {
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}
.old-theme .open-menu #header .box-mainmenu {
  transform: translateY(0);
}
.old-theme .open-search #header .section-search {
  transform: translateY(0);
}
.old-theme #footer {
  background-color: #333;
  padding: 0;
  margin-top: 95px;
  color: #fff;
  z-index: 1313;
}
.old-theme #footer .page-center {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 0;
}
.old-theme #footer .menu-footer ul {
  display: flex;
  align-items: center;
  font-size: 22px;
}
.old-theme #footer .menu-footer ul li + li {
  margin-left: 38px;
}
.old-theme #footer .menu-footer ul .active {
  color: #ed7725;
}
.old-theme #footer .group-social {
  display: flex;
  align-items: center;
}
.old-theme #footer .group-social a {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ed7725;
  border-radius: 50%;
  transition: 0.15s ease;
}
.old-theme #footer .group-social a::before {
  display: block;
  font-size: 20px;
}
.desktop .old-theme #footer .group-social a:hover {
  background-color: #ed7725;
  color: #333;
}
.old-theme #footer .group-social a + a {
  margin-left: 12px;
}
.old-theme #footer .section-bottom {
  position: relative;
}
.old-theme #footer .section-bottom::before {
  content: "";
  width: 200vw;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  left: -100vw;
  top: 0;
}
.old-theme #footer .section-bottom p {
  font-size: 18px;
}
.old-theme #footer .section-bottom p + p {
  margin: 8px 0 0;
}
@media only screen and (min-width: 1400px) {
  .old-theme #header .box-mainmenu .mainmenu ul {
    margin: 120px auto;
  }
}
@media only screen and (max-width: 1024px) {
  .old-theme #header .page-center {
    width: 100%;
  }
}
@media only screen and (max-width: 1023px) {
  .old-theme #header h1 {
    font-size: 2.4rem;
  }
  .old-theme #header h1 a::before {
    width: 84px;
    height: 60px;
  }
  .old-theme #header .img {
    display: none;
  }
  .old-theme #header .box-mainmenu .mainmenu {
    width: 100%;
    min-width: inherit;
  }
  .old-theme #header .box-mainmenu .mainmenu li > a::before {
    display: none;
  }
  .old-theme #header .box-mainmenu .mainmenu .submenu {
    width: 100%;
    display: none;
    position: relative;
    left: 0;
    top: 0;
    padding: 15px;
    margin: 10px 0 40px;
  }
  .old-theme #header .box-mainmenu .mainmenu .submenu a + a {
    margin-top: 20px;
  }
  .old-theme #header .box-mainmenu .mainmenu .show-sub .submenu {
    display: block;
    left: 0;
  }
  .old-theme #footer .page-center {
    flex-direction: column;
    text-align: center;
  }
  .old-theme #footer .menu-footer {
    width: 80%;
  }
  .old-theme #footer .menu-footer ul {
    flex-wrap: wrap;
    justify-content: center;
  }
  .old-theme #footer .menu-footer ul li {
    margin: 5px 0;
  }
  .old-theme #footer .group-social {
    margin-top: 20px;
  }
  .old-theme #footer .copyright {
    width: 100%;
    margin-top: 8px;
  }
}
@media only screen and (max-width: 767px) {
  .old-theme #header h1 span {
    display: none;
  }
  .old-theme #header .menu-top a + a {
    margin-left: 20px;
  }
  .old-theme #header .box-mainmenu .mainmenu ul {
    width: calc(100% - 30px);
    margin: 15px auto;
  }
  .old-theme #header .section-search .inputtext-style {
    font-size: 40px;
    line-height: 1;
  }
  .old-theme #footer .menu-footer {
    width: 85%;
  }
  .old-theme #footer .menu-footer ul {
    flex-wrap: wrap;
    justify-content: center;
  }
  .old-theme #footer .menu-footer ul li {
    margin: 5px 0;
  }
}
@media screen and (max-width: 479px) {
  .old-theme #header h1 a::before {
    width: 74px;
    height: 53px;
  }
  .old-theme #header .menu-top {
    max-width: calc(100% - 122px);
    margin: 0;
    font-size: 18px;
  }
  .old-theme #header .menu-top a + a {
    margin-left: 15px;
  }
  .old-theme #header .text-size a,
  .old-theme #header .language a {
    width: 26px;
    height: 26px;
    font-size: 16px;
  }
  .old-theme #header .language {
    margin-left: 10px;
  }
  .old-theme #header .box-mainmenu .mainmenu {
    font-size: 20px;
  }
  .old-theme #header .box-mainmenu .mainmenu .submenu {
    font-size: 1.8rem;
  }
  .old-theme #header .group-btn .btn-search {
    right: 68px;
  }
  .old-theme #header .group-btn .hamburger {
    width: 48px;
  }
  .old-theme #header .group-btn .hamburger span {
    display: none;
  }
  .old-theme #footer .menu-footer ul {
    flex-direction: column;
    font-size: 20px;
  }
  .old-theme #footer .menu-footer ul li + li {
    margin: 15px 0 0;
  }
  .old-theme #footer .group-social {
    margin-top: 30px;
  }
  .old-theme #footer .section-bottom {
    font-size: 16px;
  }
  .old-theme #footer .section-bottom p {
    font-size: 16px;
  }
}

.add-font-icon, .search-page .list-search .item .detail a::after, .corporate-materials-page .content-corporate-materials .content-newsletter .list-newsletter .item a .text::after, .areas-of-work-page .content-areas-of-work .list-areas-of-work .item a .text::after, .management-page .group-link a::before, .home-page .section.-key-projects .box-img .control a::before, .home-page .section.-key-projects .box-img .text.video > a::before, .home-page .section.-key-projects .nav-control div::before, .home-page .section.-upcoming-events .nav-control div::before, .home-page .section.-highlights .slide-highlights .nav-control .prev::before,
.home-page .section.-highlights .slide-highlights .nav-control .next::before, html .fancybox-nav.fancybox-next span::before, html .fancybox-nav.fancybox-prev span::before, html .fancybox-close::before, .old-theme .form-style .radio-style label::after, .old-theme .form-style .checkbox-style label::after, .old-theme .form-style .select-box::before, .old-theme .content-logo .list-logo .item a .text::after, .old-theme .box-search-job button::before, .old-theme .design-row-style .-play::before, .old-theme [class*=layout-col] .menu-left a::before, .old-theme [class*=layout-col] .menu-left .btn-active::after, .text-view-all::after, .btn-read-more::after, .btn-load-more::after {
  font-weight: normal !important;
  font-family: "tij" !important;
  text-transform: none !important;
}

.orange {
  color: #ed7725 !important;
}

.black {
  color: #000 !important;
}

.editor ul,
.list-bullet {
  padding: 0;
  margin: 10px 0;
  list-style: none;
}
.editor ul > li,
.list-bullet > li {
  position: relative;
  padding-left: 15px;
  margin-top: 15px;
}
.editor ul > li li,
.list-bullet > li li {
  font-size: 0.9em;
}
.editor ul > li li::before,
.list-bullet > li li::before {
  width: 5px;
  height: 5px;
  background-color: #000 !important;
  top: 6px;
}
.editor ul > li:first-child,
.list-bullet > li:first-child {
  margin-top: 0;
}
.editor ul > li::before,
.list-bullet > li::before {
  content: "";
  width: 7px;
  height: 7px;
  display: block;
  background-color: #000;
  border-radius: 100%;
  position: absolute;
  left: 0;
  top: 7px;
  text-indent: -9999px;
}
.editor ul.-orange > li::before,
.list-bullet.-orange > li::before {
  background-color: #ed7725;
}
.editor ul.-circle > li::before,
.list-bullet.-circle > li::before {
  background: none;
  border: 1px solid #000;
}

.editor ol,
.list-number {
  padding: 0;
  margin: 5px 0;
  list-style: none;
  counter-reset: number;
}
.editor ol > li,
.list-number > li {
  position: relative;
  padding-left: 20px;
  margin-top: 8px;
}
.editor ol > li:first-child,
.list-number > li:first-child {
  margin-top: 0;
}
.editor ol > li::before,
.list-number > li::before {
  counter-increment: number;
  content: counters(number, ".") ".";
  position: absolute;
  left: 0;
  top: 0;
}
.editor ol .list-number li, .editor ol ol li,
.list-number .list-number li,
.list-number ol li {
  padding-left: 32px;
}
.editor ol .list-number li::before,
.list-number .list-number li::before {
  content: counters(number, ".") "";
}

.hover-img {
  display: block;
  overflow: hidden;
}
.hover-img img {
  transition: 0.25s ease;
}
.desktop .hover-img:hover img {
  transform: scale(1.05);
}

.btn-style {
  min-width: 216px;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e37835;
  border: none;
  border-radius: 0;
  padding: 15px 30px;
  margin: 0 auto;
  font-size: 22px;
  color: #fff !important;
  transition: 0.25s ease;
}
.desktop .btn-style:hover {
  background-color: #000;
}
.btn-style.-orange {
  background-color: #ed7725;
}
.btn-style.-dark-gray {
  background-color: #777779;
}

.btn-view-all {
  height: 37px;
  display: flex;
  align-items: center;
  position: relative;
  padding-right: 47px;
  font-size: 21px;
  font-family: "db_ozone_x-medium";
}
.btn-view-all::before {
  width: 37px;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid #ed7725;
  border-radius: 50%;
  position: absolute;
  right: 0;
  top: 0;
  font-size: 15px;
  color: #ed7725;
  transition: 0.15s ease;
}
.desktop .btn-view-all:hover::before {
  background-color: #ed7725;
  color: #fff;
}

.btn-load-more {
  margin-top: 70px;
}
.btn-load-more::after {
  content: "q";
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #fff;
  border-radius: 50%;
  margin-left: 8px;
  font-size: 14px;
}

.btn-read-more {
  display: flex;
  align-items: center;
  font-size: 28px;
  color: #fff;
}
.btn-read-more::after {
  content: "h";
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid #fff;
  border-radius: 50%;
  margin-left: 14px;
  font-size: 0.7em;
  text-indent: 3px;
  transition: 0.15s ease;
}
.desktop .btn-read-more:hover {
  color: #fff;
}
.desktop .btn-read-more:hover::after {
  transform: scale(1.15);
  color: #fff;
}

.text-view-all {
  font-size: 20px;
  padding-top: 5px;
}
.text-view-all::after {
  content: "h";
  position: relative;
  top: -2px;
  margin-left: 10px;
  font-size: 20px;
  transition: 0.15s ease;
}
.desktop .text-view-all:hover::after {
  margin-left: 15px;
}

.section .page-center {
  margin-top: 50px;
}
.section .page-center::before {
  content: "";
  width: 74px;
  height: 37px;
  background-color: #ed7725;
  position: absolute;
  left: -104px;
  top: 80px;
}
.section .page-center::after {
  content: "";
  width: 52px;
  height: 9px;
  background-color: #ccc;
  position: absolute;
  left: 0;
  top: 80px;
}
.section .head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.old-theme .banner-page {
  height: calc(100vh - 94px);
  position: relative;
  overflow: hidden;
}
.old-theme .banner-page::before {
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 0;
  top: 0;
}
.old-theme .banner-page img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.ie .old-theme .banner-page img {
  height: auto;
}
.old-theme .banner-page .box-text {
  position: absolute;
  left: 84px;
  top: 50%;
  transform: translateY(-50%);
  transform-origin: left;
  color: #fff;
  font-size: 32px;
  margin-top: 0 !important;
}
.old-theme .banner-page .box-text .breadcrumbs {
  margin-bottom: 25px;
}
.old-theme .banner-page .box-text .breadcrumbs a {
  margin: 0;
  font-size: 0.72em;
  text-transform: uppercase;
  color: #d2d0d0;
}
.old-theme .banner-page .box-text h1 {
  margin-bottom: 30px;
  font-size: 2.8125em;
  line-height: 0.9;
  font-family: "db_ozone_x-medium";
  text-transform: uppercase;
}
.old-theme .banner-page .box-text a {
  display: flex;
  align-items: center;
  margin-top: 100px;
  font-size: 0.75em;
  font-family: "db_ozone_x-medium";
  color: #fff;
}
.old-theme .banner-page .box-text a::before {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #fff;
  border-radius: 50%;
  margin-right: 20px;
}
.old-theme .banner-page .box-text .btn-style {
  width: 220px;
  height: 60px;
  margin-left: 0;
}
.old-theme .title-section {
  font-size: 60px;
  font-family: "db_ozone_x-bold";
  color: #2c2c2c;
}
.old-theme .list-content {
  width: calc(100% + 35px);
  display: flex;
  flex-wrap: wrap;
  margin: -35px 0 0 -35px;
}
.old-theme .list-content .item {
  width: calc(25% - 35px);
  margin: 35px 0 0 35px;
}
.old-theme .list-content .item .img {
  position: relative;
}
.old-theme .list-content .item .img img {
  width: 100%;
}
.old-theme .list-content .item .img span {
  width: 117px;
  height: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  position: absolute;
  right: 0;
  bottom: 0;
  padding-top: 5px;
  font-size: 16px;
  color: #002a64;
}
.old-theme .list-content .item .img span::before {
  position: relative;
  top: -2px;
  margin-right: 8px;
  font-size: 16px;
}
.old-theme .list-content .item .text {
  margin-top: 16px;
}
.old-theme .list-content .item .text .date {
  margin-bottom: 5px;
  font-size: 14px;
  color: #c6c5c5;
}
.old-theme .list-content .item .text h3 {
  min-height: 38px;
  margin: 0 0 6px;
  font-size: 19px;
}
.text-large .old-theme .list-content .item .text h3 {
  font-size: 21px;
}
.old-theme .list-content .item .text a {
  font-size: 16px;
  color: #ed7725;
}
.text-large .old-theme .list-content .item .text a {
  font-size: 18px;
}
.desktop .old-theme .list-content .item .text a:hover {
  color: #000;
}
.old-theme .list-content.col3 {
  width: calc(100% + 40px);
  margin: -40px 0 0 -40px;
}
.old-theme .list-content.col3 .item {
  width: calc(33.33% - 40px);
  margin: 40px 0 0 40px;
}
.old-theme .list-content.slide-content {
  margin: 0;
}
.old-theme .list-content.slide-content .owl-dots {
  position: relative;
  bottom: 0;
  margin-top: 30px;
}
.old-theme .list-content.slide-content .owl-dots .owl-dot {
  background-color: #ed7725;
}
.old-theme .list-content.slide-content .owl-dots .owl-dot.active {
  background-color: #000;
}
.old-theme .list-content .owl-carousel .item {
  width: 100%;
  margin: 0;
}
.old-theme [class*=layout-col] {
  padding-top: 70px;
}
.old-theme [class*=layout-col] .page-center {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.old-theme [class*=layout-col] .title-page {
  width: 100%;
  margin-bottom: 60px;
  font-size: 90px;
}
.text-large .old-theme [class*=layout-col] .title-page {
  font-size: 92px;
}
.old-theme [class*=layout-col] .title-page h1 {
  font-size: 1em;
  font-family: "db_ozone_x-medium";
  color: #2f3844;
}
.old-theme [class*=layout-col] .title-page h2 {
  font-size: 0.6666em;
  font-family: "db_ozone_x-medium";
  color: #2f3844;
}
.old-theme [class*=layout-col] .title-page p {
  margin-top: -10px;
  font-size: 0.356em;
}
.old-theme [class*=layout-col] .heading {
  width: 270px;
  font-size: 60px;
}
.text-large .old-theme [class*=layout-col] .heading {
  font-size: 62px;
}
.old-theme [class*=layout-col] .heading h2 {
  font-size: 1em;
  line-height: 0.8;
  font-family: "db_ozone_x-bold";
}
.old-theme [class*=layout-col] .menu-left {
  width: 320px;
}
.old-theme [class*=layout-col] .menu-left .btn-active {
  width: 100%;
  height: 62px;
  display: none;
  align-items: center;
  border-bottom: 1px solid #dce1e5;
  position: relative;
  padding: 0 45px 0 10px;
  color: #2c2c2c;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.old-theme [class*=layout-col] .menu-left .btn-active::after {
  content: "q";
  width: 40px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
}
.old-theme [class*=layout-col] .menu-left a {
  min-height: 28px;
  display: block;
  position: relative;
  padding-left: 52px;
  font-size: 32px;
  color: #b4b9bf;
}
.text-large .old-theme [class*=layout-col] .menu-left a {
  font-size: 34px;
}
.old-theme [class*=layout-col] .menu-left a::before {
  content: "j";
  width: 28px;
  height: 28px;
  display: none;
  align-items: center;
  justify-content: center;
  border: 1px solid #b6bbc1;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 0;
  font-size: 7px;
  color: #ed7725;
}
.desktop .old-theme [class*=layout-col] .menu-left a:hover {
  color: #2c2c2c;
}
.old-theme [class*=layout-col] .menu-left a + a {
  margin-top: 25px;
}
.old-theme [class*=layout-col] .menu-left a.active {
  color: #2c2c2c;
}
.old-theme [class*=layout-col] .menu-left a.active::before {
  display: flex;
}
.old-theme [class*=layout-col] .design-row-style {
  min-width: 800px;
}
.old-theme .layout-col1 .page-center {
  flex-direction: column;
  align-items: center;
}
.old-theme .layout-col1 .heading {
  width: 100%;
  margin-bottom: 55px;
  text-align: center;
}
.old-theme .layout-col1 .heading p {
  width: 65%;
  margin: 15px auto 0;
  font-size: 0.4em;
  line-height: 1.3;
}
.old-theme .listing-in-page .title-page {
  margin-bottom: 80px;
}
.old-theme .listing-in-page .list-content {
  position: relative;
}
.old-theme .listing-in-page .list-content::before {
  content: "";
  width: 74px;
  height: 37px;
  display: block;
  background-color: #ed7725;
  position: absolute;
  left: -68px;
  top: 40px;
}
.old-theme .underline {
  position: relative;
  padding-bottom: 24px;
  text-decoration: none !important;
}
.old-theme .underline::before {
  content: "";
  width: 52px;
  height: 6px;
  display: block;
  background-color: #000;
  position: absolute;
  left: 0;
  bottom: 0;
}
.old-theme .underline.-center::before {
  left: 50%;
  margin-left: -26px;
}
.old-theme .underline.-light-gray::before {
  background-color: #ccc;
}
.old-theme .underline.-orange::before {
  background-color: #ed7725;
}
.old-theme .underline.-dark-blue::before {
  background-color: #002a64;
}
.old-theme .design-row-style {
  width: 800px;
  max-width: 100%;
  margin-top: 12px;
  font-size: 22px;
}
.text-large .old-theme .design-row-style {
  font-size: 24px;
}
.old-theme .design-row-style .item {
  border-bottom: 1px solid #dce1e5;
  padding-bottom: 26px;
  margin-bottom: 26px;
}
.old-theme .design-row-style .item:last-child {
  border: none;
  padding-bottom: 0;
  margin-bottom: 0;
}
.old-theme .design-row-style a {
  font-size: 1em;
  color: #000;
}
.old-theme .design-row-style a span {
  display: block;
  color: #8d959f;
}
.old-theme .design-row-style .-pdf {
  background: url("../img/icons/file-pdf.png") 0 0/38px no-repeat;
  padding-left: 64px;
}
.old-theme .design-row-style .-excel {
  background: url("../img/icons/file-excel.png") 0 0/38px no-repeat;
  padding-left: 64px;
}
.old-theme .design-row-style .-play {
  min-height: 28px;
  position: relative;
  padding-left: 58px;
}
.old-theme .design-row-style .-play::before {
  content: "j";
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ed7725;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 0;
  font-size: 7px;
  color: #ed7725;
}
.old-theme .img-banner {
  width: 100%;
  height: 390px;
  background: 50% 0/cover no-repeat;
  margin: 74px 0;
}
.old-theme .box-shadow, .old-theme .home-page .section.-key-projects .box-img, .home-page .section.-key-projects .old-theme .box-img, .old-theme .home-page .section.-upcoming-events .content-upcoming-events .slide-upcoming-events .item, .home-page .section.-upcoming-events .content-upcoming-events .slide-upcoming-events .old-theme .item {
  box-shadow: 0 6px 5px rgba(0, 0, 0, 0.17);
}
.old-theme .box-search-job {
  position: relative;
  margin-bottom: 48px;
}
.old-theme .box-search-job .inputtext-style {
  height: 58px;
  border-color: #dce1e5 !important;
  padding-right: 70px !important;
  font-size: 24px !important;
}
.text-large .old-theme .box-search-job .inputtext-style {
  font-size: 26px !important;
}
.old-theme .box-search-job button {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  position: absolute;
  padding: 0 26px;
  right: 0;
  top: 0;
}
.old-theme .box-search-job button::before {
  content: "d";
  font-family: 18px;
  color: #b4b9bf;
}
.old-theme .nicescroll-rails {
  width: 1px !important;
  background-color: #959595;
}
.old-theme .nicescroll-rails::before, .old-theme .nicescroll-rails::after {
  content: "";
  width: 7px;
  height: 7px;
  display: block;
  border: 1px solid #ed7725;
  border-radius: 50%;
  position: absolute;
  left: -4px;
  top: -8px;
}
.old-theme .nicescroll-rails::before {
  top: auto;
  bottom: -8px;
}
.old-theme .nicescroll-rails .nicescroll-cursors {
  width: 100% !important;
  background-color: #ed7725 !important;
  border: none !important;
  border-radius: none !important;
}
.old-theme html .jspVerticalBar {
  width: 7px;
  height: 100%;
  background: none;
  position: absolute;
  right: 0;
  top: 0;
  padding: 0;
}
.old-theme html .jspVerticalBar::before, .old-theme html .jspVerticalBar::after {
  content: "";
  width: 7px;
  height: 7px;
  display: block;
  background-color: #2f3844;
  border: 1px solid #ed7725;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 13;
}
.old-theme html .jspVerticalBar::before {
  top: auto;
  bottom: 0;
}
.old-theme html .jspVerticalBar .jspTrack {
  width: 1px;
  background-color: #959595;
  position: absolute;
  left: 3px;
}
.old-theme html .jspVerticalBar .jspTrack .jspDrag {
  width: 3px;
  background-color: #ed7725;
  margin-left: -1px;
}
.old-theme .box-under-construction {
  width: 100%;
  padding-top: 150px;
  margin: 0 auto;
  text-align: center;
  font-size: 26px;
}
.text-large .old-theme .box-under-construction {
  font-size: 28px;
}
.old-theme .box-under-construction h1 {
  font-size: 5.2em;
  line-height: 0.8;
  color: #ed7725;
}
.old-theme .box-under-construction h2 {
  font-size: 1em;
}
.old-theme .box-under-construction p {
  display: inline-block;
  border-top: 1px solid #000;
  padding-top: 22px;
  margin-top: 22px;
  font-size: 0.8em;
}
.old-theme .box-success {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 150px;
  margin: 0 auto;
  text-align: center;
  font-size: 26px;
}
.text-large .old-theme .box-success {
  font-size: 28px;
}
.old-theme .box-success h1 {
  margin-bottom: 60px;
  font-size: 3em;
  line-height: 0.8;
  color: #ed7725;
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.old-theme .animateScale {
  animation: animateScale 1s ease;
}
@keyframes animateScale {
  0% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.old-theme .animateScale {
  animation: animateScale 1s ease;
}
.old-theme .content-logo .page-center {
  display: flex;
  flex-wrap: wrap;
}
.old-theme .content-logo .list-logo {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 70px 0 0 -15px;
}
.old-theme .content-logo .list-logo .item {
  width: calc(33.33% - 15px);
  margin: 15px 0 0 15px;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.17);
}
.old-theme .content-logo .list-logo .item a {
  width: 100%;
  height: 100%;
  display: block;
  border: 1px solid #ebebeb;
  position: relative;
}
.old-theme .content-logo .list-logo .item a .text {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background-color: #ed7725;
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 22px 75px 22px 32px;
  font-size: 25px;
  font-family: "db_ozone_x-bold";
  color: #fff;
  z-index: 13;
}
.text-large .old-theme .content-logo .list-logo .item a .text {
  font-size: 27px;
}
.old-theme .content-logo .list-logo .item a .text small {
  display: block;
  margin-top: 5px;
  font-size: 0.75em;
  font-family: "db_ozone_x";
}
.old-theme .content-logo .list-logo .item a .text::after {
  content: "h";
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  right: 15px;
  top: 50%;
  margin-top: -19px;
  color: #ed7725;
}
.old-theme .press-room-page .content-logo {
  border-top: 1px solid #dce1e5;
  padding-top: 60px;
  margin-top: 60px;
}
.old-theme .press-room-page .content-logo .title-page {
  margin-bottom: 64px;
}
.old-theme .press-room-page .content-logo .title-page h2 {
  font-family: "db_ozone_x-bold";
}
.old-theme .press-room-page .content-logo .list-logo {
  position: relative;
  margin-top: 0;
}
.old-theme .press-room-page .content-logo .list-logo::before {
  content: "";
  width: 74px;
  height: 37px;
  display: block;
  background-color: #ed7725;
  position: absolute;
  left: -78px;
  top: 15px;
}
.old-theme .slide-group-btn {
  width: 100%;
  border-bottom: 1px solid #dce1e5;
  margin-bottom: -1px;
  font-size: 21px;
}
.text-large .old-theme .slide-group-btn {
  font-size: 23px;
}
.old-theme .slide-group-btn .owl-stage {
  min-width: 100% !important;
  display: flex;
}
.old-theme .slide-group-btn a {
  display: block;
  position: relative;
  padding: 50px 5px 40px;
  color: #000;
  white-space: nowrap;
}
.old-theme .slide-group-btn a::after {
  content: "";
  width: 100%;
  height: 3px;
  display: block;
  background-color: #ed7725;
  position: absolute;
  left: 0;
  bottom: -1px;
  transform: scale(0);
  transition: 0.25s ease;
}
.old-theme .slide-group-btn a.active {
  font-family: "db_ozone_x-bold";
}
.old-theme .slide-group-btn a.active.active::after {
  transform: scale(1);
}
.old-theme .slide-group-btn .owl-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: -40px;
}
.old-theme .slide-group-btn .owl-nav > div {
  width: 30px;
  height: 30px;
  border: 1px solid #000;
  border-radius: 50%;
  position: relative;
  left: 0;
  top: 0;
}
.old-theme .slide-group-btn .owl-nav > div + div {
  margin-left: 5px;
}
.old-theme .slide-group-btn .owl-nav.disabled {
  display: none !important;
}
.old-theme .group-box-content > div {
  height: 897px;
  display: flex;
}
.old-theme .group-box-content > div .img {
  min-width: 50%;
  width: 50%;
  height: 100%;
}
.old-theme .group-box-content > div .img img {
  width: 100%;
  height: 100%;
  margin: 0;
  object-fit: cover;
}
.old-theme .group-box-content > div .detail {
  width: 100%;
  height: 100%;
  padding: 84px 64px 0 64px;
  font-size: 24px;
  line-height: 1.5;
}
.text-large .old-theme .group-box-content > div .detail {
  font-size: 26px;
}
.old-theme .group-box-content > div .detail .scroll {
  width: 100%;
  height: calc(100% - 46px);
  overflow: hidden;
  overflow-y: auto;
}
.old-theme .group-box-content > div .detail section {
  padding-right: 40px;
}
.old-theme .group-box-content > div .detail section + section {
  margin-top: 26px;
}
.old-theme .group-box-content > div .detail h3 {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 30px;
  font-size: 2.5em;
  line-height: 0.8;
  font-family: "db_ozone_x-bold";
}
.old-theme .group-box-content > div .detail h3::before {
  width: 52px;
  height: 6px;
}
.old-theme .group-box-content > div .detail h4 {
  margin-bottom: 10px;
  font-size: 1.25em;
  font-family: "db_ozone_x-bold";
}
.old-theme .group-box-content > div .detail .box-quote {
  quotes: "“" "”" "‘" "’";
  position: relative;
  padding: 48px 25px;
  margin-bottom: 20px;
  text-align: center;
  font-size: 1.15em;
  color: #ed7725;
}
.old-theme .group-box-content > div .detail .box-quote::before, .old-theme .group-box-content > div .detail .box-quote::after {
  width: 100%;
  height: 40px;
  display: block;
  position: absolute;
  left: 0;
  font-size: 120px;
  text-align: center;
}
.old-theme .group-box-content > div .detail .box-quote::before {
  content: open-quote;
  top: -40px;
}
.old-theme .group-box-content > div .detail .box-quote::after {
  content: close-quote;
  bottom: 40px;
}
.old-theme .group-box-content > div .detail p + p {
  margin-top: 60px;
}
.old-theme .group-box-content > div .detail .align-center {
  padding: 0 40px;
}
.old-theme .group-box-content > div:nth-of-type(even) .img {
  order: 2;
  margin-left: 0;
}
.old-theme .group-box-content > div:nth-of-type(even) .detail {
  padding-right: 64px;
  padding-left: 0;
  margin-left: auto;
}
.old-theme .group-box-content > div:nth-of-type(even) .detail section {
  padding-left: 40px;
}
.old-theme .text-coming-soon {
  font-size: 60px;
  font-family: "db_ozone_x-bold";
  color: #ed7725;
}
.text-large .old-theme .text-coming-soon {
  font-size: 62px;
}
.old-theme .primary-content {
  width: 800px;
  min-width: 800px;
}
@keyframes animateChange {
  0% {
    transform: translateY(60px);
    opacity: 0;
  }
  20%, 80% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-60px);
    opacity: 0;
  }
}

#wrapper.-detail .old-theme .banner-page .box-text {
  width: 1000px;
  max-width: 100%;
  top: auto;
  bottom: 60px;
  transform: translateY(0) scale(1) !important;
}
#wrapper.-detail .old-theme .banner-page .box-text p {
  font-size: 0.75em;
  font-family: "db_ozone_x-medium";
}
#wrapper.-detail .old-theme .banner-page .box-text a {
  margin-top: 46px;
}

@media screen and (min-width: 1400px) {
  .old-theme .banner-page .box-text {
    transform: translateY(-50%) scale(1.2);
  }
}
@media screen and (max-width: 1024px) {
  .old-theme [class*=layout-col] .design-row-style {
    min-width: 670px;
    width: 670px;
  }
  .old-theme [class*=layout-col] .title-page {
    margin-bottom: 20px;
  }
  #wrapper.-detail .old-theme .banner-page .box-text {
    width: 860px;
  }
  .old-theme .content-logo .list-logo .item a .text {
    font-size: 20px;
  }
  .text-large .old-theme .content-logo .list-logo .item a .text {
    font-size: 22px;
  }
  .old-theme .press-room-page .content-logo .title-page {
    margin-bottom: 20px;
  }
  .old-theme .group-box-content > div .detail {
    padding: 50px 30px 0 30px;
  }
  .old-theme .group-box-content > div:nth-of-type(even) .detail {
    padding-right: 30px;
    padding-left: 0;
  }
  .old-theme .primary-content {
    min-width: 670px !important;
    width: 670px !important;
    margin-left: 20px;
  }
}
@media screen and (max-width: 1023px) {
  .old-theme .banner-page {
    height: auto;
  }
  .old-theme .banner-page .box-text {
    width: 100%;
    background-color: #ed7725;
    position: relative;
    left: 0;
    top: 0 !important;
    bottom: auto !important;
    padding: 40px 20px;
    transform: translateY(0);
    font-size: 20px;
  }
  .text-large .old-theme .banner-page .box-text {
    font-size: 22px;
  }
  .old-theme .banner-page .box-text h1 {
    line-height: 0.8;
  }
  .old-theme .banner-page .box-text .breadcrumbs a {
    font-size: 1em;
  }
  .old-theme .banner-page .box-text a {
    margin-top: 20px;
    font-size: 1em;
  }
  .old-theme .banner-page .box-text a::before {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
  .old-theme [class*=layout-col] .design-row-style {
    min-width: inherit;
    width: 100%;
    margin-top: 40px;
  }
  .old-theme [class*=layout-col] .menu-left {
    width: 100%;
    position: relative;
    z-index: 13;
  }
  .old-theme [class*=layout-col] .menu-left .btn-active {
    display: flex;
  }
  .old-theme [class*=layout-col] .menu-left a {
    padding-left: 0;
  }
  .old-theme [class*=layout-col] .menu-left a::before {
    display: none !important;
  }
  .old-theme [class*=layout-col] .menu-left .group-btn {
    width: 100%;
    display: none;
    background-color: #fff;
    border: 1px solid #dce1e5;
    position: absolute;
    left: 0;
    padding: 14px 10px;
    margin-top: -1px;
  }
  .old-theme .list-content.col3 {
    width: calc(100% + 15px);
    margin: -15px 0 0 -15px;
  }
  .old-theme .list-content.col3 .item {
    width: calc(33.33% - 15px);
    margin: 15px 0 0 15px;
  }
  .old-theme .content-logo .list-logo {
    width: auto;
  }
  .old-theme .content-logo .list-logo .item {
    width: calc(50% - 15px);
  }
  .old-theme .group-box-content > div {
    height: auto;
    flex-wrap: wrap;
  }
  .old-theme .group-box-content > div .img,
  .old-theme .group-box-content > div .detail {
    width: 100%;
  }
  .old-theme .group-box-content > div .detail {
    padding: 40px !important;
  }
  .old-theme .group-box-content > div .detail .scroll,
  .old-theme .group-box-content > div .detail .jspContainer,
  .old-theme .group-box-content > div .detail .jspPane {
    width: 100% !important;
    height: auto !important;
    position: relative !important;
  }
  .old-theme .group-box-content > div .detail section {
    padding: 0 !important;
  }
  .old-theme .group-box-content > div:nth-of-type(even) .img {
    order: 0;
  }
  .old-theme .primary-content {
    min-width: inherit !important;
    width: 100% !important;
    margin-top: 40px !important;
    margin-left: 0 !important;
  }
}
@media screen and (max-width: 767px) {
  .old-theme .banner-page .box-text h1 {
    font-size: 2em;
  }
  .old-theme .section .page-center::after {
    top: 66px;
  }
  .old-theme .title-section {
    font-size: 50px;
  }
  .text-large .old-theme .title-section {
    font-size: 52px;
  }
  .old-theme [class*=layout-col] .title-page {
    font-size: 70px;
  }
  .text-large .old-theme [class*=layout-col] .title-page {
    font-size: 72px;
  }
  .old-theme [class*=layout-col] .heading p {
    width: 100%;
  }
  .old-theme [class*=layout-col] .menu-left .btn-active {
    font-size: 22px;
  }
  .text-large .old-theme [class*=layout-col] .menu-left .btn-active {
    font-size: 24px;
  }
  .old-theme [class*=layout-col] .menu-left .group-btn a {
    font-size: 22px;
  }
  .text-large .old-theme [class*=layout-col] .menu-left .group-btn a {
    font-size: 24px;
  }
  .old-theme [class*=layout-col] .menu-left .group-btn a + a {
    margin-top: 15px;
  }
  .old-theme .list-content.col3 .item {
    width: calc(50% - 15px);
  }
  .old-theme .box-under-construction {
    padding-top: 60px;
  }
  .old-theme .box-under-construction h1 {
    font-size: 4em;
  }
  .old-theme .content-logo .list-logo .item a .text {
    padding: 15px 75px 15px 15px;
    font-size: 18px;
  }
  .text-large .old-theme .content-logo .list-logo .item a .text {
    font-size: 20px;
  }
  .old-theme .primary-content {
    margin-top: 20px !important;
  }
}
@media screen and (max-width: 479px) {
  .old-theme .title-section {
    font-size: 44px;
    line-height: 0.8;
  }
  .text-large .old-theme .title-section {
    font-size: 46px;
  }
  .old-theme .banner-page {
    height: auto;
  }
  .old-theme .banner-page img {
    height: 220px;
  }
  .old-theme .banner-page .box-text .breadcrumbs {
    margin-bottom: 10px;
    font-size: 0.9em;
  }
  .old-theme .banner-page .box-text h1 {
    margin-bottom: 10px;
    font-size: 1.7em;
  }
  .old-theme .banner-page .box-text br {
    display: none !important;
  }
  .old-theme .btn-view-all {
    height: 30px;
  }
  .old-theme .btn-view-all::before {
    width: 30px;
    height: 30px;
    margin: 0;
  }
  .old-theme .btn-view-all span {
    display: none;
  }
  .old-theme .btn-load-more {
    width: 100%;
  }
  .old-theme .design-row-style {
    font-size: 18px;
  }
  .text-large .old-theme .design-row-style {
    font-size: 20px;
  }
  .old-theme .design-row-style .-play {
    padding-left: 40px;
  }
  .old-theme [class*=layout-col] {
    padding-top: 40px;
  }
  .old-theme [class*=layout-col] .title-page {
    margin-bottom: 0;
    font-size: 44px;
  }
  .text-large .old-theme [class*=layout-col] .title-page {
    font-size: 46px;
  }
  .old-theme [class*=layout-col] .title-page p {
    font-size: 0.55em;
  }
  .old-theme [class*=layout-col] .heading {
    font-size: 44px;
  }
  .text-large .old-theme [class*=layout-col] .heading {
    font-size: 46px;
  }
  .old-theme [class*=layout-col] .menu-left .btn-active {
    font-size: 20px;
  }
  .text-large .old-theme [class*=layout-col] .menu-left .btn-active {
    font-size: 22px;
  }
  .old-theme [class*=layout-col] .menu-left .group-btn a {
    font-size: 20px;
  }
  .text-large .old-theme [class*=layout-col] .menu-left .group-btn a {
    font-size: 22px;
  }
  .old-theme [class*=layout-col] .menu-left .group-btn a + a {
    margin-top: 10px;
  }
  .old-theme .list-content.col3 {
    width: 100%;
    margin: -15px 0 0 0;
  }
  .old-theme .list-content.col3 .item {
    width: 100%;
    margin: 15px 0 0 0;
  }
  .old-theme .img-banner {
    height: 220px;
  }
  .old-theme .box-under-construction {
    width: calc(100% - 30px);
  }
  .old-theme .box-under-construction h1 {
    font-size: 2.2em;
  }
  .old-theme .content-logo .list-logo {
    margin-left: 0;
  }
  .old-theme .content-logo .list-logo .item {
    width: 100%;
    margin-left: 0;
  }
  .old-theme .content-logo .list-logo .item a .text {
    font-size: 20px;
  }
  .text-large .old-theme .content-logo .list-logo .item a .text {
    font-size: 22px;
  }
  .old-theme .slide-group-btn {
    margin-bottom: 50px;
  }
  .old-theme .slide-group-btn .owl-nav {
    bottom: -15px;
  }
  .old-theme .group-box-content > div .detail {
    padding: 20px !important;
    font-size: 18px;
  }
  .text-large .old-theme .group-box-content > div .detail {
    font-size: 20px;
  }
}
@media screen and (max-width: 320px) {
  .old-theme .text-coming-soon {
    font-size: 50px;
  }
  .text-large .old-theme .text-coming-soon {
    font-size: 52px;
  }
}
.fancybox-image, .fancybox-inner, .fancybox-nav, .fancybox-nav span, .fancybox-outer, .fancybox-skin, .fancybox-tmp, .fancybox-wrap, .fancybox-wrap iframe, .fancybox-wrap object {
  padding: 0;
  margin: 0;
  border: 0;
  outline: 0;
  vertical-align: top;
}

.fancybox-wrap {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8020;
}

.fancybox-skin {
  position: relative;
  background: #f9f9f9;
  color: #444;
  text-shadow: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.fancybox-opened {
  z-index: 8030;
}

.fancybox-inner, .fancybox-outer {
  position: relative;
}

.fancybox-inner {
  overflow: hidden;
}

.fancybox-type-iframe .fancybox-inner {
  -webkit-overflow-scrolling: touch;
}

.fancybox-error {
  color: #444;
  font: 14px/20px "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin: 0;
  padding: 15px;
  white-space: nowrap;
}

.fancybox-iframe, .fancybox-image {
  display: block;
  width: 100%;
  height: 100%;
}

.fancybox-image {
  max-width: 100%;
  max-height: 100%;
}

#fancybox-loading, .fancybox-close, .fancybox-next span, .fancybox-prev span {
  background-image: url(./js/jquery.fancybox/fancybox_sprite.png);
}

#fancybox-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -22px;
  margin-left: -22px;
  background-position: 0 -108px;
  opacity: 0.8;
  cursor: pointer;
  z-index: 8060;
}

#fancybox-loading div {
  width: 44px;
  height: 44px;
  background: url(./js/jquery.fancybox/fancybox_loading.gif) center center no-repeat;
}

.fancybox-close {
  position: absolute;
  top: -18px;
  right: -18px;
  width: 36px;
  height: 36px;
  cursor: pointer;
  z-index: 8040;
}

.fancybox-nav {
  position: absolute;
  top: 0;
  width: 40%;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  z-index: 8040;
}

.fancybox-prev {
  left: 0;
}

.fancybox-next {
  right: 0;
}

.fancybox-nav span {
  position: absolute;
  top: 50%;
  width: 36px;
  height: 34px;
  margin-top: -18px;
  cursor: pointer;
  z-index: 8040;
  visibility: hidden;
}

.fancybox-prev span {
  left: 10px;
  background-position: 0 -36px;
}

.fancybox-next span {
  right: 10px;
  background-position: 0 -72px;
}

.fancybox-nav:hover span {
  visibility: visible;
}

.fancybox-tmp {
  position: absolute;
  top: -99999px;
  left: -99999px;
  visibility: hidden;
  max-width: 99999px;
  max-height: 99999px;
  overflow: visible !important;
}

.fancybox-lock {
  overflow: hidden !important;
  width: auto;
}

.fancybox-lock body {
  overflow: hidden !important;
}

.fancybox-lock-test {
  overflow-y: hidden !important;
}

.fancybox-overlay {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  display: none;
  z-index: 8010;
  background: url(./js/jquery.fancybox/fancybox_overlay.png);
}

.fancybox-overlay-fixed {
  position: fixed;
  bottom: 0;
  right: 0;
}

.fancybox-lock .fancybox-overlay {
  overflow: auto;
  overflow-y: scroll;
}

.fancybox-title {
  visibility: hidden;
  font: normal 13px/20px "Helvetica Neue", Helvetica, Arial, sans-serif;
  position: relative;
  text-shadow: none;
  z-index: 8050;
}

.fancybox-opened .fancybox-title {
  visibility: visible;
}

.fancybox-title-float-wrap {
  position: absolute;
  bottom: 0;
  right: 50%;
  margin-bottom: -35px;
  z-index: 8050;
  text-align: center;
}

.fancybox-title-float-wrap .child {
  display: inline-block;
  margin-right: -100%;
  padding: 2px 20px;
  background: 0 0;
  background: rgba(0, 0, 0, 0.8);
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  text-shadow: 0 1px 2px #222;
  color: #fff;
  font-weight: 700;
  line-height: 24px;
  white-space: nowrap;
}

.fancybox-title-outside-wrap {
  position: relative;
  margin-top: 10px;
  color: #fff;
}

.fancybox-title-inside-wrap {
  padding-top: 10px;
}

.fancybox-title-over-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #fff;
  padding: 10px;
  background: #000;
  background: rgba(0, 0, 0, 0.8);
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min--moz-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
  #fancybox-loading, .fancybox-close, .fancybox-next span, .fancybox-prev span {
    background-image: url(./js/jquery.fancybox/fancybox_sprite@2x.png);
    background-size: 44px 152px;
  }
  #fancybox-loading div {
    background-image: url(./js/jquery.fancybox/fancybox_loading@2x.gif);
    background-size: 24px 24px;
  }
}
.owl-carousel, .owl-carousel .owl-item {
  -webkit-tap-highlight-color: transparent;
  position: relative;
}

.owl-carousel {
  display: none;
  width: 100%;
  z-index: 1;
}

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  -moz-backface-visibility: hidden;
}

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item, .owl-carousel .owl-wrapper {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item {
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-touch-callout: none;
}

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}

.owl-carousel .owl-dots.disabled, .owl-carousel .owl-nav.disabled {
  display: none;
}

.no-js .owl-carousel, .owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel .owl-dot, .owl-carousel .owl-nav .owl-next, .owl-carousel .owl-nav .owl-prev {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}

.owl-carousel.owl-drag .owl-item {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

.owl-carousel .animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.owl-height {
  transition: height 0.5s ease-in-out;
}

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 0.4s ease;
}

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}

.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url(./js/owl.carousel/owl.video.play.png) no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 0.1s ease;
}

.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3);
}

.owl-carousel .owl-video-playing .owl-video-play-icon, .owl-carousel .owl-video-playing .owl-video-tn {
  display: none;
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 0.4s ease;
}

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
}

.old-theme .form-style .items {
  margin-top: -32px;
  overflow: hidden;
}
.old-theme .form-style .items .item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 32px;
}
.old-theme .form-style .items .item .col {
  width: 100%;
}
.old-theme .form-style .items .item.col2 .col {
  width: 50%;
}
.old-theme .form-style .items .item.col2 label {
  margin-bottom: 0;
}
.old-theme .form-style .items .item.col3 .col {
  width: 33.33%;
}
.old-theme .form-style .items .item.col3 label {
  margin-bottom: 0;
}
.old-theme .form-style label {
  display: inline-block;
  margin-bottom: 18px;
  font-size: 20px;
  font-family: "db_ozone_x-medium";
  color: #333;
}
.text-large .old-theme .form-style label {
  font-size: 22px;
}
.old-theme .form-style input[type=number]::-webkit-inner-spin-button,
.old-theme .form-style input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.old-theme .form-style input[type=number] {
  -moz-appearance: textfield;
}
.old-theme .form-style .inputtext-style,
.old-theme .form-style .textarea-style,
.old-theme .form-style .select-style {
  width: 100%;
  display: block;
  background: #fff;
  border: 1px solid #7d7d7d;
  border-radius: 0;
  padding: 12px 30px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-size: 20px;
}
.text-large .old-theme .form-style .inputtext-style,
.text-large .old-theme .form-style .textarea-style,
.text-large .old-theme .form-style .select-style {
  font-size: 22px;
}
.old-theme .form-style .inputtext-style::-webkit-input-placeholder {
  color: #817f7f;
}
.old-theme .form-style .inputtext-style::-moz-placeholder {
  color: #817f7f;
}
.old-theme .form-style .inputtext-style:-ms-input-placeholder {
  color: #817f7f;
}
.old-theme .form-style .inputtext-style:-moz-placeholder {
  color: #817f7f;
}
.old-theme .form-style .textarea-style::-webkit-input-placeholder {
  color: #817f7f;
}
.old-theme .form-style .textarea-style::-moz-placeholder {
  color: #817f7f;
}
.old-theme .form-style .textarea-style:-ms-input-placeholder {
  color: #817f7f;
}
.old-theme .form-style .textarea-style:-moz-placeholder {
  color: #817f7f;
}
.old-theme .form-style .inputtext-style::-ms-clear {
  display: none;
}
.old-theme .form-style .textarea-style {
  height: 148px;
  resize: none;
}
.old-theme .form-style .select-style {
  cursor: pointer;
}
.old-theme .form-style .select-box {
  position: relative;
}
.old-theme .form-style .select-box::before {
  content: "q";
  width: 46px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  bottom: 0;
  pointer-events: none;
}
.ie .old-theme .form-style .select-box:before {
  display: none;
}
.old-theme .form-style .checkbox-style input[type=checkbox] {
  display: none;
}
.old-theme .form-style .checkbox-style label {
  position: relative;
  padding: 0 0 0 32px;
  line-height: 1.3;
  font-family: "db_ozone_x";
}
.old-theme .form-style .checkbox-style label::before {
  content: "";
  width: 20px;
  height: 20px;
  display: block;
  background-color: #fff;
  border: 1px solid #7d7d7d;
  position: absolute;
  left: 0;
  top: 0;
}
.old-theme .form-style .checkbox-style label::after {
  content: "l";
  display: block;
  position: absolute;
  left: 2px;
  top: -1px;
  font-size: 18px;
  color: #7d7d7d;
  transform: scale(0);
  transition: 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.old-theme .form-style .checkbox-style input[type=checkbox]:checked + label::after {
  transform: scale(1);
}
.old-theme .form-style .radio-style input[type=radio] {
  display: none;
}
.old-theme .form-style .radio-style label {
  position: relative;
  padding: 0 0 0 32px;
  line-height: 1.3;
  font-family: "db_ozone_x";
}
.old-theme .form-style .radio-style label::before {
  content: "";
  width: 20px;
  height: 20px;
  display: block;
  background-color: #fff;
  border: 1px solid #7d7d7d;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 0;
}
.old-theme .form-style .radio-style label::after {
  content: "l";
  display: block;
  position: absolute;
  left: 2px;
  top: -1px;
  font-size: 18px;
  color: #7d7d7d;
  transform: scale(0);
  transition: 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.old-theme .form-style .radio-style input[type=radio]:checked + label::after {
  transform: scale(1);
}
.old-theme .form-style .error .inputtext-style,
.old-theme .form-style .error .textarea-style,
.old-theme .form-style .error .select-style {
  border-color: red !important;
}
.old-theme .form-style .error .checkbox-style label::before, .old-theme .form-style .error.checkbox-style label::before {
  border-color: red !important;
}
.old-theme .form-style .error .radio-style label::before, .old-theme .form-style .error.radio-style label::before {
  border-color: red !important;
}
.old-theme .form-style .text-validate-error {
  display: none;
  margin-top: 5px;
  font-size: 0.9em !important;
  font-family: "db_ozone_x" !important;
  color: red !important;
}
.old-theme .form-style .error .text-validate-error {
  display: block;
}
.old-theme .form-style.design-border-bottom .col {
  min-height: 45px;
  position: relative;
}
.old-theme .form-style.design-border-bottom label {
  position: absolute;
  left: 0;
  top: 16px;
  margin: 0;
  z-index: 13;
  transition: 0.25s ease;
}
.old-theme .form-style.design-border-bottom .inputtext-style:focus + label,
.old-theme .form-style.design-border-bottom .inputtext-style.not-empty + label,
.old-theme .form-style.design-border-bottom .textarea-style:focus + label,
.old-theme .form-style.design-border-bottom .textarea-style.not-empty + label,
.old-theme .form-style.design-border-bottom .select-style:focus + label,
.old-theme .form-style.design-border-bottom .select-style.not-empty + label {
  top: 0;
}
.old-theme .form-style.design-border-bottom .-select label {
  pointer-events: none;
}
.old-theme .form-style.design-border-bottom .inputtext-style,
.old-theme .form-style.design-border-bottom .textarea-style,
.old-theme .form-style.design-border-bottom .select-style {
  border: none;
  border-bottom: 1px solid #ebebeb;
  padding: 0;
  margin-top: 24px;
}
.old-theme .frm_purchase {
  display: flex;
  margin-bottom: 48px;
}
.old-theme .frm_purchase .box-left,
.old-theme .frm_purchase .box-right {
  float: none !important;
}
.old-theme .frm_purchase .box-right {
  display: flex;
}

@media screen and (max-width: 479px) {
  .old-theme .form-style .items .item .col {
    width: 100% !important;
  }
  .old-theme .form-style .items .item.col2 .col,
  .old-theme .form-style .items .item.col3 .col {
    margin-top: 10px;
  }
}
.old-theme .table-style {
  width: 100%;
  overflow: hidden;
  overflow-x: auto;
}
.old-theme .table-style table {
  width: 100%;
  min-width: 870px;
  border-collapse: collapse;
  border: 0;
  border-right: 1px solid #ebebeb;
  border-left: 1px solid #ebebeb;
  vertical-align: middle;
  text-align: center;
}
.old-theme .table-style table thead th {
  background-color: #ed7725;
  padding: 22px 8px;
  font-size: 20px;
  color: #fff;
  text-align: center;
}
.text-large .old-theme .table-style table thead th {
  font-size: 22px;
}
.old-theme .table-style table thead th:first-child {
  padding-left: 18px;
}
.old-theme .table-style table thead th:last-child {
  padding-right: 18px;
}
.old-theme .table-style table tbody td {
  background-color: #fff;
  border-bottom: 1px solid #ebebeb;
  padding: 18px 8px;
  font-size: 20px;
}
.text-large .old-theme .table-style table tbody td {
  font-size: 22px;
}
.old-theme .table-style table tbody td:first-child {
  padding-left: 18px;
}
.old-theme .table-style table tbody td:last-child {
  padding-right: 18px;
}
.old-theme .table-style table tbody tr:nth-of-type(even) td {
  background-color: #fafafa;
}

.accordion {
  min-width: 800px;
  width: 800px;
}
.accordion > .item {
  width: 100%;
  border-bottom: 1px solid #dce1e5;
  padding-bottom: 28px;
}
.accordion > .item > a {
  display: block;
  position: relative;
  padding-right: 50px;
  margin-bottom: 0;
  font-size: 30px;
  font-family: "db_ozone_x-medium";
  color: #8d959f;
}
.text-large .accordion > .item > a {
  font-size: 32px;
}
.accordion > .item > a::after {
  content: "+";
  width: 22px;
  height: 30px;
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  font-size: 47px;
  line-height: 30px;
  text-align: center;
  color: #ed7725;
}
.accordion > .item .item-content {
  display: none;
  margin-top: 25px;
  color: #919195;
  overflow: hidden;
}
.accordion > .item.active > a {
  color: #ed7725;
}
.accordion > .item.active > a::after {
  content: "";
  width: 22px;
  height: 3px;
  background-color: #ed7725;
  right: 0;
  top: 14px;
}
.accordion > .item.active .item-content {
  display: block;
}
.accordion .item + .item {
  margin-top: 28px;
}

@media screen and (max-width: 1024px) {
  .accordion {
    min-width: 670px;
    width: 670px;
  }
}
@media screen and (max-width: 1023px) {
  .accordion {
    min-width: inherit;
    width: 100%;
    margin-top: 40px;
  }
}
@media screen and (max-width: 479px) {
  .accordion > .item > a {
    font-size: 24px;
  }
  .text-large .accordion > .item > a {
    font-size: 26px;
  }
  .accordion > .item > a::after {
    font-size: 36px;
    line-height: 24px;
  }
  .accordion > .item.active > a::after {
    width: 14px;
    right: 4px;
    top: 12px;
  }
}
.popup-old-theme {
  max-width: calc(100vw - 40px);
  margin: 20px 0;
}
.popup-old-theme * {
  box-sizing: border-box;
}
.popup-old-theme .inner {
  width: 680px;
  background-color: #fff;
  padding: 76px 36px 60px;
}
.popup-old-theme .inner h2 {
  margin-bottom: 76px;
  font-size: 32px;
  font-family: "db_ozone_x-bold";
  text-align: center;
}
.text-large .popup-old-theme .inner h2 {
  font-size: 34px;
}
.popup-old-theme.-intro {
  margin: 50px 0;
}
.popup-old-theme.-intro .inner {
  width: auto;
  padding: 0;
}
.popup-old-theme.-subscribe .col3 + .col3 {
  margin-top: 10px;
}
.popup-old-theme.-subscribe .item:last-child {
  margin-top: 10px;
}
.popup-old-theme.-subscribe .btn-style {
  width: 100%;
}
.popup-old-theme.-job-apply .inner {
  width: 1100px;
  max-width: 100%;
  overflow: hidden;
}
.popup-old-theme.-job-apply .items {
  overflow: visible;
}
.popup-old-theme.-job-apply .item:after {
  display: none !important;
}
.popup-old-theme.-job-apply label {
  margin-bottom: 18px !important;
}
.popup-old-theme.-job-apply label .remark {
  font-size: 18px;
  color: #817f7f;
}
.text-large .popup-old-theme.-job-apply label .remark {
  font-size: 20px;
}
.popup-old-theme.-job-apply .col2 {
  justify-content: space-between;
}
.popup-old-theme.-job-apply .col2 > .col {
  width: calc(50% - 14px) !important;
}
.popup-old-theme.-job-apply .sub-col {
  display: flex;
  justify-content: space-between;
}
.popup-old-theme.-job-apply .sub-col .col {
  width: calc(50% - 7px) !important;
}
.popup-old-theme.-job-apply .sub-col.col3 .col {
  width: calc(33.33% - 7px) !important;
}
.popup-old-theme.-job-apply .title-name .col:nth-of-type(1) {
  width: calc(30% - 7px) !important;
}
.popup-old-theme.-job-apply .title-name .col:nth-of-type(2) {
  width: calc(70% - 7px) !important;
}
.popup-old-theme.-job-apply .attach-file .col > div + div {
  margin-left: 12px;
}
.popup-old-theme.-job-apply .attach-file input {
  position: absolute;
  left: -9999px;
}
.popup-old-theme.-job-apply .attach-file .btn-style {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 0 !important;
  text-align: center;
  overflow: hidden;
}
.popup-old-theme.-job-apply .attach-file .btn-style:before {
  content: "";
  display: block;
  background: 50% 50%/100% no-repeat;
  margin-right: 14px;
}
.popup-old-theme.-job-apply .attach-file .btn-style.-letter:before {
  width: 17px;
  height: 23px;
  background-image: url("../img/icons/letter.svg");
}
.popup-old-theme.-job-apply .attach-file .btn-style.-resume:before {
  width: 20px;
  height: 24px;
  background-image: url("../img/icons/resume.svg");
}
.popup-old-theme.-job-apply .attach-file .icon-android-done {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -15px 0 0 -15px;
  transform: translateY(60px);
  opacity: 0;
  color: #ed7725;
}
.popup-old-theme.-job-apply .attach-file .is-change .icon-android-done {
  animation: animateChange 2s;
}
.popup-old-theme.-job-apply .item:last-child {
  position: relative;
  padding-top: 40px;
  margin-top: 40px;
}
.popup-old-theme.-job-apply .item:last-child:before {
  content: "";
  width: 200%;
  height: 1px;
  display: block;
  background-color: #ebebeb;
  position: absolute;
  left: -50%;
  top: 0;
}
.popup-old-theme.-board-of-directors {
  max-width: calc(100vw - 80px);
  margin: 30px 0;
}
.popup-old-theme.-board-of-directors .inner {
  width: 990px;
  max-width: 100%;
  padding: 0;
}
.popup-old-theme.-board-of-directors .content {
  display: flex;
}
.popup-old-theme.-board-of-directors .img {
  min-width: 450px;
}
.popup-old-theme.-board-of-directors img {
  margin: 0;
}
.popup-old-theme.-board-of-directors .detail {
  width: 100%;
  padding: 48px 0;
}
.popup-old-theme.-board-of-directors .detail .title {
  border-bottom: 1px solid #dce1e5;
  padding: 0 40px 35px 40px;
  margin-bottom: 35px;
}
.popup-old-theme.-board-of-directors .detail .desription {
  padding: 0 40px;
  font-size: 22px;
}
.text-large .popup-old-theme.-board-of-directors .detail .desription {
  font-size: 24px;
}
.popup-old-theme.-board-of-directors .detail .desription h3 {
  font-size: 1.2em;
}
.popup-old-theme.-board-of-directors .detail .desription strong {
  font-family: "db_ozone_x-medium";
}
.popup-old-theme.-board-of-directors .detail .scroll {
  height: 356px;
}
.popup-old-theme.-board-of-directors .detail .scroll .jspVerticalBar {
  width: 8px;
}
.popup-old-theme.-board-of-directors .detail .scroll .jspVerticalBar::before, .popup-old-theme.-board-of-directors .detail .scroll .jspVerticalBar::after {
  background-color: #fff;
  left: -1px;
}
.popup-old-theme.-board-of-directors .detail .editor {
  padding-right: 40px;
}
.popup-old-theme.-board-of-directors .detail .editor ul li::before {
  background-color: #ed7725;
}
.popup-old-theme.-board-of-directors .detail .editor h3 {
  font-family: "db_ozone_x-bold";
}
.popup-old-theme.-board-of-directors .detail h2 {
  position: relative;
  margin: 0;
  font-size: 32px;
  text-align: left;
}
.text-large .popup-old-theme.-board-of-directors .detail h2 {
  font-size: 34px;
}
.popup-old-theme.-board-of-directors .detail h2::before {
  content: "";
  width: 10px;
  height: 10px;
  display: none;
  border: 2px solid #ed7725;
  border-radius: 50%;
  position: absolute;
  left: -22px;
  top: 0;
}
.popup-old-theme.-board-of-directors .detail h2 small {
  display: block;
  font-size: 0.8em;
  font-family: "db_ozone_x";
  color: #8a8a8a;
}
.popup-old-theme.-board-of-directors .detail .list-bullet > li {
  padding-left: 32px;
  line-height: 1.2;
}
.popup-old-theme.-board-of-directors .detail .list-bullet > li::before {
  top: 9px;
}
.popup-old-theme .btn-orange {
  min-width: 216px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e37835;
  border: none;
  border-radius: 0;
  padding: 15px 30px;
  margin: 0 auto;
  font-size: 22px;
  color: #fff !important;
  transition: 0.25s ease;
}
.desktop .popup-old-theme .btn-orange:hover {
  background-color: #000;
}
.popup-old-theme .btn-orange.-orange {
  background-color: #ed7725;
}
.popup-old-theme .btn-orange.-dark-gray {
  background-color: #777779;
}

html .popup-intro .fancybox-close {
  border-color: #fff;
  right: 0;
  top: -40px;
}
html .popup-intro .fancybox-close::before {
  color: #fff;
}

html .popup-board-of-directors .fancybox-close {
  width: 55px;
  height: 55px;
  background-color: #ed7725;
  border: none;
  right: -27px;
  top: -27px;
}
html .popup-board-of-directors .fancybox-close::before {
  font-size: 30px;
  color: #fff;
}

.popup-gallery .fancybox-close {
  border-color: #fff;
  right: 10px;
  top: 10px;
}
.popup-gallery .fancybox-close::before {
  color: #fff;
}

.popup-video .inner {
  height: 28.2vw;
  padding: 0;
}
.popup-video .fancybox-close {
  border-color: #fff;
  right: 10px;
  top: 10px;
}
.popup-video .fancybox-close::before {
  color: #fff;
}

@media screen and (max-width: 1023px) {
  .popup-old-theme.-board-of-directors .content {
    flex-wrap: wrap;
  }
  .popup-old-theme.-board-of-directors .img,
  .popup-old-theme.-board-of-directors .detail {
    width: 100%;
  }
  .popup-old-theme.-board-of-directors .img {
    min-width: inherit;
  }
  .popup-old-theme.-board-of-directors .img img {
    width: 100%;
  }
  .popup-old-theme.-job-apply .col2 {
    display: block;
  }
  .popup-old-theme.-job-apply .col2 > .col {
    width: 100% !important;
  }
  .popup-old-theme.-job-apply .col2 > .col + .col {
    margin-top: 32px;
  }
  .popup-old-theme.-job-apply .attach-file .col > div {
    width: 100%;
  }
  .popup-old-theme.-job-apply .attach-file .btn-style {
    min-width: 100%;
    white-space: nowrap;
  }
}
@media screen and (max-width: 767px) {
  .popup-old-theme.-subscribe .inner {
    width: 100%;
  }
  .popup-old-theme.-subscribe .col3 label {
    padding-top: 2px;
    font-size: 15px;
  }
  .text-large .popup-old-theme.-subscribe .col3 label {
    font-size: 17px;
  }
  .popup-old-theme.-board-of-directors ul > li::before {
    top: 6px;
  }
  .popup-old-theme.-job-apply .attach-file .col {
    flex-wrap: wrap;
  }
  .popup-old-theme.-job-apply .attach-file .col > div {
    width: 100%;
  }
  .popup-old-theme.-job-apply .attach-file .col > div + div {
    margin: 10px 0 0;
  }
  .popup-old-theme.-job-apply .btn-style {
    width: 100%;
  }
}
@media screen and (max-width: 479px) {
  .popup-old-theme.-board-of-directors {
    max-width: calc(100vw - 40px);
  }
  .popup-old-theme.-board-of-directors .detail {
    padding: 30px 0;
  }
  .popup-old-theme.-board-of-directors .detail h2 {
    font-size: 28px;
  }
  .text-large .popup-old-theme.-board-of-directors .detail h2 {
    font-size: 30px;
  }
  .popup-old-theme.-board-of-directors .detail .title {
    padding: 0 30px 35px;
  }
  .popup-old-theme.-board-of-directors .detail .desription {
    padding: 0 20px;
    font-size: 18px;
  }
  .text-large .popup-old-theme.-board-of-directors .detail .desription {
    font-size: 20px;
  }
  .popup-old-theme.-board-of-directors .detail ul > li::before {
    top: 4px;
  }
  .popup-old-theme.-subscribe .inner {
    padding: 76px 20px 60px;
  }
  .popup-old-theme.-subscribe .inner h2 {
    margin-bottom: 40px;
  }
  .popup-old-theme.-subscribe .inner .col3 label {
    font-size: 18px;
  }
  .text-large .popup-old-theme.-subscribe .inner .col3 label {
    font-size: 20px;
  }
  .popup-old-theme.-subscribe .inner .col3 + .col3 {
    margin-top: 0;
  }
  .popup-old-theme.-job-apply .inner {
    padding: 50px 20px 30px;
  }
  .popup-old-theme.-job-apply .sub-col {
    display: block;
  }
  .popup-old-theme.-job-apply .sub-col.col3 .col {
    width: 100% !important;
  }
  .popup-old-theme.-job-apply .sub-col .col {
    width: 100% !important;
  }
  .popup-old-theme.-job-apply .sub-col .col + .col {
    margin-top: 32px;
  }
  .popup-old-theme.-job-apply .title-name .col:nth-of-type(1),
  .popup-old-theme.-job-apply .title-name .col:nth-of-type(2) {
    width: 100% !important;
  }
  html .popup-board-of-directors .fancybox-close {
    width: 40px;
    height: 40px;
    right: -18px;
    top: -18px;
  }
}
html .fancybox-overlay {
  background: rgba(0, 0, 0, 0.8);
  z-index: 131313;
}

html .fancybox-wrap {
  z-index: 13131313;
}
html .fancybox-wrap .fancybox-skin {
  background: none;
  border-radius: 0;
}
html .fancybox-wrap .fancybox-skin .fancybox-inner {
  overflow: visible !important;
}

html .fancybox-close {
  width: 28px;
  height: 28px;
  display: block;
  background: none;
  border: 2px solid #000;
  border-radius: 50%;
  right: 26px;
  top: 16px;
  transition: 0.15s ease;
}
html .fancybox-close::before {
  content: "k";
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: #000;
}

html.desktop .fancybox-close:hover {
  transform: scale(1.2);
}

html .fancybox-nav span {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none !important;
  border: 1px solid #fff;
  border-radius: 50%;
  margin-top: -14px;
  color: #fff !important;
}
html .fancybox-nav span::before {
  content: "";
  display: block;
}
html .fancybox-nav.fancybox-prev span {
  left: 10px;
}
html .fancybox-nav.fancybox-prev span::before {
  content: "g";
}
html .fancybox-nav.fancybox-next span {
  right: 10px;
}
html .fancybox-nav.fancybox-next span::before {
  content: "h";
}

@media only screen and (max-width: 479px) {
  html .fancybox-close {
    right: 10px;
    top: 10px;
  }
}
html .old-theme .owl-nav {
  width: 100%;
  max-width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  pointer-events: none;
  opacity: 1;
  transition: 0.25s ease;
}
html .old-theme .owl-nav .disabled {
  opacity: 0.1 !important;
}
html .old-theme .owl-nav .owl-prev,
html .old-theme .owl-nav .owl-next {
  width: 54px;
  height: 54px;
  background: url("../img/icons/arrow-left-black.svg") 50% 50% no-repeat;
  position: absolute;
  left: 0;
  top: 0;
  text-indent: -9999px;
  pointer-events: all;
}
html .old-theme .owl-nav .owl-next {
  background-image: url("../img/icons/arrow-right-black.svg");
  left: auto;
  right: 0;
}

html .old-theme .owl-dots {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  bottom: 24px;
  pointer-events: none;
}
html .old-theme .owl-dots .owl-dot {
  width: 9px;
  height: 9px;
  background-color: #000;
  border-radius: 50%;
  pointer-events: all;
}
html .old-theme .owl-dots .owl-dot + .owl-dot {
  margin-left: 10px;
}
html .old-theme .owl-dots .owl-dot.active {
  background-color: #fff;
}

html .old-theme .owl-carousel .owl-video-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

/*
 * CSS Styles that are needed by jScrollPane for it to operate correctly.
 *
 * Include this stylesheet in your site or copy and paste the styles below into your stylesheet - jScrollPane
 * may not operate correctly without them.
 */
.jspContainer {
  overflow: hidden;
  position: relative;
}

.jspPane {
  position: absolute;
}

.jspVerticalBar {
  position: absolute;
  top: 0;
  right: 0;
  width: 16px;
  height: 100%;
  background: red;
}

.jspHorizontalBar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 16px;
  background: red;
}

.jspCap {
  display: none;
}

.jspHorizontalBar .jspCap {
  float: left;
}

.jspTrack {
  background: #dde;
  position: relative;
}

.jspDrag {
  background: #bbd;
  position: relative;
  top: 0;
  left: 0;
  cursor: pointer;
}

.jspHorizontalBar .jspTrack,
.jspHorizontalBar .jspDrag {
  float: left;
  height: 100%;
}

.jspArrow {
  background: #50506d;
  text-indent: -20000px;
  display: block;
  cursor: pointer;
  padding: 0;
  margin: 0;
}

.jspArrow.jspDisabled {
  cursor: default;
  background: #80808d;
}

.jspVerticalBar .jspArrow {
  height: 16px;
}

.jspHorizontalBar .jspArrow {
  width: 16px;
  float: left;
  height: 100%;
}

.jspVerticalBar .jspArrow:focus {
  outline: none;
}

.jspCorner {
  background: #eeeef4;
  float: left;
  height: 100%;
}

/* Yuk! CSS Hack for IE6 3 pixel bug :( */
* html .jspCorner {
  margin: 0 -3px 0 0;
}

.home-page .section.-highlight-banner {
  width: 100%;
  height: calc(100vh - 136px);
  overflow: hidden;
}
.home-page .section.-highlight-banner .owl-carousel,
.home-page .section.-highlight-banner .owl-stage-outer,
.home-page .section.-highlight-banner .item,
.home-page .section.-highlight-banner .img {
  height: calc(100vh - 180px);
}
.home-page .section.-highlight-banner .slide-highlight-banner {
  height: 100%;
  position: relative;
}
.home-page .section.-highlight-banner .slide-highlight-banner .owl-nav {
  display: none !important;
}
.home-page .section.-highlight-banner .item {
  position: relative;
}
.home-page .section.-highlight-banner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin: 0;
}
.home-page .section.-highlight-banner .box-text {
  width: 600px;
  display: block;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 72px;
  font-size: 5rem;
  color: #fff;
}
.home-page .section.-highlight-banner .box-text::before, .home-page .section.-highlight-banner .box-text::after {
  content: "";
  width: 35px;
  height: 35px;
  display: block;
  background-color: #d7d7d7;
  position: absolute;
  left: 0;
  top: -70px;
}
.home-page .section.-highlight-banner .box-text::after {
  background-color: #e58345;
  left: 35px;
  top: -35px;
}
.home-page .section.-highlight-banner .box-text h2 {
  position: relative;
  font-size: 1em;
  line-height: 1em;
  font-family: "db_ozone_x-bold";
  text-transform: uppercase;
}
.home-page .section.-highlight-banner .box-text h2::before {
  content: "";
  width: 50px;
  height: 12px;
  display: block;
  background-color: #ed7725;
  position: absolute;
  left: -72px;
  top: 12px;
}
.home-page .section.-highlight-banner .box-text h2 small {
  display: block;
  font-size: 0.8em;
}
.home-page .section.-highlight-banner .box-text h3 {
  font-size: 0.66em;
  font-family: "db_ozone_x-medium";
}
.home-page .section.-highlight-banner .box-text p {
  margin-top: 26px;
  font-size: 0.5em;
}
.home-page .section.-highlight-banner .box-text .btn-read-more {
  margin-top: 72px;
  font-size: 0.56em;
}
.home-page .section.-highlight-banner .owl-dots {
  position: relative;
  bottom: 0;
  margin-top: 17px;
}
.home-page .section.-highlight-banner .owl-dots .owl-dot {
  background-color: #c7c5c6;
}
.home-page .section.-highlight-banner .owl-dots .owl-dot.active {
  background-color: #ed7725;
}
.home-page .section.-resources .page-center {
  width: 1240px;
  overflow: hidden;
}
.home-page .section.-resources .page-center .head {
  margin: 0 30px;
}
.home-page .section.-resources .page-center::before {
  background-color: #ed7725;
}
.home-page .list-resources {
  width: 1180px;
  max-width: 100%;
  margin: 60px auto 0;
}
.home-page .list-resources .owl-stage-outer {
  overflow: visible;
}
.home-page .list-resources .item {
  width: 100%;
}
.home-page .list-resources .item a {
  width: 100%;
  display: block;
  position: relative;
  margin-bottom: 50px;
  box-shadow: 0 24px 35px rgba(0, 0, 0, 0.17);
}
.home-page .list-resources .item img {
  width: 100%;
  margin: 0;
}
.home-page .list-resources .item .text {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: #fff;
  padding: 32px 0;
}
.home-page .list-resources .item .text span {
  width: 100%;
  display: block;
  padding: 0 20px;
  font-size: 3rem;
  font-family: "db_ozone_x-bold";
  color: #555;
  text-align: center;
  text-transform: uppercase;
}
.home-page .list-resources .item .text .text-view-all {
  min-width: 142px;
  max-width: 142px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ed7725;
  margin-left: 32px;
  font-size: 1.8rem;
  font-family: "db_ozone_x";
  color: #fff;
  box-shadow: 0 6px 9px rgba(0, 0, 0, 0.17);
}
.desktop .home-page .list-resources .item a:hover .text-view-all::after {
  margin-left: 15px;
}
.home-page .list-resources .owl-dots {
  position: relative;
  bottom: 0;
  margin-top: 10px;
}
.home-page .list-resources .owl-dots .owl-dot {
  width: 7px;
  height: 7px;
  background-color: #c6c5c5;
}
.home-page .list-resources .owl-dots .owl-dot.active {
  background-color: #ed7725;
}
.home-page .section.-highlights .page-center::before {
  background-color: #002a64;
}
.home-page .section.-highlights .content-highlights {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.home-page .section.-highlights .slide-highlights {
  min-width: 558px;
  width: 558px;
  position: relative;
}
.home-page .section.-highlights .slide-highlights .item .img {
  position: relative;
}
.home-page .section.-highlights .slide-highlights .item .img span {
  width: 176px;
  height: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #002a64;
  position: absolute;
  right: 0;
  bottom: 0;
  padding-top: 5px;
  font-size: 2.4rem;
  color: #fff;
}
.home-page .section.-highlights .slide-highlights .item .img span::before {
  position: relative;
  top: -2px;
  margin-right: 12px;
  font-size: 18px;
}
.home-page .section.-highlights .slide-highlights .item .text {
  margin-top: 36px;
}
.home-page .section.-highlights .slide-highlights .item .text .date {
  margin-bottom: 8px;
  font-size: 2.4rem;
  color: #c6c5c5;
}
.home-page .section.-highlights .slide-highlights .item .text h3 {
  margin: 0 0 26px;
  font-size: 3.8rem;
}
.home-page .section.-highlights .slide-highlights .item .text a {
  font-size: 2.4rem;
  color: #ed7725;
}
.desktop .home-page .section.-highlights .slide-highlights .item .text a:hover {
  color: #000;
}
.home-page .section.-highlights .slide-highlights .owl-nav {
  display: none !important;
}
.home-page .section.-highlights .slide-highlights .nav-control {
  display: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  bottom: 0;
}
.home-page .section.-highlights .slide-highlights .nav-control .prev,
.home-page .section.-highlights .slide-highlights .nav-control .next {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #ed7725;
  border-radius: 50%;
  transition: 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.desktop .home-page .section.-highlights .slide-highlights .nav-control .prev:hover,
.desktop .home-page .section.-highlights .slide-highlights .nav-control .next:hover {
  transform: scale(1.2);
}
.home-page .section.-highlights .slide-highlights .nav-control .prev::before,
.home-page .section.-highlights .slide-highlights .nav-control .next::before {
  content: "g";
  font-size: 13px;
  color: #ed7725;
}
.home-page .section.-highlights .slide-highlights .nav-control .prev.next::before,
.home-page .section.-highlights .slide-highlights .nav-control .next.next::before {
  content: "h";
  text-indent: 2px;
}
.home-page .section.-highlights .slide-highlights .nav-control .dots {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
}
.home-page .section.-highlights .slide-highlights .nav-control .owl-dot {
  width: 4px;
  height: 4px;
  background-color: #ccc;
  border-radius: 50%;
  cursor: pointer;
}
.home-page .section.-highlights .slide-highlights .nav-control .owl-dot.active {
  background-color: #ed7725;
}
.home-page .section.-highlights .slide-highlights .nav-control .owl-dot + .owl-dot {
  margin-left: 6px;
}
.home-page .section.-highlights .list-highlights {
  width: calc(100% + 22px);
  justify-content: space-between;
  margin: 0;
}
.home-page .section.-highlights .list-highlights .item {
  width: calc(50% - 22px);
  border-left: 1px solid #ccc;
  padding: 0 0 28px 22px;
  margin: 0 0 0 22px;
}
.home-page .section.-highlights .list-highlights .item h3 {
  min-height: 38px;
}
.home-page .section.-upcoming-events {
  min-height: 720px;
  position: relative;
  margin-top: 50px;
}
.home-page .section.-upcoming-events::before {
  content: "";
  width: 2058px;
  height: 535px;
  background: url("../img/home/bg-upcoming-events.jpg") no-repeat;
  display: block;
  position: absolute;
  left: 50%;
  top: 130px;
  margin-left: -552px;
  z-index: 1;
}
.home-page .section.-upcoming-events .page-center::before {
  background-color: #777779;
}
.home-page .section.-upcoming-events .nav-control {
  display: flex;
}
.home-page .section.-upcoming-events .nav-control div {
  width: 38px;
  height: 38px;
  display: block;
  border: 3px solid #ed7725;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.25s ease;
}
.desktop .home-page .section.-upcoming-events .nav-control div:hover {
  transform: scale(1.2);
}
.home-page .section.-upcoming-events .nav-control div::before {
  content: "g";
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: #ed7725;
}
.home-page .section.-upcoming-events .nav-control div.owl-next::before {
  content: "h";
  text-indent: 2px;
}
.home-page .section.-upcoming-events .nav-control div.disabled {
  opacity: 0.2;
}
.home-page .section.-upcoming-events .nav-control div + div {
  margin-left: 6px;
}
.home-page .section.-upcoming-events .owl-nav {
  display: none !important;
}
.home-page .section.-upcoming-events .content-upcoming-events {
  margin-top: 102px;
}
.home-page .section.-upcoming-events .content-upcoming-events .slide-upcoming-events {
  /* .desktop & .item a:hover .img::before {
    opacity: 0;
  } */
}
.home-page .section.-upcoming-events .content-upcoming-events .slide-upcoming-events .owl-stage-outer {
  overflow: visible;
}
.home-page .section.-upcoming-events .content-upcoming-events .slide-upcoming-events .img {
  width: 545px;
  height: 340px;
  display: block;
  position: relative;
  overflow: hidden;
}
.home-page .section.-upcoming-events .content-upcoming-events .slide-upcoming-events .img::before {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  background-color: #363636;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.5;
  transition: 0.25s ease;
  z-index: 13;
}
.home-page .section.-upcoming-events .content-upcoming-events .slide-upcoming-events .img span {
  width: 200px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e37835;
  position: absolute;
  left: 38px;
  bottom: 0;
  font-size: 2.4rem;
  color: #fff;
  z-index: 13;
}
.home-page .section.-upcoming-events .content-upcoming-events .slide-upcoming-events .img span::before {
  display: block;
  margin-right: 12px;
}
.home-page .section.-upcoming-events .content-upcoming-events .slide-upcoming-events .text {
  position: absolute;
  left: 38px;
  bottom: 74px;
  font-size: 3.6rem;
  padding-right: 38px;
  color: #fff;
  font-family: "db_ozone_x-bold";
  z-index: 13;
}
.home-page .section.-upcoming-events .content-upcoming-events .slide-upcoming-events .text h3 {
  line-height: 0.7;
}
.home-page .section.-upcoming-events .content-upcoming-events .slide-upcoming-events .text h4 {
  margin-bottom: 15px;
  font-size: 0.68em;
  color: #ed7725;
}
.home-page .section.-upcoming-events .dots {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1313;
}
.home-page .section.-upcoming-events .dots .owl-dot {
  width: 46px;
  height: 2px;
  background-color: #ccc;
}
.home-page .section.-upcoming-events .dots .owl-dot span {
  width: 0;
  height: 100%;
  display: block;
  background-color: #000;
  transition: 0.25s ease;
}
.home-page .section.-upcoming-events .dots .owl-dot.active span {
  width: 100%;
}
.home-page .section.-key-projects {
  background: url("../img/home/bg-key-projects.png") 50% 0 no-repeat;
  margin-top: 80px;
}
.home-page .section.-key-projects .page-center {
  width: 100%;
  max-width: inherit;
  padding-top: 162px;
}
.home-page .section.-key-projects .page-center::before, .home-page .section.-key-projects .page-center::after {
  display: none;
}
.home-page .section.-key-projects .nav-control {
  display: flex;
  position: absolute;
  left: 50%;
  top: 60px;
  margin-left: 500px;
}
.home-page .section.-key-projects .nav-control div {
  width: 38px;
  height: 38px;
  display: block;
  border: 3px solid #ed7725;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.25s ease;
}
.desktop .home-page .section.-key-projects .nav-control div:hover {
  transform: scale(1.2);
}
.home-page .section.-key-projects .nav-control div::before {
  content: "g";
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: #ed7725;
}
.home-page .section.-key-projects .nav-control div.owl-next::before {
  content: "h";
  text-indent: 2px;
}
.home-page .section.-key-projects .nav-control div.disabled {
  opacity: 0.2;
}
.home-page .section.-key-projects .nav-control div + div {
  margin-left: 6px;
}
.home-page .section.-key-projects .slide-key-projects .owl-stage-outer {
  overflow: visible;
}
.home-page .section.-key-projects .item {
  width: 1180px;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}
.home-page .section.-key-projects .item::before {
  content: "";
  width: 59px;
  height: 30px;
  background-color: #b3b3b3;
  position: absolute;
  left: 50%;
  top: 138px;
  margin-left: -675px;
}
.home-page .section.-key-projects .item::after {
  content: "";
  width: 52px;
  height: 9px;
  background-color: #ccc;
  position: absolute;
  left: 50%;
  top: 138px;
  margin-left: -590px;
}
.home-page .section.-key-projects .item h2 {
  margin-bottom: 62px;
  font-size: 9.27rem;
  font-family: "db_ozone_x-bold";
  color: #ed7725;
}
.home-page .section.-key-projects .item h2 small {
  display: block;
  margin-top: -20px;
  font-size: 0.47em;
  color: #000;
}
.home-page .section.-key-projects .detail {
  width: 570px;
}
.home-page .section.-key-projects .detail .scroll {
  height: 350px;
}
.home-page .section.-key-projects .detail .scroll .jspVerticalBar::before, .home-page .section.-key-projects .detail .scroll .jspVerticalBar::after {
  background-color: #fff;
}
.home-page .section.-key-projects .detail .area-content {
  padding-right: 40px;
}
.home-page .section.-key-projects .detail p,
.home-page .section.-key-projects .detail li {
  font-size: 2.2rem;
  line-height: 1.3;
}
.home-page .section.-key-projects .detail p + p {
  margin-top: 38px;
}
.home-page .section.-key-projects .detail .list-number {
  margin-top: 5px;
}
.home-page .section.-key-projects .detail li + li {
  margin-top: 5px;
}
.home-page .section.-key-projects .box-img {
  width: 580px;
  min-width: 580px;
  height: 515px;
  background-color: #fff;
  position: relative;
  margin: 44px 4px 0 0;
}
.home-page .section.-key-projects .box-img h2 {
  display: none;
}
.home-page .section.-key-projects .box-img .img {
  position: relative;
  right: -60px;
  top: -74px;
}
.home-page .section.-key-projects .box-img .text {
  width: 100%;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 0 5px 0 20px;
  font-family: "db_ozone_x-medium";
}
.home-page .section.-key-projects .box-img .text.video > a {
  width: calc(100% - 88px);
  display: flex;
  align-items: center;
}
.home-page .section.-key-projects .box-img .text.video > a::before {
  content: "j";
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #000;
  border-radius: 50%;
  margin-right: 15px;
  font-size: 12px;
  text-indent: 4px;
  color: #000;
}
.home-page .section.-key-projects .box-img .control {
  min-width: 88px;
  display: flex;
  align-items: center;
  margin-left: 20px;
}
.home-page .section.-key-projects .box-img .control p {
  margin-right: 10px;
  font-size: 2.2rem;
}
.home-page .section.-key-projects .box-img .control a::before {
  content: "g";
  font-size: 22px;
  color: #6d7174;
}
.home-page .section.-key-projects .box-img .control a.next::before {
  content: "h";
}
.home-page .section.-subscribe {
  margin-bottom: 170px;
}
.home-page .section.-subscribe .page-center::before {
  width: 544px;
  height: 490px;
  top: 120px;
}
.home-page .section.-subscribe .page-center::after {
  display: none;
}
.home-page .section.-subscribe .page-center .content-subscribe {
  position: relative;
  z-index: 13;
}
.home-page .section.-subscribe .page-center .box-subscribe {
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  position: absolute;
  right: 64px;
  top: 50%;
  transform: translateY(-50%);
  padding: 0 30px;
  font-size: 4.2rem;
  text-align: center;
}
.home-page .section.-subscribe .page-center .box-subscribe h3 {
  font-size: 1em;
  line-height: 1.1;
}
.home-page .section.-subscribe .page-center .box-subscribe h3 strong {
  font-size: 1.18em;
  font-family: "db_ozone_x";
}
.home-page .section.-subscribe .page-center .box-subscribe .btn-style {
  margin-top: 40px;
}
.home-page .section.-movements .page-center::before {
  background-color: #ed7725;
}
.home-page .section.-movements .content-movements {
  position: relative;
  margin-top: 20px;
  z-index: 13;
}

@media screen and (max-width: 1024px) {
  .home-page .section.-highlight-banner .box-text {
    width: 500px;
    padding: 40px;
    font-size: 4rem;
  }
  .home-page .section.-highlight-banner .box-text::before, .home-page .section.-highlight-banner .box-text::after {
    width: 30px;
    height: 30px;
    left: 0;
    top: -60px;
  }
  .home-page .section.-highlight-banner .box-text::after {
    left: 30px;
    top: -30px;
  }
  .home-page .section.-highlight-banner .box-text h2::before {
    width: 28px;
    top: 9px;
    left: -40px;
  }
  .home-page .section.-highlights .slide-highlights {
    width: 50%;
    min-width: 50%;
  }
  .home-page .section.-resources .page-center {
    overflow: visible;
  }
  .home-page .section.-resources .page-center .head {
    margin: 0;
  }
  .home-page .section .list-resources .item .text span {
    font-size: 2.6rem;
  }
  .home-page .section .list-resources .item .text .text-view-all {
    margin-left: 10px;
    font-size: 1.8rem;
  }
  .home-page .section.-key-projects .page-center {
    padding-top: 72px;
  }
  .home-page .section.-key-projects .nav-control {
    left: auto;
    right: 15px;
    margin-left: 0;
  }
  .home-page .section.-key-projects .content-key-projects {
    width: calc(100% - 30px);
    margin: 0 auto;
    overflow: hidden;
  }
  .home-page .section.-key-projects .detail {
    width: 400px;
  }
  .home-page .section.-key-projects .detail h2 {
    margin-bottom: 40px;
    font-size: 8rem;
  }
  .home-page .section.-key-projects .detail .scroll {
    height: 400px;
  }
  .home-page .section.-key-projects .box-img {
    width: 540px;
    min-width: inherit;
    height: auto;
    align-self: baseline;
    box-shadow: none;
  }
  .home-page .section.-key-projects .box-img .img {
    left: 0;
    top: 0;
  }
  .home-page .section.-key-projects .box-img .text {
    position: relative;
    bottom: 0;
  }
  .home-page .section.-subscribe {
    margin-bottom: 140px;
  }
  .home-page .section.-subscribe .page-center::before {
    height: 420px;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5) {
  .home-page .section.-highlight-banner {
    width: 100%;
    height: auto;
    overflow: hidden;
  }
  .home-page .section.-highlight-banner .owl-carousel,
  .home-page .section.-highlight-banner .owl-stage-outer,
  .home-page .section.-highlight-banner .item,
  .home-page .section.-highlight-banner .img {
    height: auto;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  .home-page .section.-highlight-banner {
    width: 100%;
    height: auto;
    overflow: hidden;
  }
  .home-page .section.-highlight-banner .owl-carousel,
  .home-page .section.-highlight-banner .owl-stage-outer,
  .home-page .section.-highlight-banner .item,
  .home-page .section.-highlight-banner .img {
    height: auto;
  }
}
@media only screen and (max-width: 1023px) {
  .home-page .section.-highlight-banner {
    width: 100%;
    height: auto;
    overflow: hidden;
  }
  .home-page .section.-highlight-banner .owl-carousel,
  .home-page .section.-highlight-banner .owl-stage-outer,
  .home-page .section.-highlight-banner .item,
  .home-page .section.-highlight-banner .img {
    height: auto;
  }
  .home-page .section.-highlight-banner .nav-control {
    right: 20px;
    top: auto;
    bottom: 20px;
    transform: translateY(0);
  }
  .home-page .section.-highlights .slide-highlights {
    width: 45%;
    min-width: 45%;
  }
  .home-page .section.-highlights .slide-highlights .item .text h3 {
    font-size: 3rem;
  }
  .home-page .section.-key-projects .page-center {
    padding-top: 30px;
  }
  .home-page .section.-key-projects .content-key-projects {
    width: calc(100% - 30px);
    margin: 0 auto;
    overflow: hidden;
  }
  .home-page .section.-key-projects .item {
    flex-wrap: wrap;
  }
  .home-page .section.-key-projects .item::before, .home-page .section.-key-projects .item::after {
    display: none;
  }
  .home-page .section.-key-projects .detail {
    width: 100%;
    order: 1;
    margin-top: 20px;
  }
  .home-page .section.-key-projects .detail h2 {
    display: none;
  }
  .home-page .section.-key-projects .box-img {
    width: 100%;
    min-width: inherit;
    background: none;
  }
  .home-page .section.-key-projects .box-img h2 {
    display: block;
    margin-bottom: 10px;
  }
  .home-page .section.-key-projects .nav-control {
    top: 20px;
    z-index: 13;
  }
  .home-page .section.-subscribe {
    margin-bottom: 140px;
  }
  .home-page .section.-subscribe .page-center::before {
    height: 320px;
  }
  .home-page .section.-subscribe .page-center .box-subscribe {
    right: 0;
  }
}
@media only screen and (max-width: 767px) {
  .home-page .section.-highlight-banner .box-text {
    width: 100%;
    position: relative;
    left: 0;
    bottom: 0;
  }
  .home-page .section.-highlight-banner .box-text::before, .home-page .section.-highlight-banner .box-text::after {
    display: none;
  }
  .home-page .section.-highlights .content-highlights {
    flex-wrap: wrap;
  }
  .home-page .section.-highlights .slide-highlights {
    width: 100%;
    min-width: 100%;
  }
  .home-page .section.-highlights .list-highlights {
    margin: 18px 0 0 -22px;
  }
  .home-page .section.-highlights .list-highlights .item {
    border: none;
    padding: 0;
    margin-top: 22px;
  }
  .home-page .section .list-resources .item .text {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .home-page .section .list-resources .item .text span {
    text-align: center;
  }
  .home-page .section .list-resources .item .text .text-view-all {
    width: calc(100% - 30px);
    max-width: inherit;
    margin: 20px 0 0;
  }
  .home-page .section.-upcoming-events {
    min-height: auto;
    padding-bottom: 80px;
    overflow: hidden;
  }
  .home-page .section.-upcoming-events .page-center::after {
    display: none;
    top: 78px;
  }
  .home-page .section.-upcoming-events .content-upcoming-events {
    margin-top: 20px;
  }
  .home-page .section.-upcoming-events .content-upcoming-events .slide-upcoming-events .img {
    width: 400px;
    height: 250px;
  }
  .home-page .section.-upcoming-events .content-upcoming-events .slide-upcoming-events .text {
    font-size: 3rem;
  }
  .home-page .section.-upcoming-events .dots {
    background-color: #fff;
    padding: 20px 0;
  }
  .home-page .section.-subscribe {
    margin-bottom: 0;
  }
  .home-page .section.-subscribe .page-center::before {
    display: none;
  }
  .home-page .section.-subscribe .page-center .box-subscribe {
    width: 100%;
    height: auto;
    position: relative;
    right: auto;
    left: 0;
    top: 0;
    transform: translateY(0);
    padding: 40px 0;
  }
}
@media only screen and (max-width: 479px) {
  .home-page .section.-highlight-banner .box-text {
    padding: 20px 40px;
    font-size: 2.8rem;
  }
  .home-page .section.-highlight-banner .box-text h2::before {
    top: 6px;
  }
  .home-page .section.-highlight-banner .nav-control {
    width: calc(100% - 40px);
    position: relative;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    margin-top: 20px;
    font-size: 1.8rem;
    color: #000;
  }
  .home-page .section.-highlight-banner .nav-control .progress {
    background-color: #b3b3b3;
  }
  .home-page .section.-highlight-banner .nav-control .progress .bar {
    background-color: #2c2c2c;
  }
  .home-page .section.-highlight-banner .nav-control .control .prev,
  .home-page .section.-highlight-banner .nav-control .control .next {
    width: 30px;
    height: 30px;
    border-color: #b3b3b3;
  }
  .home-page .section.-highlight-banner .nav-control .control .prev::before,
  .home-page .section.-highlight-banner .nav-control .control .next::before {
    font-size: 16px;
    color: #b3b3b3;
  }
  .home-page .section.-highlight-banner .nav-control .control .dots .owl-dot {
    background-color: #b3b3b3;
  }
  .home-page .section.-highlights .list-highlights {
    width: 100%;
    margin-left: 0;
  }
  .home-page .section.-highlights .list-highlights .item {
    width: 100%;
    margin-left: 0;
  }
  .home-page .section.-upcoming-events::before {
    top: 160px;
  }
  .home-page .section.-upcoming-events .page-center::after {
    display: none;
    top: 78px;
  }
  .home-page .section.-upcoming-events .content-upcoming-events .slide-upcoming-events .img {
    width: 290px;
    height: 181px;
  }
  .home-page .section.-upcoming-events .content-upcoming-events .slide-upcoming-events .img span {
    left: 0;
    font-size: 1.8rem;
  }
  .home-page .section.-upcoming-events .content-upcoming-events .slide-upcoming-events .text {
    left: 10px;
    bottom: 61px;
    padding-right: 10px;
    font-size: 2rem;
  }
  .home-page .section.-upcoming-events .content-upcoming-events .slide-upcoming-events .text h3 {
    line-height: 1;
  }
  .home-page .section.-key-projects .item h2 {
    margin-bottom: 30px;
    font-size: 6rem;
    line-height: 0.7;
  }
  .home-page .section.-key-projects .item h2 small {
    margin-top: 10px;
    font-size: 0.55em;
  }
  .home-page .section.-key-projects .box-img .text {
    flex-wrap: wrap;
    padding: 0;
    margin-top: 10px;
  }
  .home-page .section.-key-projects .box-img .control {
    margin: 0;
  }
  .home-page .section.-key-projects .detail {
    margin-top: 20px;
  }
  .home-page .section.-key-projects .detail li,
  .home-page .section.-key-projects .detail p {
    font-size: 1.8rem;
  }
  .home-page .section.-subscribe {
    margin-bottom: 0;
  }
  .home-page .section.-subscribe .page-center::before {
    display: none;
  }
  .home-page .section.-subscribe .page-center img {
    height: 180px;
    object-fit: cover;
  }
  .home-page .section.-subscribe .page-center .box-subscribe {
    font-size: 3.6rem;
  }
  .home-page .section.-subscribe .page-center .box-subscribe .btn-style {
    width: 100%;
  }
}
.contact-us-page #footer {
  margin-top: 0;
}
.contact-us-page .content-contact-us {
  margin-top: 94px;
  display: flex;
}
.contact-us-page .content-contact-us .location,
.contact-us-page .content-contact-us .contact {
  width: 50%;
}
.contact-us-page .content-contact-us .location .map {
  height: 520px;
  position: relative;
}
.contact-us-page .content-contact-us .location .map .btn-style {
  width: 220px;
  height: 62px;
  position: absolute;
  right: 0;
  bottom: 0;
}
.contact-us-page .content-contact-us .location .map .btn-style::before {
  margin-right: 15px;
}
.contact-us-page .content-contact-us .location .detail {
  height: 100%;
  background-color: #ed7725;
  padding: 42px 72px 42px 90px;
  color: #fff;
  font-size: 2.4rem;
}
.contact-us-page .content-contact-us .location .detail h2 {
  font-size: 1.25em;
  font-family: "db_ozone_x-medium";
}
.contact-us-page .content-contact-us .location .detail p {
  position: relative;
  padding-left: 25px;
  margin-top: 40px;
  line-height: 1.4;
}
.contact-us-page .content-contact-us .location .detail p::before {
  position: absolute;
  left: 0;
  top: 5px;
  font-size: 18px;
}
.contact-us-page .content-contact-us .location .detail p.icon-telephone::before {
  font-size: 20px;
}
.contact-us-page .content-contact-us .contact {
  padding: 76px 0 0 90px;
}
.contact-us-page .content-contact-us .contact h2 {
  font-size: 4.8rem;
  font-family: "db_ozone_x-bold";
}
.contact-us-page .content-contact-us .contact .form-contact {
  width: 420px;
  max-width: 100%;
  margin-top: 50px;
}
.contact-us-page .content-contact-us .contact .form-contact .agree {
  justify-content: flex-start;
}
.contact-us-page .content-contact-us .contact .form-contact .agree .col {
  width: 100px;
  margin: 10px 0 20px;
}
.contact-us-page .content-contact-us .contact .form-contact .agree label {
  font-family: "db_ozone_x-bold";
}
.contact-us-page .content-contact-us .contact .form-contact .agree p {
  width: 100%;
  font-size: 2rem;
  font-family: "db_ozone_x-medium";
}
.contact-us-page .content-contact-us .contact .form-contact .agree p strong {
  font-size: 1.1em;
  font-family: "db_ozone_x-bold";
}
.contact-us-page .content-contact-us .contact .form-contact .btn-style {
  width: 216px;
  margin: 0;
}

@media screen and (max-width: 1024px) {
  .contact-us-page .content-contact-us .location .detail {
    padding: 40px;
  }
  .contact-us-page .content-contact-us .location .detail p {
    margin-top: 20px;
  }
}
@media screen and (max-width: 1023px) {
  .contact-us-page .content-contact-us {
    flex-wrap: wrap;
  }
  .contact-us-page .content-contact-us .location {
    width: 100%;
  }
  .contact-us-page .content-contact-us .location .detail {
    height: auto;
  }
  .contact-us-page .content-contact-us .contact {
    width: 100%;
    padding: 40px;
  }
  .contact-us-page .content-contact-us .contact .form-contact {
    width: 100%;
  }
}
@media screen and (max-width: 479px) {
  .contact-us-page .content-contact-us .location .map {
    height: 300px;
  }
  .contact-us-page .content-contact-us .location .detail {
    padding: 30px 20px;
    font-size: 1.8rem;
  }
  .contact-us-page .content-contact-us .location .detail p::before {
    font-size: 10px;
  }
  .contact-us-page .content-contact-us .location .detail p.icon-telephone::before {
    font-size: 14px;
  }
  .contact-us-page .content-contact-us .contact {
    padding: 30px 20px;
  }
  .contact-us-page .content-contact-us .contact h2 {
    font-size: 4rem;
  }
  .contact-us-page .content-contact-us .contact .form-contact {
    margin-top: 20px;
  }
  .contact-us-page .content-contact-us .contact .form-contact .btn-style {
    width: 100%;
  }
}
.board-of-directors-page .content-board-of-directors {
  padding-top: 0;
}
.board-of-directors-page .slide-group-btn {
  width: 100%;
  border-bottom: 1px solid #dce1e5;
  margin-bottom: 90px;
  font-size: 21px;
}
.board-of-directors-page .slide-group-btn a {
  height: 110px;
  display: block;
  position: relative;
  padding: 40px 5px 90px;
  color: #000;
  text-align: center;
  white-space: nowrap;
}
.board-of-directors-page .slide-group-btn a::after {
  content: "";
  width: 100%;
  height: 3px;
  display: block;
  background-color: #ed7725;
  position: absolute;
  left: 0;
  bottom: -1px;
  transform: scale(0);
  transition: 0.25s ease;
}
.board-of-directors-page .slide-group-btn a.active {
  font-family: "db_ozone_x-bold";
}
.board-of-directors-page .slide-group-btn a.active.active::after {
  transform: scale(1);
}
.board-of-directors-page .slide-group-btn .owl-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: -40px;
}
.board-of-directors-page .slide-group-btn .owl-nav > div {
  width: 30px;
  height: 30px;
  border: 1px solid #000;
  border-radius: 50%;
  position: relative;
  left: 0;
  top: 0;
}
.board-of-directors-page .slide-group-btn .owl-nav > div + div {
  margin-left: 5px;
}
.board-of-directors-page .slide-group-btn .owl-nav.disabled {
  display: none !important;
}
.board-of-directors-page .heading h2 {
  padding-bottom: 40px;
  line-height: 0.9;
}
.board-of-directors-page .list-board .row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.board-of-directors-page .list-board .row + .row {
  border-top: 1px dashed #ccc;
  padding-top: 32px;
  margin-top: 32px;
}
.board-of-directors-page .list-board .item {
  width: 170px;
  margin: 10px 30px;
  text-align: center;
}
.board-of-directors-page .list-board .item a {
  display: inline-block;
  position: relative;
}
.board-of-directors-page .list-board .item a::before {
  width: 50px;
  height: 50px;
  display: none;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  right: 0;
  top: 110px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16);
  font-size: 28px;
  color: #ed7725;
  z-index: 13;
}
.board-of-directors-page .list-board .item a:not([href]) {
  cursor: default;
  color: #000;
}
.board-of-directors-page .list-board .item .img {
  width: 170px;
  height: 170px;
  display: block;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
}
.board-of-directors-page .list-board .item .img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}
.board-of-directors-page .list-board .item .detail {
  width: 100%;
  display: block;
  position: relative;
  margin: 45px auto 0;
  text-align: left;
  font-size: 24px;
  font-family: "db_ozone_x-bold";
}
.text-large .board-of-directors-page .list-board .item .detail {
  font-size: 26px;
}
.board-of-directors-page .list-board .item .detail::before {
  content: "";
  width: 7px;
  height: 7px;
  display: none;
  border: 1px solid #000;
  border-radius: 50%;
  position: absolute;
  left: -12px;
  top: 2px;
}
.board-of-directors-page .list-board .item .detail small {
  display: block;
  font-size: 0.8em;
  font-family: "db_ozone_x";
  color: #8a8a8a;
}
.board-of-directors-page .list-board .row:first-child .item {
  width: 100%;
}
.board-of-directors-page .list-board .row:first-child .item a {
  width: 224px;
}
.board-of-directors-page .list-board .row:first-child .item a::before {
  width: 68px;
  height: 68px;
  top: 145px;
  font-size: 34px;
}
.board-of-directors-page .list-board .row:first-child .item .img {
  width: 224px;
  height: 224px;
}
.board-of-directors-page .list-board .row:first-child .item .detail {
  width: 100%;
  font-size: 26px;
}
.text-large .board-of-directors-page .list-board .row:first-child .item .detail {
  font-size: 28px;
}
.board-of-directors-page .list-board .row:first-child .item .detail::before {
  width: 8px;
  height: 8px;
}
.board-of-directors-page .remark {
  font-size: 22px;
}
.text-large .board-of-directors-page .remark {
  font-size: 24px;
}
.board-of-directors-page .remark li {
  font-size: 1em;
}
.board-of-directors-page .remark li + li {
  margin-top: 15px;
}

@media screen and (max-width: 1024px) {
  .board-of-directors-page .heading {
    font-size: 50px;
  }
  .text-large .board-of-directors-page .heading {
    font-size: 52px;
  }
  .board-of-directors-page .list-board .row {
    flex-wrap: wrap;
  }
  .board-of-directors-page .list-board .item {
    margin-left: 20px;
  }
}
@media screen and (max-width: 1023px) {
  .board-of-directors-page .heading {
    font-size: 40px;
  }
  .text-large .board-of-directors-page .heading {
    font-size: 42px;
  }
  .board-of-directors-page .list-board .row + .row {
    margin-top: 80px;
  }
  .board-of-directors-page .list-board .item {
    margin: 35px 35px 0;
  }
  .board-of-directors-page .list-board .item:first-child {
    margin-left: 35px;
  }
}
@media screen and (max-width: 767px) {
  .board-of-directors-page .heading br {
    display: none !important;
  }
}
@media screen and (max-width: 479px) {
  .board-of-directors-page .heading {
    font-size: 32px;
  }
  .text-large .board-of-directors-page .heading {
    font-size: 34px;
  }
  .board-of-directors-page .heading h2 {
    padding-bottom: 30px;
    line-height: 1;
  }
  .board-of-directors-page .slide-group-btn {
    font-size: 17px;
  }
}
html .career-page .jspVerticalBar::before, html .career-page .jspVerticalBar::after {
  background-color: #fff !important;
}
.career-page.-life-tij #footer, .career-page.-jobs-opening #footer {
  margin-top: -108px;
}
@media (max-width: 1023px) {
  .career-page.-life-tij #footer, .career-page.-jobs-opening #footer {
    margin-top: -40px;
  }
}
.career-page .old-theme .title-career {
  background: #2f3844 url("../img/career/bg-title-career.jpg") 50% 50%/cover no-repeat;
  position: relative;
}
.career-page .old-theme .title-career .page-center {
  min-height: 360px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 0;
}
.career-page .old-theme .title-career h2 {
  margin-right: 130px;
  font-size: 60px;
  line-height: 0.7em;
  font-family: "db_ozone_x-bold";
  color: #ed7725;
}
.text-large .career-page .old-theme .title-career h2 {
  font-size: 62px;
}
.career-page .old-theme .title-career p {
  font-size: 22px;
  line-height: 1.6em;
  color: #fff;
}
.text-large .career-page .old-theme .title-career p {
  font-size: 24px;
}
.career-page .old-theme .remark {
  font-size: 22px;
  line-height: 1.5;
}
.text-large .career-page .old-theme .remark {
  font-size: 24px;
}
.career-page .old-theme .remark section + section {
  border-top: 1px solid #dce1e5;
  padding-top: 40px;
  margin-top: 40px;
}
.career-page .old-theme .remark h3 {
  margin-bottom: 5px;
  font-size: 1.1em;
  line-height: 1;
  font-family: "db_ozone_x-medium";
}
.career-page .old-theme .remark p strong {
  font-family: "db_ozone_x-medium";
  color: #ed7725;
}
.career-page .old-theme .group-box-content {
  position: relative;
}
.career-page .old-theme .group-box-content > div .img img {
  object-position: top;
}
.career-page .old-theme .btn-load-more {
  width: 216px;
}
.career-page .old-theme .list-content-employees {
  margin-top: 46px;
}
.career-page .old-theme .list-content-employees .group-list {
  margin-top: -12px;
}
.career-page .old-theme .list-content-employees .row {
  width: calc(100% + 12px);
  display: flex;
  margin: 12px 0 0 -12px;
}
.career-page .old-theme .list-content-employees a {
  width: calc(25% - 6px);
  display: block;
  position: relative;
  margin: 0 0 0 12px;
}
.career-page .old-theme .list-content-employees a img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.career-page .old-theme .list-content-employees .row:nth-of-type(odd) a:nth-of-type(1) {
  width: calc(50% - 6px);
}
.career-page .old-theme .list-content-employees .row:nth-of-type(even) a:nth-of-type(2) {
  width: calc(50% - 6px);
}
.career-page .old-theme .list-content-employees .icon-play::before {
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #fff;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 26px;
  text-indent: 5px;
  color: #fff;
  z-index: 13;
}
.career-page .old-theme .content-job {
  background-color: #f5f8fb;
  padding-bottom: 80px;
}
.career-page .old-theme .content-job .page-center {
  flex-wrap: nowrap;
}
.career-page .old-theme .content-job .title-page h2 {
  font-family: "db_ozone_x-bold";
}
.career-page .old-theme .content-job .menu-left a {
  padding-top: 5px;
  font-size: 24px;
}
.text-large .career-page .old-theme .content-job .menu-left a {
  font-size: 26px;
}
.career-page .old-theme .content-job .box-search-job {
  border: 1px solid #dce1e5;
  padding: 26px 22px;
  margin: 0;
}
.career-page .old-theme .content-job .box-search-job .icon-search {
  position: relative;
}
.career-page .old-theme .content-job .box-search-job .icon-search:before {
  position: absolute;
  left: 0;
  top: 18px;
  color: #b4b9bf;
}
.career-page .old-theme .content-job .box-search-job .inputtext-style {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #ebebeb;
  padding-right: 340px !important;
  padding-left: 40px;
  font-size: 30px;
}
.text-large .career-page .old-theme .content-job .box-search-job .inputtext-style {
  font-size: 32px;
}
.career-page .old-theme .content-job .box-search-job .sorting {
  display: flex;
  align-items: flex-end;
  position: absolute;
  right: 22px;
  top: 24px;
}
.career-page .old-theme .content-job .box-search-job .sorting label {
  margin: 0 20px 0 0;
  font-family: "db_ozone_x-bold";
}
.career-page .old-theme .content-job .box-search-job .sorting .select-box {
  width: 240px;
}
.career-page .old-theme .content-job .box-search-job .sorting .select-box:before {
  height: 36px;
}
.career-page .old-theme .content-job .box-search-job .sorting .select-box .select-style {
  background: #f4f4f4;
  border: 2px solid #ebebeb;
  border-radius: 2px;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 20px;
}
.career-page .old-theme .content-job .primary-content {
  width: 870px;
  min-width: 870px;
}
.career-page .old-theme .content-job .table-job {
  margin-top: 25px;
}
.career-page .old-theme .content-job .table-job h3 {
  margin-bottom: 36px;
  font-size: 30px;
  font-family: "db_ozone_x-medium";
  color: #ed7725;
  text-transform: uppercase;
}
.text-large .career-page .old-theme .content-job .table-job h3 {
  font-size: 32px;
}
.career-page .old-theme .content-job .table-job th:nth-of-type(1) {
  width: 160px;
}
.career-page .old-theme .content-job .table-job th:nth-of-type(2) {
  width: 24px;
}
.career-page .old-theme .content-job .table-job th:nth-of-type(3) {
  width: 200px;
}
.career-page .old-theme .content-job .table-job th:nth-of-type(4) {
  width: 200px;
}
.career-page .old-theme .content-job .table-job th:nth-of-type(5) {
  width: 70px;
}
.career-page .old-theme .content-job .table-job th:nth-of-type(6) {
  width: 74px;
}
.career-page .old-theme .content-job .table-job td:nth-of-type(1),
.career-page .old-theme .content-job .table-job td:nth-of-type(4) {
  text-align: left;
}
.career-page .old-theme .content-job .table-job td:nth-of-type(1) {
  color: #ed7725;
}
.career-page .old-theme .content-job .table-job .btn-style {
  width: 74px;
  min-width: inherit;
  padding: 6px 0;
  font-size: 16px;
}
.career-page .old-theme .interns-detail .page-center {
  display: flex;
  justify-content: space-between;
}
.career-page .old-theme .interns-detail .page-center > div {
  width: 50%;
}
.career-page .old-theme .interns-detail .left .img {
  position: relative;
}
.career-page .old-theme .interns-detail .left .img:before, .career-page .old-theme .interns-detail .left .img:after {
  content: "";
  width: 126px;
  height: 8px;
  display: block;
  background-color: #ed7725;
  position: absolute;
  right: 8.5%;
  top: 0;
}
.career-page .old-theme .interns-detail .left .img:before {
  top: 17%;
}
.career-page .old-theme .interns-detail .left .img:after {
  top: 81%;
}
.career-page .old-theme .interns-detail .left .img img {
  position: relative;
  margin: 0;
  z-index: 13;
}
.career-page .old-theme .interns-detail .left p {
  margin-top: -5%;
  padding: 0 12.3% 0 9.6%;
  font-size: 22px;
  line-height: 1.4em;
}
.text-large .career-page .old-theme .interns-detail .left p {
  font-size: 24px;
}
.career-page .old-theme .interns-detail .right {
  margin-top: 50px;
  font-size: 22px;
}
.text-large .career-page .old-theme .interns-detail .right {
  font-size: 24px;
}
.career-page .old-theme .interns-detail .right h3 {
  margin-bottom: 30px;
  font-size: 74px;
  line-height: 0.7em;
  font-family: "db_ozone_x-bold";
}
.text-large .career-page .old-theme .interns-detail .right h3 {
  font-size: 76px;
}
.career-page .old-theme .interns-detail .right h3 span {
  color: #ed7725;
}
.career-page .old-theme .interns-detail .editor {
  line-height: 1.4em;
}
.career-page .old-theme .interns-detail .editor > ol > li {
  margin-top: 40px;
  font-family: "db_ozone_x-bold";
}
.career-page .old-theme .interns-detail .editor > ol > li:first-child {
  margin-top: 0;
}
.career-page .old-theme .interns-detail .editor ol ul {
  margin-left: -18px;
}
.career-page .old-theme .interns-detail .editor ol ul li {
  padding-left: 20px;
  margin-top: 10px;
  line-height: 1.2em;
  font-family: "db_ozone_x";
}
.career-page .old-theme .interns-detail .editor ol ul li:before {
  width: 6px;
  height: 6px;
  background: none;
  border: 1px solid #ed7725;
  top: 9px;
}
.career-page .old-theme .interns-detail .editor ol ol li {
  margin-top: 10px;
  font-family: "db_ozone_x";
}
.career-page .old-theme .interns-detail .editor a {
  color: #ed7725;
}
.career-page .old-theme .interns-detail .editor .flex-content {
  display: flex;
}
.career-page .old-theme .interns-detail .editor .flex-content span:first-child {
  min-width: 180px;
}
.career-page .old-theme .interns-detail .btn-apply {
  width: 182px;
  margin: 40px 0 0;
}
.career-page .old-theme .slide-interns {
  margin-top: 76px;
}
.career-page .old-theme .slide-interns .inner {
  border: 1px solid #ed7725;
  padding: 36px 46px;
  margin: 0 76px;
}
.career-page .old-theme .slide-interns .inner a {
  width: calc(100% + 244px);
  display: block;
  position: relative;
  margin: 20px 0 30px -122px;
}
.career-page .old-theme .slide-interns .inner .icon-play::before {
  width: 84px;
  height: 84px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #fff;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 32px;
  text-indent: 5px;
  color: #fff;
  z-index: 13;
}
.career-page .old-theme .slide-interns .inner h3 {
  font-size: 28px;
  font-family: "db_ozone_x-bold";
}
.text-large .career-page .old-theme .slide-interns .inner h3 {
  font-size: 30px;
}
.career-page .old-theme .slide-interns .inner p {
  font-size: 24px;
}
.text-large .career-page .old-theme .slide-interns .inner p {
  font-size: 26px;
}
.career-page .old-theme .slide-interns .inner p strong {
  font-family: "db_ozone_x-bold";
}
.career-page .old-theme .slide-interns .owl-dots {
  position: relative;
  bottom: 0;
  margin-top: 30px;
}
.career-page .old-theme .slide-interns .owl-dots .owl-dot {
  background-color: #000;
}
.career-page .old-theme .slide-interns .owl-dots .owl-dot.active {
  background-color: #ed7725;
}

@media screen and (max-width: 1023px) {
  .old-theme .content-job .page-center {
    flex-wrap: wrap !important;
  }
  .career-page .old-theme .title-career .page-center {
    display: block;
  }
  .career-page .old-theme .title-career p {
    margin-top: 20px;
  }
}
@media screen and (max-width: 767px) {
  .career-page .old-theme .content-job .box-search-job .inputtext-style {
    padding-right: 0 !important;
  }
  .career-page .old-theme .content-job .box-search-job .sorting {
    position: relative;
    right: auto;
    top: auto;
    margin-top: 20px;
  }
  .career-page .old-theme .content-job .box-search-job .sorting .select-box {
    width: 100%;
  }
  .career-page .old-theme .content-job .table-job h3 {
    margin-bottom: 20px;
  }
  .old-theme .interns-detail .page-center {
    flex-wrap: wrap;
  }
  .old-theme .interns-detail .page-center > div {
    width: 100%;
  }
  .old-theme .interns-detail .page-center .left {
    order: 2;
  }
  .old-theme .interns-detail .btn-style {
    width: 100%;
  }
  .old-theme .slide-interns {
    margin-top: 40px;
  }
  .old-theme .slide-interns .inner {
    padding: 20px;
    margin: 0 20px;
  }
  .old-theme .slide-interns .inner a {
    width: calc(100% + 80px);
    margin-left: -40px;
  }
}
@media screen and (max-width: 479px) {
  .career-page .old-theme .slide-group-btn {
    margin-bottom: 80px;
  }
  .career-page .old-theme .title-career .page-center {
    min-height: inherit;
    padding: 60px 0;
  }
  .career-page .old-theme .title-career h2 {
    font-size: 45px;
  }
  .text-large .career-page .old-theme .title-career h2 {
    font-size: 47px;
  }
  .career-page .old-theme .list-content-employees .row {
    width: calc(100% + 6px);
    margin-left: -6px;
  }
  .career-page .old-theme .list-content-employees .row + .row {
    margin-top: 6px;
  }
  .career-page .old-theme .list-content-employees a {
    width: calc(25% - 3px);
    margin-left: 6px;
  }
  .career-page .old-theme .list-content-employees .row:nth-of-type(odd) a:nth-of-type(1) {
    width: calc(50% - 3px);
  }
  .career-page .old-theme .list-content-employees .row:nth-of-type(even) a:nth-of-type(2) {
    width: calc(50% - 3px);
  }
  .career-page .old-theme .list-content-employees .icon-play:before {
    width: 40px;
    height: 40px;
    font-size: 14px;
  }
  .old-theme .interns-detail .left {
    margin-top: 40px;
  }
  .old-theme .interns-detail .left p {
    font-size: 20px;
  }
  .text-large .old-theme .interns-detail .left p {
    font-size: 22px;
  }
  .old-theme .interns-detail .right h3 {
    font-size: 50px;
  }
  .text-large .old-theme .interns-detail .right h3 {
    font-size: 52px;
  }
  .old-theme .interns-detail .editor .flex-content {
    display: block;
  }
  .old-theme .interns-detail .editor .flex-content span {
    display: block;
  }
  .old-theme .interns-detail .editor .flex-content span br {
    display: none;
  }
  .old-theme .interns-detail .editor .flex-content span:first-child {
    width: 100%;
  }
  .old-theme .slide-interns .inner {
    margin: 0;
  }
  .old-theme .slide-interns .inner h3 {
    font-size: 24px;
  }
  .text-large .old-theme .slide-interns .inner h3 {
    font-size: 26px;
  }
  .old-theme .slide-interns .inner p {
    font-size: 20px;
  }
  .text-large .old-theme .slide-interns .inner p {
    font-size: 22px;
  }
  .old-theme .slide-interns .inner a {
    width: 100%;
    margin-left: 0;
  }
  .old-theme .slide-interns .inner .icon-play:before {
    width: 60px;
    height: 60px;
    font-size: 20px;
  }
  .old-theme .slide-interns img {
    height: 160px;
    object-fit: cover;
  }
}
.management-page #footer {
  margin-top: -108px;
}
.management-page .banner-page .box-text {
  min-height: 350px;
}
.management-page .banner-page .box-text h1 {
  font-size: 72px;
}
.text-large .management-page .banner-page .box-text h1 {
  font-size: 74px;
}
.management-page .group-link {
  width: 400px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-30%, -30%);
  text-align: left;
}
.management-page .group-link a {
  min-height: 32px;
  display: block;
  position: relative;
  padding-left: 62px;
  font-size: 36px;
  color: #fff;
}
.text-large .management-page .group-link a {
  font-size: 38px;
}
.management-page .group-link a::before {
  content: "h";
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #fff;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 0;
  font-size: 20px;
  transition: 0.25s ease;
}
.desktop .management-page .group-link a:hover::before {
  border-width: 3px;
}
.management-page .group-link a + a {
  margin-top: 30px;
}

@media screen and (min-width: 1400px) {
  .banner-page .group-link {
    transform: translate(-50%, -30%) scale(1.2);
  }
}
@media screen and (max-width: 1024px) {
  .management-page .group-link {
    left: 84px;
    transform: translate(0, -20%);
  }
}
@media screen and (max-width: 1023px) {
  .management-page #container {
    min-height: auto;
  }
  .management-page .box-text {
    min-height: auto !important;
  }
  .management-page .box-text h1 {
    margin-bottom: 0;
  }
  .management-page .group-link {
    width: 100%;
    background-color: #ed7725;
    position: relative;
    left: 0;
    top: 0;
    padding: 0 20px 110px;
    transform: translate(0, 0);
  }
}
@media screen and (max-width: 767px) {
  .management-page .group-link {
    padding-bottom: 60px;
  }
}
@media screen and (max-width: 479px) {
  .management-page .box-text h1 {
    font-size: 50px !important;
  }
  .text-large .management-page .box-text h1 {
    font-size: 52px !important;
  }
  .management-page .group-link a {
    padding: 1px 0 0 35px;
    font-size: 33px;
  }
  .text-large .management-page .group-link a {
    font-size: 35px;
  }
  .management-page .group-link a::before {
    width: 22px;
    height: 22px;
    font-size: 14px;
  }
}
.our-partners-page .tabs .tab {
  display: none;
}
.our-partners-page .tabs .tab.active {
  display: block;
}
.our-partners-page .tabs .section + .section {
  margin-top: 66px;
}
.our-partners-page .nav-tab {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 72px;
}
.our-partners-page .nav-tab a {
  width: 252px;
  height: 72px;
  background-color: #dee0de;
}
.our-partners-page .nav-tab a.active {
  background-color: #ed7725;
}
.our-partners-page .list-partners {
  display: flex;
  flex-wrap: wrap;
}
.our-partners-page .list-partners .item {
  width: 20%;
  border: 1px solid #eceff1;
  margin: -1px 0 0 -1px;
}

@media only screen and (max-width: 767px) {
  .our-partners-page .list-partners .item {
    width: 25%;
  }
}
@media only screen and (max-width: 767px) {
  .our-partners-page .list-partners .item {
    width: 33.33%;
  }
  .our-partners-page .nav-tab {
    width: 100%;
    margin-top: 20px;
  }
  .our-partners-page .nav-tab a {
    min-width: inherit;
    width: 50%;
    height: 50px;
    padding: 0;
    font-size: 1.8rem;
  }
}
.complaint-page .section-definition {
  background-color: #2f3844;
  position: relative;
  padding: 60px 0 50px;
  color: #fff;
  z-index: 1;
}
.complaint-page .section-definition .page-center {
  justify-content: flex-start;
}
.complaint-page .section-definition .heading {
  display: flex;
  align-items: center;
  font-size: 42px;
}
.text-large .complaint-page .section-definition .heading {
  font-size: 44px;
}
.complaint-page .section-definition .detail {
  width: 710px;
  margin-left: 100px;
  font-size: 22px;
  line-height: 1.6;
}
.text-large .complaint-page .section-definition .detail {
  font-size: 24px;
}
.complaint-page .section-definition .detail strong {
  font-size: 1.85em;
  line-height: 0.8;
}
.complaint-page .section-definition .detail p + p {
  margin-top: 15px;
}
.complaint-page .section-definition .detail .remark {
  border-top: 1px solid #ccc;
  padding-top: 10px;
  font-size: 19px;
  color: #ccc;
}
.text-large .complaint-page .section-definition .detail .remark {
  font-size: 21px;
}
.complaint-page .section-definition .detail a {
  color: #ed7725;
}
.complaint-page .section-definition .detail li::before {
  background-color: #ed7725;
  top: 14px;
}
.complaint-page .section-form-complaint {
  background: url("../img/complaint/bg-form.jpg") 50% 50%/cover no-repeat;
  position: relative;
  padding: 76px 0 114px;
}
.complaint-page .section-form-complaint img[class^=obj-] {
  position: absolute;
  left: 50%;
  top: 0;
}
.complaint-page .section-form-complaint img.obj-01 {
  margin-left: -820px;
}
.complaint-page .section-form-complaint img.obj-02 {
  top: 150px;
  margin-left: 550px;
}
.complaint-page .section-form-complaint img.obj-03 {
  top: auto;
  bottom: 40px;
  margin-left: -780px;
  z-index: 13;
}
.complaint-page .section-form-complaint img.obj-04 {
  top: auto;
  bottom: 0;
  margin-left: 170px;
}
.complaint-page .section-form-complaint .form-complaint {
  width: 1080px;
  max-width: calc(100% - 30px);
  position: relative;
  padding: 58px 0 0;
  margin: 0 auto;
}
.complaint-page .section-form-complaint .form-complaint::before {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  background-color: #fff;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.38);
  position: absolute;
  left: -15px;
  top: 20px;
  transform: rotate(-8deg);
}
.complaint-page .section-form-complaint .form-complaint::after {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  background-color: #fff;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.38);
  position: absolute;
  left: 0;
  top: 0;
}
.complaint-page .section-form-complaint .form-complaint form {
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 13;
}
.complaint-page .section-form-complaint .form-complaint form label,
.complaint-page .section-form-complaint .form-complaint form p {
  font-size: 24px;
  font-family: "db_ozone_x";
}
.text-large .complaint-page .section-form-complaint .form-complaint form label,
.text-large .complaint-page .section-form-complaint .form-complaint form p {
  font-size: 26px;
}
.complaint-page .section-form-complaint .form-complaint form p {
  width: 100%;
}
.complaint-page .section-form-complaint .form-complaint form .inputtext-style,
.complaint-page .section-form-complaint .form-complaint form .textarea-style {
  font-size: 24px;
}
.text-large .complaint-page .section-form-complaint .form-complaint form .inputtext-style,
.text-large .complaint-page .section-form-complaint .form-complaint form .textarea-style {
  font-size: 26px;
}
.complaint-page .section-form-complaint .form-complaint form .inputtext-style:focus + label,
.complaint-page .section-form-complaint .form-complaint form .inputtext-style.not-empty + label,
.complaint-page .section-form-complaint .form-complaint form .textarea-style:focus + label,
.complaint-page .section-form-complaint .form-complaint form .textarea-style.not-empty + label,
.complaint-page .section-form-complaint .form-complaint form .select-style:focus + label,
.complaint-page .section-form-complaint .form-complaint form .select-style.not-empty + label {
  color: #d4d6d6;
}
.complaint-page .section-form-complaint .form-complaint form .textarea-style {
  height: 135px;
}
.complaint-page .section-form-complaint .form-complaint form .checkbox-style label {
  font-size: 28px;
}
.text-large .complaint-page .section-form-complaint .form-complaint form .checkbox-style label {
  font-size: 30px;
}
.complaint-page .section-form-complaint .form-complaint form .checkbox-style label::before {
  width: 22px;
  height: 22px;
  top: 5px;
}
.complaint-page .section-form-complaint .form-complaint form .checkbox-style label::after {
  left: 1px;
  top: 2px;
  font-size: 22px;
}
.text-large .complaint-page .section-form-complaint .form-complaint form .checkbox-style label::after {
  font-size: 24px;
}
.complaint-page .section-form-complaint .form-complaint form .radio-style label {
  color: #d4d6d6;
}
.complaint-page .section-form-complaint .form-complaint form .radio-style label::before {
  width: 22px;
  height: 22px;
  border-color: #ccc;
  top: 3px;
}
.complaint-page .section-form-complaint .form-complaint form .radio-style label::after {
  left: 2px;
  top: 3px;
  color: #ed7725;
}
.complaint-page .section-form-complaint .form-complaint form .radio-style input[type=radio]:checked + label {
  color: #ed7725;
}
.complaint-page .section-form-complaint .form-complaint form .box-subject {
  justify-content: flex-start;
}
.complaint-page .section-form-complaint .form-complaint form .box-subject .col:nth-of-type(1) {
  width: 100px !important;
}
.complaint-page .section-form-complaint .form-complaint form .items:nth-of-type(1) {
  margin: 0 0 0 84px;
}
.complaint-page .section-form-complaint .form-complaint form .items:nth-of-type(1) h2 {
  font-size: 48px;
  line-height: 0.8;
  font-family: "db_ozone_x-medium";
}
.text-large .complaint-page .section-form-complaint .form-complaint form .items:nth-of-type(1) h2 {
  font-size: 50px;
}
.complaint-page .section-form-complaint .form-complaint form .items:nth-of-type(1) h2::before {
  width: 100px;
  height: 3px;
  background-color: #ed7725;
}
.complaint-page .section-form-complaint .form-complaint form .items:nth-of-type(2) {
  width: 600px;
  border-left: 2px solid #efefef;
}
.complaint-page .section-form-complaint .form-complaint form .items:nth-of-type(2) .item {
  padding: 0 56px 0 32px;
}
.complaint-page .section-form-complaint .form-complaint form .items:nth-of-type(2) .item:last-child {
  padding: 0;
  margin-top: 100px;
}
.complaint-page .section-form-complaint .form-complaint form .items:nth-of-type(2) .btn-style {
  width: 100%;
  height: 68px;
  background-color: #000;
}
.complaint-page .section-form-complaint .remark {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 40px;
  font-size: 22px;
  text-align: center;
}
.text-large .complaint-page .section-form-complaint .remark {
  font-size: 24px;
}
.complaint-page .img-banner {
  margin-top: 0;
}
.complaint-page .section-download .page-center {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
}
.complaint-page .section-download .remark {
  font-size: 22px;
}
.text-large .complaint-page .section-download .remark {
  font-size: 24px;
}
.complaint-page .section-download .remark li {
  font-size: 1em;
}
.complaint-page .section-download .remark li + li {
  margin-top: 15px;
}

@media screen and (max-width: 1024px) {
  .complaint-page .section-definition .detail {
    width: 620px;
  }
  .complaint-page .section-form-complaint .form-complaint form .items:first-of-type {
    margin-left: 30px;
  }
  .complaint-page .section-form-complaint .form-complaint form .items:nth-of-type(2) .item {
    padding: 0 30px 0 30px;
  }
}
@media screen and (max-width: 1023px) {
  .complaint-page .section-definition .detail {
    width: 100%;
    margin: 20px 0 0;
  }
  .complaint-page .section-form-complaint .form-complaint form .items {
    width: 40%;
  }
  .complaint-page .section-form-complaint .form-complaint form .items:first-child h2 {
    font-size: 40px;
  }
  .text-large .complaint-page .section-form-complaint .form-complaint form .items:first-child h2 {
    font-size: 42px;
  }
  .complaint-page .section-form-complaint .form-complaint form .items:nth-of-type(2) {
    width: 60%;
    margin-left: 30px;
  }
  .complaint-page .banner-page .box-text .btn-style {
    background-color: #000 !important;
  }
}
@media screen and (max-width: 767px) {
  .complaint-page .banner-page a {
    background-color: #000 !important;
  }
  .complaint-page .section-form-complaint .form-complaint form {
    flex-wrap: wrap;
  }
  .complaint-page .section-form-complaint .form-complaint form .items {
    width: 100%;
  }
  .complaint-page .section-form-complaint .form-complaint form .items .item {
    padding: 0 30px 0 0;
  }
  .complaint-page .section-form-complaint .form-complaint form .items .checkbot {
    position: absolute;
    left: 0;
    bottom: 130px;
    padding-left: 30px;
  }
  .complaint-page .section-form-complaint .form-complaint form .items:first-child {
    margin: 0;
  }
  .complaint-page .section-form-complaint .form-complaint form .items:first-child h2 {
    margin: 0 30px;
  }
  .complaint-page .section-form-complaint .form-complaint form .items:nth-of-type(2) {
    width: 100%;
    border: none;
    margin: 0 0 0;
  }
  .complaint-page .section-form-complaint .form-complaint form .items:nth-of-type(2) .item:last-child {
    margin-top: 150px;
  }
}
@media screen and (max-width: 479px) {
  .complaint-page .section-form-complaint .form-complaint form .items:first-child h2 {
    font-size: 32px;
  }
  .text-large .complaint-page .section-form-complaint .form-complaint form .items:first-child h2 {
    font-size: 34px;
  }
  .complaint-page .section-form-complaint .form-complaint form .box-subject .col {
    width: 100%;
  }
}
.excutive-director-page #footer {
  margin-top: -108px;
}
.excutive-director-page .content-excutive-director {
  display: flex;
  background-color: #2f3844;
}
.excutive-director-page .content-excutive-director .box-img {
  min-width: 50%;
  width: 50%;
  align-self: baseline;
}
.excutive-director-page .content-excutive-director .box-img img {
  width: 100%;
}
.excutive-director-page .content-excutive-director .box-img .text {
  background-color: #ed7725;
  padding: 56px 68px;
  font-size: 30px;
  color: #fff;
}
.text-large .excutive-director-page .content-excutive-director .box-img .text {
  font-size: 32px;
}
.excutive-director-page .content-excutive-director .box-img .text h2 {
  position: relative;
  font-size: 1.3em;
  font-family: "db_ozone_x-bold";
}
.excutive-director-page .content-excutive-director .box-img .text h2::before {
  content: "";
  width: 13px;
  height: 13px;
  display: none;
  border: 1px solid #fff;
  border-radius: 50%;
  position: absolute;
  left: -15px;
  top: 0;
}
.excutive-director-page .content-excutive-director .box-detail {
  width: 100%;
  padding: 80px 90px 0;
  color: #b8b2ae;
  font-size: 20px;
  line-height: 1.4;
  overflow: hidden;
}
.text-large .excutive-director-page .content-excutive-director .box-detail {
  font-size: 22px;
}
.excutive-director-page .content-excutive-director .box-detail .scroll {
  height: calc(100% - 68px);
  max-width: 500px;
  overflow: hidden;
  overflow-y: auto;
}
.excutive-director-page .content-excutive-director .box-detail section {
  padding-right: 40px;
}
.excutive-director-page .content-excutive-director .box-detail section + section {
  margin-top: 26px;
}
.excutive-director-page .content-excutive-director .box-detail h3 {
  padding-bottom: 15px;
  margin-bottom: 30px;
  font-size: 1.6em;
  color: #ed7725;
}
.excutive-director-page .content-excutive-director .box-detail h3::before {
  width: 42px;
  height: 3px;
}
.excutive-director-page .box-btn-download {
  background-color: #2f3844;
}
.excutive-director-page .box-btn-download .page-center {
  display: flex;
  padding: 40px 0;
}

@media screen and (max-width: 1024px) {
  .excutive-director-page .content-excutive-director .box-detail {
    padding: 40px 40px 0;
  }
}
@media screen and (max-width: 1023px) {
  .excutive-director-page .content-excutive-director {
    flex-wrap: wrap;
  }
  .excutive-director-page .content-excutive-director .box-img,
  .excutive-director-page .content-excutive-director .box-detail {
    width: 100%;
  }
  .excutive-director-page .content-excutive-director .box-detail .scroll {
    max-width: 100%;
  }
  .excutive-director-page .box-btn-download .page-center {
    padding-top: 0;
  }
}
@media screen and (max-width: 767px) {
  .excutive-director-page .content-excutive-director .box-img .text {
    padding: 25px;
  }
}
@media screen and (max-width: 479px) {
  .excutive-director-page .content-excutive-director .box-img .text {
    font-size: 24px;
  }
  .text-large .excutive-director-page .content-excutive-director .box-img .text {
    font-size: 26px;
  }
  .excutive-director-page .content-excutive-director .box-detail {
    padding: 40px 20px 0;
  }
  .excutive-director-page .box-btn-download .btn-style {
    width: 100%;
  }
}
.special-advisory-board-page #footer {
  margin-top: -108px;
}
.special-advisory-board-page .banner-page .btn-learn-more {
  margin-top: 60px;
}
.special-advisory-board-page .banner-page .group-btn {
  display: flex;
  align-items: center;
  margin-top: 60px;
  font-size: 48px;
}
.special-advisory-board-page .banner-page .group-btn a {
  border-bottom: 1px solid #fff;
  padding-bottom: 10px;
  margin: 0;
  font-size: 1em;
  line-height: 1;
  font-family: "db_ozone_x";
  color: #ed7725;
}
.special-advisory-board-page .banner-page .group-btn a small {
  display: block;
  margin: 7px 0 0 10px;
  font-size: 0.62em;
  line-height: 1;
  color: #fff;
}
.special-advisory-board-page .banner-page .group-btn a + a {
  margin-left: 50px;
}
.special-advisory-board-page .content-special-advisory-board {
  width: 100%;
  overflow: hidden;
}
.special-advisory-board-page .slide-special-advisory-board .owl-stage-outer {
  overflow: visible;
}
.special-advisory-board-page .slide-special-advisory-board .item {
  display: flex;
  background-color: #2f3844;
}
.special-advisory-board-page .slide-special-advisory-board .box-img {
  min-width: 50%;
  width: 50%;
}
.special-advisory-board-page .slide-special-advisory-board .box-img img {
  width: 100%;
}
.special-advisory-board-page .slide-special-advisory-board .box-img .text {
  height: 300px;
  background-color: #4c4b49;
  padding: 56px 68px;
  font-size: 22px;
  color: #fff;
  transition: 0.25s ease;
}
.special-advisory-board-page .slide-special-advisory-board .box-img .text h2 {
  position: relative;
  margin-bottom: 20px;
  font-size: 3.5em;
  font-family: "db_ozone_x-bold";
  line-height: 0.8;
}
.special-advisory-board-page .slide-special-advisory-board .box-img .text h2 strong {
  display: block;
  margin-bottom: -10px;
  font-size: 1.15em;
}
.special-advisory-board-page .slide-special-advisory-board .box-img .text p {
  line-height: 1.3;
}
.special-advisory-board-page .slide-special-advisory-board .box-img .text p strong {
  font-family: "db_ozone_x-bold";
}
.special-advisory-board-page .slide-special-advisory-board .owl-item.active .text {
  background-color: #ed7725;
}
.special-advisory-board-page .slide-special-advisory-board .box-detail {
  width: 100%;
  height: 684px;
  position: relative;
  padding: 80px 45px 0;
  color: #b8b2ae;
  font-size: 20px;
  line-height: 1.4;
  overflow: hidden;
}
.special-advisory-board-page .slide-special-advisory-board .box-detail .scroll {
  width: 100%;
  height: calc(100% - 112px);
  overflow: hidden;
  overflow-y: auto;
}
.special-advisory-board-page .slide-special-advisory-board .box-detail section {
  padding-right: 40px;
}
.special-advisory-board-page .slide-special-advisory-board .box-detail section + section {
  margin-top: 26px;
}
.special-advisory-board-page .slide-special-advisory-board .box-detail h3 {
  padding-bottom: 15px;
  margin-bottom: 30px;
  font-size: 1.6em;
  color: #ed7725;
}
.special-advisory-board-page .slide-special-advisory-board .box-detail h3::before {
  width: 42px;
  height: 3px;
}
.special-advisory-board-page .slide-special-advisory-board .box-detail p + p {
  margin-top: 40px;
}
.special-advisory-board-page .slide-special-advisory-board .box-detail .list-bullet li {
  padding-left: 20px;
}
.special-advisory-board-page .slide-special-advisory-board .box-detail .list-bullet li::before {
  background-color: #ed7725;
  top: 10px;
}
.special-advisory-board-page .slide-special-advisory-board .box-detail .btn-style {
  width: 100%;
  height: 68px;
  background-color: #2a2b34;
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 26px;
  font-family: "db_ozone_x-bold";
}
.special-advisory-board-page .slide-special-advisory-board .box-detail .btn-style::before {
  width: 29px;
  height: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #fff;
  border-radius: 50%;
  margin-left: 20px;
  order: 2;
  font-size: 16px;
  text-indent: 2px;
}
.special-advisory-board-page .slide-special-advisory-board .owl-nav,
.special-advisory-board-page .slide-special-advisory-board .owl-dots {
  display: none;
}

@media screen and (max-width: 1024px) {
  .special-advisory-board-page .slide-special-advisory-board .box-img .text {
    height: 340px;
  }
  .special-advisory-board-page .slide-special-advisory-board .box-detail {
    height: 664px;
  }
}
@media screen and (max-width: 1023px) {
  .special-advisory-board-page .slide-special-advisory-board .item {
    flex-wrap: wrap;
  }
  .special-advisory-board-page .slide-special-advisory-board .box-img {
    width: 100%;
  }
  .special-advisory-board-page .slide-special-advisory-board .box-img .text {
    height: 310px;
  }
  .special-advisory-board-page .slide-special-advisory-board .box-detail {
    width: 100%;
    height: 800px;
  }
}
@media screen and (max-width: 767px) {
  .special-advisory-board-page .banner-page .group-btn a {
    color: #000;
  }
  .special-advisory-board-page .slide-special-advisory-board .box-img .text {
    height: 240px;
    padding: 30px 20px;
  }
  .special-advisory-board-page .slide-special-advisory-board .box-img .text h2 {
    font-size: 3em;
  }
}
@media screen and (max-width: 479px) {
  .special-advisory-board-page .banner-page .group-btn {
    flex-wrap: wrap;
    margin-top: 30px;
    font-size: 34px;
  }
  .special-advisory-board-page .banner-page .group-btn a {
    width: 100%;
  }
  .special-advisory-board-page .banner-page .group-btn a + a {
    margin: 10px 0 0;
  }
  .special-advisory-board-page .slide-special-advisory-board {
    max-width: 100%;
  }
  .special-advisory-board-page .slide-special-advisory-board .box-img .text {
    height: 240px;
    padding: 30px 20px;
  }
  .special-advisory-board-page .slide-special-advisory-board .box-img .text h2 {
    font-size: 2.4em;
  }
  .special-advisory-board-page .slide-special-advisory-board .box-detail {
    padding: 40px 20px 0;
  }
  .special-advisory-board-page .slide-special-advisory-board .box-detail .btn-style {
    font-size: 22px;
  }
}
.about-us-page .old-theme #footer {
  margin-top: 0;
}
.about-us-page .old-theme .content-overview .section-navigation {
  height: 800px;
  background: url("../img/about-us/bg.jpg") 50% 50%/cover no-repeat;
  padding: 62px 0;
}
.about-us-page .old-theme .content-overview .section-navigation .title {
  width: 570px;
  max-width: 100%;
  position: relative;
  padding-bottom: 40px;
  margin: 0 auto;
  font-size: 26px;
  color: #9e9a9a;
  text-align: center;
}
.text-large .about-us-page .old-theme .content-overview .section-navigation .title {
  font-size: 28px;
}
.about-us-page .old-theme .content-overview .section-navigation .title::before, .about-us-page .old-theme .content-overview .section-navigation .title::after {
  content: "";
  width: 44px;
  height: 9px;
  display: block;
  background-color: #282828;
  position: absolute;
  left: 50%;
  bottom: 0;
  margin-left: -43px;
}
.about-us-page .old-theme .content-overview .section-navigation .title::after {
  background-color: #fff;
  margin-left: 1px;
}
.about-us-page .old-theme .content-overview .section-navigation .group-nav {
  width: 940px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 50px auto 0;
}
.about-us-page .old-theme .content-overview .section-navigation .group-nav a {
  width: 235px;
  height: 240px;
  display: block;
  position: relative;
  text-align: center;
}
.about-us-page .old-theme .content-overview .section-navigation .group-nav a::before {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 0;
  top: 0;
}
.about-us-page .old-theme .content-overview .section-navigation .group-nav a span {
  width: 100%;
  display: block;
  position: absolute;
  left: 0;
  bottom: 26px;
  font-size: 30px;
  line-height: 0.8;
  font-family: "db_ozone_x-bold";
  color: #fff;
}
.text-large .about-us-page .old-theme .content-overview .section-navigation .group-nav a span {
  font-size: 32px;
}
.about-us-page .old-theme .content-overview .list-number {
  counter-reset: number;
}
.about-us-page .old-theme .content-overview .list-number li + li {
  margin-top: 16px;
}
.about-us-page .old-theme .content-overview .list-number > li {
  padding: 65px 0 0;
}
.about-us-page .old-theme .content-overview .list-number > li::before {
  content: counter(number, decimal-leading-zero);
  counter-increment: number;
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ed7725;
  border-radius: 50%;
  font-size: 37px;
  font-family: "db_ozone_x-bold";
  color: #fff;
}
.text-large .about-us-page .old-theme .content-overview .list-number > li::before {
  font-size: 39px;
}
.about-us-page .old-theme .content-overview .nav-page {
  width: 100%;
  height: 65px;
  background-color: #2b2b2b;
  position: fixed;
  left: 0;
  bottom: -65px;
  color: #fff;
  transition: 0.25s ease;
  z-index: 1313;
}
.about-us-page .old-theme .content-overview .nav-page.show-nav {
  bottom: 0;
}
.about-us-page .old-theme .content-overview .nav-page .page-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.about-us-page .old-theme .content-overview .nav-page a {
  display: block;
  position: relative;
  padding-left: 20px;
  font-size: 18px;
}
.text-large .about-us-page .old-theme .content-overview .nav-page a {
  font-size: 20px;
}
.about-us-page .old-theme .content-overview .nav-page a::before {
  content: "";
  width: 5px;
  height: 5px;
  display: block;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 10px;
}
.about-us-page .old-theme .content-overview .nav-page a + a {
  margin-left: 22px;
}
.about-us-page .old-theme .content-overview .nav-page a.active {
  color: #ed7725;
}
.about-us-page .old-theme .content-overview .nav-page a.active::before {
  background-color: #ed7725;
}
html .about-us-page .old-theme .jspVerticalBar::before, html .about-us-page .old-theme .jspVerticalBar::after {
  background-color: #fff !important;
}

@media screen and (max-width: 1023px) {
  .about-us-page .old-theme .content-overview .section-navigation {
    height: auto;
  }
  .about-us-page .old-theme .content-overview .section-navigation .group-nav {
    width: 100%;
  }
  .about-us-page .old-theme .content-overview .section-navigation .group-nav a {
    width: 25%;
    height: auto;
  }
  .about-us-page .old-theme .content-overview .nav-page {
    display: none !important;
  }
}
@media screen and (max-width: 767px) {
  .about-us-page .old-theme .content-overview .section-navigation .group-nav a {
    width: 33.33%;
  }
}
@media screen and (max-width: 479px) {
  .about-us-page .old-theme .content-overview .section-navigation .group-nav a {
    width: 50%;
  }
  .about-us-page .old-theme .content-overview .section-navigation .group-nav a span {
    left: 10px;
    bottom: 10px;
    font-size: 22px;
  }
  .text-large .about-us-page .old-theme .content-overview .section-navigation .group-nav a span {
    font-size: 22px;
  }
}
.-overview #main-content {
  padding-bottom: 0;
}

#wrapper.-detail #container .page-center {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 82px 0 40px;
}
#wrapper.-detail .secondary {
  width: 318px;
  min-width: 318px;
  font-size: 1.8rem;
}
#wrapper.-detail .secondary > div + div {
  margin-top: 52px;
}
#wrapper.-detail .secondary h3 {
  padding-bottom: 22px;
  margin-bottom: 22px;
  font-size: 2em;
  font-family: "db_ozone_x-bold";
}
#wrapper.-detail .secondary .box-share .group-btn {
  display: flex;
  flex-wrap: wrap;
}
#wrapper.-detail .secondary .box-share a {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #909090;
  border-radius: 50%;
  color: #888;
}
.desktop #wrapper.-detail .secondary .box-share a:hover {
  background-color: #ed7725;
  border-color: #ed7725;
  color: #fff;
}
#wrapper.-detail .secondary .box-share a + a {
  margin-left: 22px;
}
#wrapper.-detail .secondary .box-most-viewed .items {
  counter-reset: number;
  margin-top: 30px;
}
#wrapper.-detail .secondary .box-most-viewed .items .item {
  border-bottom: 1px solid #dce1e5;
  position: relative;
  padding: 4px 0 15px 46px;
  margin-bottom: 25px;
}
#wrapper.-detail .secondary .box-most-viewed .items .item::before {
  counter-increment: number;
  content: counters(number, ".");
  position: absolute;
  left: 12px;
  top: 0;
  font-size: 2.7em;
  color: #888;
}
#wrapper.-detail .secondary .box-most-viewed .items .item h4 {
  margin-bottom: 6px;
  font-size: 1.35em;
  font-family: "db_ozone_x-bold";
}
#wrapper.-detail .secondary .box-most-viewed .items .item p.icon-clock {
  min-height: 18px;
  position: relative;
  padding-left: 25px;
}
#wrapper.-detail .secondary .box-most-viewed .items .item p.icon-clock::before {
  position: absolute;
  left: 0;
  top: 1px;
  font-size: 15px;
  color: #002a64;
}
#wrapper.-detail .secondary .box-most-viewed .items .item:first-child::before {
  color: #000;
}
#wrapper.-detail .primary {
  width: calc(100% - 366px);
}
#wrapper.-detail .primary .editor {
  font-size: 2.2rem;
  line-height: 1.6;
  color: #2c2c2d;
}
#wrapper.-detail .primary .editor h3 {
  margin-bottom: 15px;
  font-size: 1.36em;
  font-family: "db_ozone_x-bold";
}
#wrapper.-detail .primary .editor p {
  margin: 0;
  font-size: 1em;
}
#wrapper.-detail .primary .editor strong {
  font-family: "db_ozone_x-medium";
}
#wrapper.-detail .primary .editor ul {
  margin: 0;
}
#wrapper.-detail .primary .editor ul li {
  padding-left: 20px;
}
#wrapper.-detail .primary .editor ul li::before {
  background-color: #ed7725;
  top: 13px;
}
#wrapper.-detail .primary .editor ul li + li {
  margin-top: 0;
}
#wrapper.-detail .primary .editor ol {
  margin: 0;
}
#wrapper.-detail .gallery {
  width: 100%;
  margin-top: 60px;
}
#wrapper.-detail .gallery h3 {
  padding-bottom: 20px;
  margin-bottom: 36px;
  font-size: 3.6rem;
  font-family: "db_ozone_x-bold";
}
#wrapper.-detail .gallery .items {
  display: flex;
  flex-wrap: wrap;
  margin: -15px 0 0 -15px;
}
#wrapper.-detail .gallery .item {
  width: calc(25% - 15px);
  position: relative;
  margin: 15px 0 0 15px;
}
#wrapper.-detail .gallery .item a {
  border: 3px solid #ccc;
}
.desktop #wrapper.-detail .gallery .item a:hover {
  border-color: #ed7725;
  transition: 0.25s ease;
}
#wrapper.-detail .gallery .item a .number {
  width: 100%;
  height: 100%;
  display: none;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  text-align: center;
  font-size: 3.7rem;
  font-family: "db_ozone_x-medium";
  color: #fff;
  z-index: 13;
}
#wrapper.-detail .gallery .item a .number strong {
  display: block;
  margin-bottom: -15px;
  font-size: 2.2em;
}
#wrapper.-detail .gallery .item + .item + .item + .item + .item {
  display: none;
}
#wrapper.-detail .gallery .item:nth-of-type(4) a {
  position: relative;
}
#wrapper.-detail .gallery .item:nth-of-type(4) a::before {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  background-color: rgba(54, 54, 54, 0.3);
  position: absolute;
  left: 0;
  top: 0;
  opacity: 1;
  z-index: 13;
}
#wrapper.-detail .gallery .item:nth-of-type(4) a .number {
  display: flex;
}
#wrapper.-detail .related {
  width: 100%;
  border-top: 1px solid #dce1e5;
  padding-top: 60px;
  margin-top: 60px;
}
#wrapper.-detail .related h2 {
  margin-bottom: 34px;
  font-size: 6rem;
  font-family: "db_ozone_x-bold";
}
#wrapper.-detail .related .list-content {
  position: relative;
}
#wrapper.-detail .related .list-content::before {
  content: "";
  width: 74px;
  height: 37px;
  display: block;
  background-color: #ed7725;
  position: absolute;
  left: -108px;
  top: 0;
}

@media screen and (max-width: 1023px) {
  #wrapper.-detail .primary {
    width: 100%;
  }
  #wrapper.-detail .secondary {
    width: 100%;
    display: flex;
    order: 1;
    margin-top: 60px;
  }
  #wrapper.-detail .secondary .box-most-viewed {
    margin-top: 0;
    margin-left: 40px;
  }
  #wrapper.-detail .gallery .item a .number {
    font-size: 2.4rem;
  }
  #wrapper.-detail .related {
    order: 1;
  }
}
@media screen and (max-width: 767px) {
  #wrapper.-detail #container .page-center {
    padding-top: 40px;
  }
  #wrapper.-detail .banner-page .box-text a {
    margin-top: 0;
  }
  #wrapper.-detail .banner-page .btn-learn-more {
    margin-top: 20px !important;
  }
  #wrapper.-detail .secondary h3 {
    font-size: 1.8em;
  }
  #wrapper.-detail .secondary .box-share {
    width: 300px;
  }
  #wrapper.-detail .gallery .item a .number {
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 479px) {
  #wrapper.-detail #container .page-center {
    padding-top: 40px;
  }
  #wrapper.-detail .secondary {
    min-width: inherit;
    flex-wrap: wrap;
  }
  #wrapper.-detail .secondary h3 {
    font-size: 1.8em;
  }
  #wrapper.-detail .secondary .box-share {
    width: 100%;
  }
  #wrapper.-detail .secondary .box-most-viewed {
    width: 100%;
    margin: 40px 0 0;
  }
  #wrapper.-detail .gallery .item {
    width: calc(50% - 15px);
  }
  #wrapper.-detail .gallery .item a .number {
    font-size: 1.8rem;
  }
}
.areas-of-work-page .content-areas-of-work .page-center {
  display: flex;
  flex-wrap: wrap;
}
.areas-of-work-page .content-areas-of-work .list-areas-of-work {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 85px 0 0 -15px;
}
.areas-of-work-page .content-areas-of-work .list-areas-of-work .item {
  width: calc(33.33% - 15px);
  margin: 15px 0 0 15px;
  box-shadow: 0 6px 5px rgba(0, 0, 0, 0.17);
}
.areas-of-work-page .content-areas-of-work .list-areas-of-work .item img {
  width: 100%;
}
.areas-of-work-page .content-areas-of-work .list-areas-of-work .item a {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
}
.areas-of-work-page .content-areas-of-work .list-areas-of-work .item a::before {
  content: "";
  width: 100%;
  height: 185px;
  display: block;
  background: url("../img/areas-of-work/overlay.png") repeat-x;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 13;
}
.areas-of-work-page .content-areas-of-work .list-areas-of-work .item a .text {
  width: calc(100% - 40px);
  height: 50px;
  display: flex;
  align-items: center;
  position: absolute;
  left: 20px;
  bottom: 40px;
  padding-right: 75px;
  font-size: 2.5rem;
  font-family: "db_ozone_x-bold";
  color: #fff;
  z-index: 13;
}
.areas-of-work-page .content-areas-of-work .list-areas-of-work .item a .text::after {
  content: "h";
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ed7725;
  border-radius: 50%;
  position: absolute;
  right: 15px;
  top: 50%;
  margin-top: -19px;
}
.areas-of-work-page .content-areas-of-work .slide-related-areas-of-work {
  margin: 0;
}
.areas-of-work-page .content-areas-of-work .slide-related-areas-of-work .item {
  padding-bottom: 10px;
}
.areas-of-work-page .content-areas-of-work .slide-related-areas-of-work .item a {
  box-shadow: 0 6px 5px rgba(0, 0, 0, 0.17);
}
.areas-of-work-page .content-areas-of-work .slide-related-areas-of-work .item a::before {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  background-color: rgba(54, 54, 54, 0.3);
  position: absolute;
  left: 0;
  bottom: 0;
  transition: 0.25s ease;
  opacity: 1;
  z-index: 13;
}
.desktop .areas-of-work-page .content-areas-of-work .slide-related-areas-of-work .item a:hover::before {
  opacity: 0;
}
.areas-of-work-page .content-areas-of-work .slide-related-areas-of-work .text {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 24px;
  font-family: "db_ozone_x-bold";
  color: #fff;
  z-index: 13;
  pointer-events: none;
}
.areas-of-work-page .content-areas-of-work .slide-related-areas-of-work .text h3 {
  margin: 0;
  font-size: 2.8rem;
}
.areas-of-work-page .content-areas-of-work .slide-related-areas-of-work .owl-dots {
  position: relative;
  bottom: 0;
  margin-top: 30px;
}
.areas-of-work-page .content-areas-of-work .slide-related-areas-of-work .owl-dots .owl-dot {
  background-color: #c7c5c6;
}
.areas-of-work-page .content-areas-of-work .slide-related-areas-of-work .owl-dots .owl-dot.active {
  background-color: #ed7725;
}

@media screen and (max-width: 1024px) {
  .areas-of-work-page .content-areas-of-work .list-areas-of-work .item a .text {
    height: auto;
    min-height: 40px;
  }
  .areas-of-work-page .content-areas-of-work .list-areas-of-work .item a .text br {
    display: none;
  }
}
@media screen and (max-width: 1023px) {
  .areas-of-work-page .content-areas-of-work .list-areas-of-work .item a .text {
    width: 100%;
    left: 0;
    bottom: 20px;
    padding-left: 20px;
    font-size: 2rem;
  }
}
@media screen and (max-width: 767px) {
  .areas-of-work-page .content-areas-of-work .list-areas-of-work {
    width: auto;
    margin-top: 40px;
  }
  .areas-of-work-page .content-areas-of-work .list-areas-of-work .item {
    width: calc(50% - 15px);
  }
}
@media screen and (max-width: 479px) {
  .areas-of-work-page .content-areas-of-work .list-areas-of-work {
    margin-left: 0;
  }
  .areas-of-work-page .content-areas-of-work .list-areas-of-work .item {
    width: 100%;
    margin-left: 0;
  }
}
.corporate-materials-page .content-corporate-materials .content-booklet {
  margin-bottom: -95px;
  background: #2f3844 url("../img/corporate-materials/bg-booklet.jpg") 50% 0 no-repeat;
  padding: 58px 0 75px;
  position: relative;
}
.corporate-materials-page .content-corporate-materials .content-booklet::before {
  content: "";
  width: 1109px;
  height: 82px;
  display: block;
  background: url("../img/corporate-materials/booklet-obj-01.png") 0 0/100% no-repeat;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-65%);
}
.corporate-materials-page .content-corporate-materials .content-booklet .page-center {
  display: flex;
  justify-content: space-between;
}
.corporate-materials-page .content-corporate-materials .content-booklet .detail {
  padding-top: 74px;
  font-size: 2.4rem;
  color: #fff;
}
.corporate-materials-page .content-corporate-materials .content-booklet .detail h2 {
  margin-bottom: 40px;
  font-size: 2em;
  line-height: 0.8;
  font-family: "db_ozone_x-bold";
  color: #ed7725;
}
.corporate-materials-page .content-corporate-materials .content-booklet .detail p {
  line-height: 1.3;
}
.corporate-materials-page .content-corporate-materials .content-booklet .link {
  width: 605px;
  min-width: 605px;
  position: relative;
  margin: 0 56px 0 100px;
}
.corporate-materials-page .content-corporate-materials .content-booklet .link::before {
  content: "";
  width: 100%;
  height: calc(100% - 134px);
  display: block;
  background-color: #ed7725;
  position: absolute;
  right: -56px;
  bottom: 0;
}
.corporate-materials-page .content-corporate-materials .content-booklet .link .img {
  display: block;
  position: relative;
}
.corporate-materials-page .content-corporate-materials .content-booklet .link .img img {
  position: relative;
}
.corporate-materials-page .content-corporate-materials .content-booklet .link .text {
  min-height: 64px;
  display: flex;
  align-items: center;
  position: relative;
  padding: 5px 0 0 155px;
  font-size: 2rem;
  font-family: "db_ozone_x-medium";
  color: #fff;
  z-index: 13;
}
.corporate-materials-page .content-corporate-materials .content-booklet .link .text::before {
  content: "";
  width: 31px;
  min-width: 31px;
  height: 35px;
  display: block;
  background: url("../img/icons/file-pdf-white.png") no-repeat;
  position: relative;
  top: -2px;
  margin-right: 16px;
}
.corporate-materials-page .content-corporate-materials .content-newsletter .page-center {
  display: flex;
  flex-wrap: wrap;
}
.corporate-materials-page .content-corporate-materials .content-newsletter .list-newsletter {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 70px 0 0 -15px;
}
.corporate-materials-page .content-corporate-materials .content-newsletter .list-newsletter .item {
  width: calc(33.33% - 15px);
  margin: 15px 0 0 15px;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.17);
}
.corporate-materials-page .content-corporate-materials .content-newsletter .list-newsletter .item a {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
}
.corporate-materials-page .content-corporate-materials .content-newsletter .list-newsletter .item a::before {
  content: "";
  width: 100%;
  height: 185px;
  display: block;
  background: url("../img/areas-of-work/overlay.png") repeat-x;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 13;
}
.corporate-materials-page .content-corporate-materials .content-newsletter .list-newsletter .item a .text {
  width: calc(100% - 40px);
  height: 50px;
  display: flex;
  align-items: center;
  position: absolute;
  left: 20px;
  bottom: 40px;
  padding-right: 75px;
  font-size: 2.5rem;
  font-family: "db_ozone_x-bold";
  color: #fff;
  z-index: 13;
}
.corporate-materials-page .content-corporate-materials .content-newsletter .list-newsletter .item a .text::after {
  content: "h";
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ed7725;
  border-radius: 50%;
  position: absolute;
  right: 15px;
  top: 50%;
  margin-top: -19px;
}

.content-corporate-materials .owl-stage {
  min-width: 100% !important;
}

@media screen and (max-width: 1024px) {
  .corporate-materials-page .content-corporate-materials .content-booklet .link {
    width: 500px;
    min-width: 500px;
    margin-right: 0;
    margin-left: 50px;
  }
  .corporate-materials-page .content-corporate-materials .content-booklet .link::before {
    right: 0;
  }
  .corporate-materials-page .content-corporate-materials .content-booklet .link .text {
    padding-left: 20px;
  }
  .corporate-materials-page .content-corporate-materials .content-newsletter .list-newsletter .item a .text {
    height: auto;
    min-height: 40px;
  }
  .corporate-materials-page .content-corporate-materials .content-newsletter .list-newsletter .item a .text br {
    display: none;
  }
}
@media screen and (max-width: 1023px) {
  .corporate-materials-page .content-corporate-materials .content-booklet .page-center {
    flex-wrap: wrap;
  }
  .corporate-materials-page .content-corporate-materials .content-booklet .detail {
    padding-top: 0;
  }
  .corporate-materials-page .content-corporate-materials .content-booklet .link {
    width: 100%;
    min-width: inherit;
    margin: 40px 0 0;
  }
  .corporate-materials-page .content-corporate-materials .content-booklet .link img {
    width: 100%;
  }
  .corporate-materials-page .content-corporate-materials .content-newsletter .list-newsletter .item a .text {
    width: 100%;
    left: 0;
    bottom: 20px;
    padding-left: 20px;
    font-size: 2rem;
  }
}
@media screen and (max-width: 767px) {
  .corporate-materials-page .content-corporate-materials .content-newsletter .list-newsletter {
    width: auto;
  }
  .corporate-materials-page .content-corporate-materials .content-newsletter .list-newsletter .item {
    width: calc(50% - 15px);
  }
}
@media screen and (max-width: 479px) {
  .corporate-materials-page .content-corporate-materials .content-booklet {
    margin-top: 40px;
  }
  .corporate-materials-page .content-corporate-materials .content-booklet .detail {
    font-size: 2rem;
  }
  .corporate-materials-page .content-corporate-materials .content-booklet .link a .text {
    padding-right: 10px;
    padding-left: 10px;
    font-size: 1.8rem;
  }
  .corporate-materials-page .content-corporate-materials .content-newsletter .list-newsletter {
    margin-left: 0;
    margin-top: 40px;
  }
  .corporate-materials-page .content-corporate-materials .content-newsletter .list-newsletter .item {
    width: 100%;
    margin-left: 0;
  }
}
.search-page .list-search {
  margin-top: 30px;
}
.search-page .list-search .item {
  display: flex;
  border-bottom: 1px solid #e1e1e1;
  padding: 0 62px 50px;
  margin-bottom: 50px;
}
.search-page .list-search .item .img {
  min-width: 220px;
  height: 204px;
  margin-right: 40px;
}
.search-page .list-search .item .img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.search-page .list-search .item .detail {
  font-size: 2rem;
}
.search-page .list-search .item .detail h3 {
  margin-bottom: 24px;
  font-size: 1.3em;
  color: #2e2d2d;
}
.search-page .list-search .item .detail p {
  font-size: 1em;
  line-height: 1.4;
}
.search-page .list-search .item .detail a {
  display: inline-block;
  position: relative;
  padding-right: 25px;
  margin-top: 24px;
  color: #ed7725;
}
.search-page .list-search .item .detail a::after {
  content: "h";
  width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ed7725;
  border-radius: 50%;
  position: absolute;
  right: 0;
  top: 3px;
  font-size: 10px;
  text-indent: 1px;
  color: #ed7725;
}
.search-page .list-search.hide-more .item + .item + .item + .item + .item + .item {
  display: none;
}
.search-page .btn-load-more {
  margin-top: 40px;
}

@media screen and (max-width: 1024px) {
  .search-page .list-search .item {
    padding: 0 0 50px;
  }
}
@media screen and (max-width: 1023px) {
  .search-page .list-search .item .img {
    margin-right: 20px;
  }
}
@media screen and (max-width: 767px) {
  .search-page .list-search .item {
    flex-wrap: wrap;
    padding-bottom: 30px;
    margin-bottom: 32px;
  }
  .search-page .list-search .item .img {
    width: 100%;
    height: auto;
    margin: 0 0 24px;
  }
  .search-page .list-search .item .detail h3 {
    margin-bottom: 16px;
  }
  .search-page .list-search .item .detail a {
    margin-top: 16px;
  }
}
@media screen and (max-width: 479px) {
  .search-page .btn-load-more {
    margin-top: 0;
  }
}
.search-page .tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}
.search-page .tags a {
  --tw-text-opacity: 1;
  color: rgb(254 103 4/var(--tw-text-opacity));
  display: block;
  font-size: 16px;
  line-height: 20px;
  margin-top: 0.25rem;
}
.text-large .search-page .tags a {
  font-size: 18px;
}
.search-page .tags a:hover {
  --tw-text-opacity: 1;
  color: rgb(54 53 53/var(--tw-text-opacity));
}

.pdpa .container .inner {
  width: 930px;
  font-size: 20px;
}
.text-large .pdpa .container .inner {
  font-size: 22px;
}
.pdpa .container .inner .page-center {
  color: #ffffff;
}
.pdpa .container .inner .page-center .text-primary {
  font-size: 26px;
}
.text-large .pdpa .container .inner .page-center .text-primary {
  font-size: 28px;
}
.pdpa .container .inner .page-center .detail p {
  display: contents;
}
.pdpa .container .inner .page-center .detail a.link-detail {
  font-size: 20px;
}
.pdpa .container .inner .page-center .detail a.link-detail:hover {
  text-decoration: underline;
}
.pdpa .container .inner .btn {
  min-width: 140px;
  margin: 0px 8px;
}

@media (max-width: 639px) {
  .pdpa .container .inner .btn {
    margin-left: auto;
    margin-right: auto;
    margin-top: 1.25rem;
    width: 100%;
  }
}
.most-viewed .items .item .tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}
.most-viewed .items .item .tags a {
  --tw-text-opacity: 1;
  color: rgb(254 103 4/var(--tw-text-opacity));
  display: block;
  font-size: 16px;
  line-height: 20px;
  margin-top: 0.25rem;
}
.text-large .most-viewed .items .item .tags a {
  font-size: 18px;
  line-height: 22px;
}
.most-viewed .items .item .tags a:hover {
  --tw-text-opacity: 1;
  color: rgb(54 53 53/var(--tw-text-opacity));
}

.section-project-highlight .tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}
.section-project-highlight .tags a {
  --tw-text-opacity: 1;
  color: rgb(254 103 4/var(--tw-text-opacity));
  display: block;
  font-size: 16px;
  line-height: 20px;
  margin-top: 0.25rem;
}
.text-large .section-project-highlight .tags a {
  font-size: 18px;
}
.section-project-highlight .tags a:hover {
  --tw-text-opacity: 1;
  color: rgb(54 53 53/var(--tw-text-opacity));
}

#frm_subscription button[disabled] {
  opacity: 0.6;
  cursor: not-allowed;
}