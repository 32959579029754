.special-advisory-board-page {
  #footer {
    margin-top: -108px;
  }

  .banner-page {
    .btn-learn-more {
      margin-top: 60px;
    }

    .group-btn {
      display: flex;
      align-items: center;
      margin-top: 60px;
      font-size: 48px;

      a {
        border-bottom: 1px solid $white;
        padding-bottom: 10px;
        margin: 0;
        font-size: 1em;
        line-height: 1;
        font-family: "db_ozone_x";
        color: $orange;

        small {
          display: block;
          margin: 7px 0 0 10px;
          font-size: 0.62em;
          line-height: 1;
          color: $white;
        }
      }

      a + a {
        margin-left: 50px;
      }
    }
  }

  .content-special-advisory-board {
    width: 100%;
    overflow: hidden;
  }

  .slide-special-advisory-board {
    .owl-stage-outer {
      overflow: visible;
    }

    .item {
      display: flex;
      background-color: #2f3844;
    }

    .box-img {
      min-width: 50%;
      width: 50%;

      img {
        width: 100%;
      }

      .text {
        height: 300px;
        background-color: #4c4b49;
        padding: 56px 68px;
        font-size: 22px;
        color: $white;
        transition: 0.25s ease;

        h2 {
          position: relative;
          margin-bottom: 20px;
          font-size: 3.5em;
          font-family: "db_ozone_x-bold";
          line-height: 0.8;

          strong {
            display: block;
            margin-bottom: -10px;
            font-size: 1.15em;
          }
        }

        p {
          line-height: 1.3;

          strong {
            font-family: "db_ozone_x-bold";
          }
        }
      }
    }

    .owl-item.active .text {
      background-color: $orange;
    }

    .box-detail {
      width: 100%;
      height: 684px;
      position: relative;
      padding: 80px 45px 0;
      color: #b8b2ae;
      font-size: 20px;
      line-height: 1.4;
      overflow: hidden;

      .scroll {
        width: 100%;
        height: calc(100% - 112px);
        overflow: hidden;
        overflow-y: auto;
      }

      section {
        padding-right: 40px;
      }

      section + section {
        margin-top: 26px;
      }

      h3 {
        padding-bottom: 15px;
        margin-bottom: 30px;
        font-size: 1.6em;
        color: $orange;

        &::before {
          width: 42px;
          height: 3px;
        }
      }

      p + p {
        margin-top: 40px;
      }

      .list-bullet {
        li {
          padding-left: 20px;

          &::before {
            background-color: $orange;
            top: 10px;
          }
        }
      }

      .btn-style {
        width: 100%;
        height: 68px;
        background-color: #2a2b34;
        position: absolute;
        right: 0;
        bottom: 0;
        font-size: 26px;
        font-family: "db_ozone_x-bold";

        &::before {
          width: 29px;
          height: 29px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid $white;
          border-radius: 50%;
          margin-left: 20px;
          order: 2;
          font-size: 16px;
          text-indent: 2px;
        }
      }
    }

    .owl-nav,
    .owl-dots {
      display: none;
    }
  }
}

@media screen and (max-width: 1024px) {
  .special-advisory-board-page {
    .slide-special-advisory-board {
      .box-img {
        .text {
          height: 340px;
        }
      }

      .box-detail {
        height: 664px;
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .special-advisory-board-page {
    .slide-special-advisory-board {
      .item {
        flex-wrap: wrap;
      }

      .box-img {
        width: 100%;
        .text {
          height: 310px;
        }
      }

      .box-detail {
        width: 100%;
        height: 800px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .special-advisory-board-page {
    .banner-page {
      .group-btn {
        a {
          color: #000;
        }
      }
    }

    .slide-special-advisory-board {
      .box-img {
        .text {
          height: 240px;
          padding: 30px 20px;

          h2 {
            font-size: 3em;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 479px) {
  .special-advisory-board-page {
    .banner-page {
      .group-btn {
        flex-wrap: wrap;
        margin-top: 30px;
        font-size: 34px;

        a {
          width: 100%;
        }
        a + a {
          margin: 10px 0 0;
        }
      }
    }

    .slide-special-advisory-board {
      max-width: 100%;
      .box-img {
        .text {
          height: 240px;
          padding: 30px 20px;

          h2 {
            font-size: 2.4em;
          }
        }
      }

      .box-detail {
        padding: 40px 20px 0;

        .btn-style {
          font-size: 22px;
        }
      }
    }
  }
}
