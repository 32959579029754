.excutive-director-page {
  #footer {
    margin-top: -108px;
  }

  .content-excutive-director {
    display: flex;
    background-color: #2f3844;

    .box-img {
      min-width: 50%;
      width: 50%;
      align-self: baseline;

      img {
        width: 100%;
      }

      .text {
        background-color: $orange;
        padding: 56px 68px;
        font-size: 30px;
        color: $white;

        .text-large & {
          font-size: 32px;
        }

        h2 {
          position: relative;
          font-size: 1.3em;
          font-family: "db_ozone_x-bold";

          &::before {
            content: "";
            width: 13px;
            height: 13px;
            display: none;
            border: 1px solid $white;
            border-radius: 50%;
            position: absolute;
            left: -15px;
            top: 0;
          }
        }
      }
    }

    .box-detail {
      width: 100%;
      padding: 80px 90px 0;
      color: #b8b2ae;
      font-size: 20px;
      line-height: 1.4;
      overflow: hidden;

      .text-large & {
        font-size: 22px;
      }

      .scroll {
        height: calc(100% - 68px);
        max-width: 500px;
        overflow: hidden;
        overflow-y: auto;
      }

      section {
        padding-right: 40px;
      }

      section + section {
        margin-top: 26px;
      }

      h3 {
        padding-bottom: 15px;
        margin-bottom: 30px;
        font-size: 1.6em;
        color: $orange;

        &::before {
          width: 42px;
          height: 3px;
        }
      }
    }
  }

  .box-btn-download {
    background-color: #2f3844;

    .page-center {
      display: flex;
      padding: 40px 0;
    }
  }
}

@media screen and (max-width: 1024px) {
  .excutive-director-page {
    .content-excutive-director {
      .box-detail {
        padding: 40px 40px 0;
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .excutive-director-page {
    .content-excutive-director {
      flex-wrap: wrap;

      .box-img,
      .box-detail {
        width: 100%;
      }

      .box-detail {
        .scroll {
          max-width: 100%;
        }
      }
    }

    .box-btn-download {
      .page-center {
        padding-top: 0;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .excutive-director-page {
    .content-excutive-director {
      .box-img {
        .text {
          padding: 25px;
        }
      }
    }
  }
}

@media screen and (max-width: 479px) {
  .excutive-director-page {
    .content-excutive-director {
      .box-img {
        .text {
          font-size: 24px;

          .text-large & {
            font-size: 26px;
          }
        }
      }

      .box-detail {
        padding: 40px 20px 0;
      }
    }

    .box-btn-download {
      .btn-style {
        width: 100%;
      }
    }
  }
}
