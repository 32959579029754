.search-page {
	.list-search {
		margin-top: 30px;

		.item {
			display: flex;
			border-bottom: 1px solid #e1e1e1;
			padding: 0 62px 50px;
			margin-bottom: 50px;

			.img {
				min-width: 220px;
				height: 204px;
				margin-right: 40px;

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
			
			.detail {
				font-size: 2rem;

				h3 {
					margin-bottom: 24px;
					font-size: 1.3em;
					color: #2e2d2d;
				}

				p {
					font-size: 1em;
					line-height: 1.4;
				}

				a {
					display: inline-block;
					position: relative;
					padding-right: 25px;
					margin-top: 24px;
					color: $orange;

					&::after {
						content: '\68';
						width: 14px;
						height: 14px;
						display: flex;
						align-items: center;
						justify-content: center;
						border: 1px solid $orange;
						border-radius: 50%;
						position: absolute;
						right: 0;
						top: 3px;
						@extend .add-font-icon;
						font-size: 10px;
						text-indent: 1px;
						color: $orange;
					}
				}
			}
		}
		&.hide-more .item+.item+.item+.item+.item+.item {
			display: none;
		}
	}

	.btn-load-more {
		margin-top: 40px;
	}
}










@media screen and (max-width:1024px) {
	.search-page {
		.list-search {
			.item {
				padding: 0 0 50px;
			}
		}
	}
}





@media screen and (max-width:1023px) {
	.search-page {
		.list-search {
			.item .img {
				margin-right: 20px;
			}
		}
	}
}





@media screen and (max-width:767px) {
	.search-page {
		.list-search {
			.item {
				flex-wrap: wrap;
				padding-bottom: 30px;
				margin-bottom: 32px;

				.img {
					width: 100%;
					height: auto;
					margin: 0 0 24px;
				}

				.detail {
					h3 {
						margin-bottom: 16px;
					}

					a {
						margin-top: 16px;
					}
				}
			}
		}
	}
}





@media screen and (max-width:479px) {
	.search-page {
		.btn-load-more {
			margin-top: 0;
		}
	}
}