.search-page {
  .tags {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    a {
      --tw-text-opacity: 1;
      color: rgb(254 103 4 / var(--tw-text-opacity));
      display: block;
      font-size: 16px;
      line-height: 20px;
      margin-top: 0.25rem;

      .text-large & {
        font-size: 18px;
      }

      &:hover {
        --tw-text-opacity: 1;
        color: rgb(54 53 53 / var(--tw-text-opacity));
      }
    }
  }
}

// PDPA
.pdpa {
  .container {
    .inner {
      width: 930px;
      font-size: 20px;

      .text-large & {
        font-size: 22px;
      }

      .page-center {
        color: #ffffff;
        .text-primary {
          font-size: 26px;

          .text-large & {
            font-size: 28px;
          }
        }

        .detail {
          p {
            display: contents;
          }
          a.link-detail {
            font-size: 20px;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

      .btn {
        min-width: 140px;
        margin: 0px 8px;
      }
    }
  }
}
@media (max-width: 639px) {
  .pdpa {
    .container {
      .inner {
        .btn {
          margin-left: auto;
          margin-right: auto;
          margin-top: 1.25rem;
          width: 100%;
        }
      }
    }
  }
}

// Most viewed
.most-viewed {
  .items {
    .item {
      .tags {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;

        a {
          --tw-text-opacity: 1;
          color: rgb(254 103 4 / var(--tw-text-opacity));
          display: block;
          font-size: 16px;
          line-height: 20px;
          margin-top: 0.25rem;

          .text-large & {
            font-size: 18px;
            line-height: 22px;
          }

          &:hover {
            --tw-text-opacity: 1;
            color: rgb(54 53 53 / var(--tw-text-opacity));
          }
        }
      }
    }
  }
}

// Project Highlight
.section-project-highlight {
  .tags {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;

    a {
      --tw-text-opacity: 1;
      color: rgb(254 103 4 / var(--tw-text-opacity));
      display: block;
      font-size: 16px;
      line-height: 20px;
      margin-top: 0.25rem;

      .text-large & {
        font-size: 18px;
      }

      &:hover {
        --tw-text-opacity: 1;
        color: rgb(54 53 53 / var(--tw-text-opacity));
      }
    }
  }
}

// Popup subscription
#frm_subscription {
  button[disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }
}