.add-font-icon {
  font-weight: normal !important;
  font-family: "tij" !important;
  text-transform: none !important;
}

.orange {
  color: $orange !important;
}

.black {
  color: $black !important;
}

.editor ul,
.list-bullet {
  padding: 0;
  margin: 10px 0;
  list-style: none;

  & > li {
    position: relative;
    padding-left: 15px;
    margin-top: 15px;

    li {
      font-size: 0.9em;
      &::before {
        width: 5px;
        height: 5px;
        background-color: #000 !important;
        top: 6px;
      }
    }
  }

  & > li:first-child {
    margin-top: 0;
  }

  & > li::before {
    content: "";
    width: 7px;
    height: 7px;
    display: block;
    background-color: $black;
    border-radius: 100%;
    position: absolute;
    left: 0;
    top: 7px;
    text-indent: -9999px;
  }

  &.-orange {
    & > li::before {
      background-color: $orange;
    }
  }

  &.-circle {
    & > li::before {
      background: none;
      border: 1px solid $black;
    }
  }
}

.editor ol,
.list-number {
  padding: 0;
  margin: 5px 0;
  list-style: none;
  counter-reset: number;

  & > li {
    position: relative;
    padding-left: 20px;
    margin-top: 8px;
  }

  & > li:first-child {
    margin-top: 0;
  }

  & > li::before {
    counter-increment: number;
    content: counters(number, ".") ".";
    position: absolute;
    left: 0;
    top: 0;
  }

  & .list-number li,
  & ol li {
    padding-left: 32px;
  }

  & .list-number li::before {
    content: counters(number, ".") "";
  }
}

.hover-img {
  display: block;
  overflow: hidden;

  img {
    transition: 0.25s ease;
  }

  .desktop &:hover img {
    transform: scale(1.05);
  }
}

.btn-style {
  min-width: 216px;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $dark-orange;
  border: none;
  border-radius: 0;
  padding: 15px 30px;
  margin: 0 auto;
  font-size: 22px;
  color: $white !important;
  transition: 0.25s ease;

  .desktop &:hover {
    background-color: $black;
  }

  &.-orange {
    background-color: $orange;
  }

  &.-dark-gray {
    background-color: $dark-gray;
  }
}

.btn-view-all {
  height: 37px;
  display: flex;
  align-items: center;
  position: relative;
  padding-right: 47px;
  font-size: 21px;
  font-family: "db_ozone_x-medium";

  &::before {
    width: 37px;
    height: 37px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid $orange;
    border-radius: 50%;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 15px;
    color: $orange;
    transition: 0.15s ease;
  }

  .desktop &:hover::before {
    background-color: $orange;
    color: $white;
  }
}

.btn-load-more {
  margin-top: 70px;

  &::after {
    content: "\71";
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #fff;
    border-radius: 50%;
    margin-left: 8px;
    @extend .add-font-icon;
    font-size: 14px;
  }
}

.btn-read-more {
  display: flex;
  align-items: center;
  font-size: 28px;
  color: $white;

  &::after {
    content: "\68";
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid $white;
    border-radius: 50%;
    margin-left: 14px;
    @extend .add-font-icon;
    font-size: 0.7em;
    text-indent: 3px;
    transition: 0.15s ease;
  }

  .desktop &:hover {
    color: $white;
  }

  .desktop &:hover::after {
    // margin-left:18px;
    transform: scale(1.15);
    color: $white;
  }
}

.text-view-all {
  font-size: 20px;
  padding-top: 5px;

  &::after {
    content: "\68";
    position: relative;
    top: -2px;
    margin-left: 10px;
    font-size: 20px;
    @extend .add-font-icon;
    transition: 0.15s ease;
  }

  .desktop &:hover::after {
    margin-left: 15px;
  }
}

.section {
  .page-center {
    margin-top: 50px;

    &::before {
      content: "";
      width: 74px;
      height: 37px;
      background-color: $orange;
      position: absolute;
      left: -104px;
      top: 80px;
    }

    &::after {
      content: "";
      width: 52px;
      height: 9px;
      background-color: $light-gray;
      position: absolute;
      left: 0;
      top: 80px;
    }
  }

  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.old-theme {
  .banner-page {
    height: calc(100vh - 94px);
    position: relative;
    overflow: hidden;

    &::before {
      content: "";
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      position: absolute;
      left: 0;
      top: 0;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      .ie & {
        height: auto;
      }
    }

    .box-text {
      position: absolute;
      left: 84px;
      top: 50%;
      transform: translateY(-50%);
      transform-origin: left;
      color: $white;
      font-size: 32px;
      margin-top: 0 !important;

      .breadcrumbs {
        margin-bottom: 25px;

        a {
          margin: 0;
          font-size: 0.72em;
          text-transform: uppercase;
          color: #d2d0d0;
        }
      }

      h1 {
        margin-bottom: 30px;
        font-size: 2.8125em;
        line-height: 0.9;
        font-family: "db_ozone_x-medium";
        text-transform: uppercase;
        // text-shadow: 0 5px 6px rgba(0,0,0,0.3);
      }

      p {
        // text-shadow: 0 5px 6px rgba(0,0,0,0.3);
      }

      a {
        display: flex;
        align-items: center;
        margin-top: 100px;
        font-size: 0.75em;
        font-family: "db_ozone_x-medium";
        color: $white;
        // text-shadow: 0 5px 6px rgba(0,0,0,0.3);

        &::before {
          width: 35px;
          height: 35px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 2px solid $white;
          border-radius: 50%;
          margin-right: 20px;
        }
      }

      .btn-style {
        width: 220px;
        height: 60px;
        margin-left: 0;
      }
    }
  }

  .title-section {
    font-size: 60px;
    font-family: "db_ozone_x-bold";
    color: $dark;
  }

  .list-content {
    width: calc(100% + 35px);
    display: flex;
    flex-wrap: wrap;
    margin: -35px 0 0 -35px;

    .item {
      width: calc(25% - 35px);
      margin: 35px 0 0 35px;

      .img {
        position: relative;

        img {
          width: 100%;
        }

        span {
          width: 117px;
          height: 31px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $white;
          position: absolute;
          right: 0;
          bottom: 0;
          padding-top: 5px;
          font-size: 16px;
          color: $dark-blue;

          &::before {
            position: relative;
            top: -2px;
            margin-right: 8px;
            font-size: 16px;
          }
        }
      }

      .text {
        margin-top: 16px;

        .date {
          margin-bottom: 5px;
          font-size: 14px;
          color: #c6c5c5;
        }

        h3 {
          min-height: 38px;
          margin: 0 0 6px;
          font-size: 19px;

          .text-large & {
            font-size: 21px;
          }
        }

        a {
          font-size: 16px;
          color: $orange;

          .text-large & {
            font-size: 18px;
          }

          .desktop &:hover {
            color: $black;
          }
        }
      }
    }

    &.col3 {
      width: calc(100% + 40px);
      margin: -40px 0 0 -40px;

      .item {
        width: calc(33.33% - 40px);
        margin: 40px 0 0 40px;
      }
    }

    &.slide-content {
      margin: 0;

      .owl-dots {
        position: relative;
        bottom: 0;
        margin-top: 30px;

        .owl-dot {
          background-color: $orange;
        }
        .owl-dot.active {
          background-color: #000;
        }
      }
    }
    .owl-carousel {
      .item {
        width: 100%;
        margin: 0;
      }
    }
  }

  [class*="layout-col"] {
    padding-top: 70px;

    .page-center {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .title-page {
      width: 100%;
      margin-bottom: 60px;
      font-size: 90px;

      .text-large & {
        font-size: 92px;
      }

      h1 {
        font-size: 1em;
        font-family: "db_ozone_x-medium";
        color: #2f3844;
      }

      h2 {
        font-size: 0.6666em;
        font-family: "db_ozone_x-medium";
        color: #2f3844;
      }

      p {
        margin-top: -10px;
        font-size: 0.356em;
      }
    }

    .heading {
      width: 270px;
      font-size: 60px;

      .text-large & {
        font-size: 62px;
      }

      h2 {
        font-size: 1em;
        line-height: 0.8;
        font-family: "db_ozone_x-bold";
      }
    }

    .menu-left {
      width: 320px;

      .btn-active {
        width: 100%;
        height: 62px;
        display: none;
        align-items: center;
        border-bottom: 1px solid #dce1e5;
        position: relative;
        padding: 0 45px 0 10px;
        color: #2c2c2c;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &::after {
          content: "\71";
          width: 40px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          right: 0;
          top: 0;
          @extend .add-font-icon;
        }
      }

      a {
        min-height: 28px;
        display: block;
        position: relative;
        padding-left: 52px;
        font-size: 32px;
        color: #b4b9bf;

        .text-large & {
          font-size: 34px;
        }

        &::before {
          content: "\6a";
          width: 28px;
          height: 28px;
          display: none;
          align-items: center;
          justify-content: center;
          border: 1px solid #b6bbc1;
          border-radius: 50%;
          position: absolute;
          left: 0;
          top: 0;
          @extend .add-font-icon;
          font-size: 7px;
          color: $orange;
        }

        .desktop &:hover {
          color: $dark;
        }
      }

      a + a {
        margin-top: 25px;
      }

      a.active {
        color: $dark;

        &::before {
          display: flex;
        }
      }
    }

    .design-row-style {
      min-width: 800px;
    }
  }

  .layout-col1 {
    .page-center {
      flex-direction: column;
      align-items: center;
    }

    .heading {
      width: 100%;
      margin-bottom: 55px;
      text-align: center;

      p {
        width: 65%;
        margin: 15px auto 0;
        font-size: 0.4em;
        line-height: 1.3;
      }
    }
  }

  .listing-in-page {
    .title-page {
      margin-bottom: 80px;
    }

    .list-content {
      position: relative;

      &::before {
        content: "";
        width: 74px;
        height: 37px;
        display: block;
        background-color: $orange;
        position: absolute;
        left: -68px;
        top: 40px;
      }
    }
  }

  .underline {
    position: relative;
    padding-bottom: 24px;
    text-decoration: none !important;

    &::before {
      content: "";
      width: 52px;
      height: 6px;
      display: block;
      background-color: $black;
      position: absolute;
      left: 0;
      bottom: 0;
    }

    &.-center::before {
      left: 50%;
      margin-left: -26px;
    }

    &.-light-gray::before {
      background-color: $light-gray;
    }

    &.-orange::before {
      background-color: $orange;
    }

    &.-dark-blue::before {
      background-color: $dark-blue;
    }
  }

  .design-row-style {
    width: 800px;
    max-width: 100%;
    margin-top: 12px;
    font-size: 22px;

    .text-large & {
      font-size: 24px;
    }

    .item {
      border-bottom: 1px solid #dce1e5;
      padding-bottom: 26px;
      margin-bottom: 26px;

      &:last-child {
        border: none;
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }

    a {
      font-size: 1em;
      color: $black;

      span {
        display: block;
        color: #8d959f;
      }
    }

    .-pdf {
      background: url("../img/icons/file-pdf.png") 0 0/38px no-repeat;
      padding-left: 64px;
    }
    .-excel {
      background: url("../img/icons/file-excel.png") 0 0/38px no-repeat;
      padding-left: 64px;
    }

    .-play {
      min-height: 28px;
      position: relative;
      padding-left: 58px;

      &::before {
        content: "\6a";
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $orange;
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 0;
        @extend .add-font-icon;
        font-size: 7px;
        color: $orange;
      }
    }
  }

  .img-banner {
    width: 100%;
    height: 390px;
    background: 50% 0 / cover no-repeat;
    margin: 74px 0;
  }

  .box-shadow {
    box-shadow: 0 6px 5px rgba(0, 0, 0, 0.17);
  }

  .box-search-job {
    position: relative;
    margin-bottom: 48px;

    .inputtext-style {
      height: 58px;
      border-color: #dce1e5 !important;
      padding-right: 70px !important;
      font-size: 24px !important;

      .text-large & {
        font-size: 26px !important;
      }
    }

    button {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: none;
      border: none;
      position: absolute;
      padding: 0 26px;
      right: 0;
      top: 0;

      &::before {
        content: "\64";
        @extend .add-font-icon;
        font-family: 18px;
        color: #b4b9bf;
      }
    }
  }

  .nicescroll-rails {
    width: 1px !important;
    background-color: #959595;

    &::before,
    &::after {
      content: "";
      width: 7px;
      height: 7px;
      display: block;
      border: 1px solid $orange;
      border-radius: 50%;
      position: absolute;
      left: -4px;
      top: -8px;
    }

    &::before {
      top: auto;
      bottom: -8px;
    }

    .nicescroll-cursors {
      width: 100% !important;
      background-color: $orange !important;
      border: none !important;
      border-radius: none !important;
    }
  }

  html .jspVerticalBar {
    width: 7px;
    height: 100%;
    background: none;
    position: absolute;
    right: 0;
    top: 0;
    padding: 0;

    &::before,
    &::after {
      content: "";
      width: 7px;
      height: 7px;
      display: block;
      background-color: #2f3844;
      border: 1px solid $orange;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 13;
    }

    &::before {
      top: auto;
      bottom: 0;
    }

    .jspTrack {
      width: 1px;
      background-color: #959595;
      position: absolute;
      left: 3px;

      .jspDrag {
        width: 3px;
        background-color: $orange;
        margin-left: -1px;
      }
    }
  }

  .box-under-construction {
    width: 100%;
    padding-top: 150px;
    margin: 0 auto;
    text-align: center;
    font-size: 26px;

    .text-large & {
      font-size: 28px;
    }

    h1 {
      font-size: 5.2em;
      line-height: 0.8;
      color: $orange;
    }

    h2 {
      font-size: 1em;
    }

    p {
      display: inline-block;
      border-top: 1px solid $black;
      padding-top: 22px;
      margin-top: 22px;
      font-size: 0.8em;
    }
  }

  .box-success {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 150px;
    margin: 0 auto;
    text-align: center;
    font-size: 26px;

    .text-large & {
      font-size: 28px;
    }

    h1 {
      margin-bottom: 60px;
      font-size: 3em;
      line-height: 0.8;
      color: $orange;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  .animateScale {
    animation: animateScale 1s ease;
  }

  @keyframes animateScale {
    0% {
      transform: scale(1.1);
    }

    100% {
      transform: scale(1);
    }
  }

  .animateScale {
    animation: animateScale 1s ease;
  }

  .content-logo {
    .page-center {
      display: flex;
      flex-wrap: wrap;
    }

    .list-logo {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin: 70px 0 0 -15px;

      .item {
        width: calc(33.33% - 15px);
        margin: 15px 0 0 15px;
        box-shadow: 0 6px 16px rgba(0, 0, 0, 0.17);

        a {
          width: 100%;
          height: 100%;
          display: block;
          border: 1px solid #ebebeb;
          position: relative;

          .text {
            width: 100%;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            background-color: $orange;
            position: absolute;
            left: 0;
            bottom: 0;
            padding: 22px 75px 22px 32px;
            font-size: 25px;
            font-family: "db_ozone_x-bold";
            color: #fff;
            z-index: 13;

            .text-large & {
              font-size: 27px;
            }

            small {
              display: block;
              margin-top: 5px;
              font-size: 0.75em;
              font-family: "db_ozone_x";
            }

            &::after {
              content: "\68";
              width: 38px;
              height: 38px;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: #fff;
              border-radius: 50%;
              position: absolute;
              right: 15px;
              top: 50%;
              margin-top: -19px;
              @extend .add-font-icon;
              color: $orange;
            }
          }
        }
      }
    }
  }
  .press-room-page .content-logo {
    border-top: 1px solid #dce1e5;
    padding-top: 60px;
    margin-top: 60px;

    .title-page {
      margin-bottom: 64px;

      h2 {
        font-family: "db_ozone_x-bold";
      }
    }

    .list-logo {
      position: relative;
      margin-top: 0;

      &::before {
        content: "";
        width: 74px;
        height: 37px;
        display: block;
        background-color: $orange;
        position: absolute;
        left: -78px;
        top: 15px;
      }
    }
  }

  .slide-group-btn {
    width: 100%;
    border-bottom: 1px solid #dce1e5;
    margin-bottom: -1px;
    font-size: 21px;

    .text-large & {
      font-size: 23px;
    }

    .owl-stage {
      min-width: 100% !important;
      display: flex;
    }

    a {
      display: block;
      position: relative;
      padding: 50px 5px 40px;
      color: #000;
      white-space: nowrap;

      &::after {
        content: "";
        width: 100%;
        height: 3px;
        display: block;
        background-color: $orange;
        position: absolute;
        left: 0;
        bottom: -1px;
        transform: scale(0);
        transition: 0.25s ease;
      }

      &.active {
        font-family: "db_ozone_x-bold";

        &.active::after {
          transform: scale(1);
        }
      }
    }

    .owl-nav {
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: -40px;

      & > div {
        width: 30px;
        height: 30px;
        border: 1px solid #000;
        border-radius: 50%;
        position: relative;
        left: 0;
        top: 0;
      }
      & > div + div {
        margin-left: 5px;
      }
    }

    .owl-nav.disabled {
      display: none !important;
    }
  }

  .group-box-content {
    & > div {
      height: 897px;
      display: flex;

      .img {
        min-width: 50%;
        width: 50%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          margin: 0;
          object-fit: cover;
        }
      }

      .detail {
        width: 100%;
        height: 100%;
        padding: 84px 64px 0 64px;
        font-size: 24px;
        line-height: 1.5;

        .text-large & {
          font-size: 26px;
        }

        .scroll {
          width: 100%;
          height: calc(100% - 46px);
          overflow: hidden;
          overflow-y: auto;
        }

        section {
          padding-right: 40px;
        }

        section + section {
          margin-top: 26px;
        }

        h3 {
          padding-top: 10px;
          padding-bottom: 10px;
          margin-bottom: 30px;
          font-size: 2.5em;
          line-height: 0.8;
          font-family: "db_ozone_x-bold";

          &::before {
            width: 52px;
            height: 6px;
          }
        }

        h4 {
          margin-bottom: 10px;
          font-size: 1.25em;
          font-family: "db_ozone_x-bold";
        }

        .box-quote {
          quotes: "“" "”" "‘" "’";
          position: relative;
          padding: 48px 25px;
          margin-bottom: 20px;
          text-align: center;
          font-size: 1.15em;
          color: $orange;

          &::before,
          &::after {
            width: 100%;
            height: 40px;
            display: block;
            position: absolute;
            left: 0;
            font-size: 120px;
            text-align: center;
          }

          &::before {
            content: open-quote;
            top: -40px;
          }

          &::after {
            content: close-quote;
            bottom: 40px;
          }
        }

        p + p {
          margin-top: 60px;
        }

        .align-center {
          padding: 0 40px;
        }
      }
    }

    & > div:nth-of-type(even) {
      .img {
        order: 2;
        margin-left: 0;
      }

      .detail {
        padding-right: 64px;
        padding-left: 0;
        margin-left: auto;

        section {
          padding-left: 40px;
        }
      }
    }
  }

  .text-coming-soon {
    font-size: 60px;
    font-family: "db_ozone_x-bold";
    color: $orange;

    .text-large & {
      font-size: 62px;
    }
  }

  .primary-content {
    width: 800px;
    min-width: 800px;
  }

  @keyframes animateChange {
    0% {
      transform: translateY(60px);
      opacity: 0;
    }
    20%,
    80% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(-60px);
      opacity: 0;
    }
  }
}

#wrapper.-detail {
  .old-theme .banner-page {
    .box-text {
      width: 1000px;
      max-width: 100%;
      top: auto;
      bottom: 60px;
      transform: translateY(0) scale(1) !important;

      p {
        font-size: 0.75em;
        font-family: "db_ozone_x-medium";
      }

      a {
        margin-top: 46px;
      }
    }
  }
}

@media screen and (min-width: 1400px) {
  .old-theme .banner-page {
    .box-text {
      transform: translateY(-50%) scale(1.2);
    }
  }
}

@media screen and (max-width: 1024px) {
  .old-theme [class*="layout-col"] {
    .design-row-style {
      min-width: 670px;
      width: 670px;
    }

    .title-page {
      margin-bottom: 20px;
    }
  }

  #wrapper.-detail {
    .old-theme .banner-page {
      .box-text {
        width: 860px;
      }
    }
  }

  .old-theme .content-logo {
    .list-logo {
      .item {
        a {
          .text {
            font-size: 20px;

            .text-large & {
              font-size: 22px;
            }
          }
        }
      }
    }
  }
  .old-theme .press-room-page {
    .content-logo .title-page {
      margin-bottom: 20px;
    }
  }

  .old-theme .group-box-content {
    & > div {
      .detail {
        padding: 50px 30px 0 30px;
      }
    }
    & > div:nth-of-type(even) {
      .detail {
        padding-right: 30px;
        padding-left: 0;
      }
    }
  }

  .old-theme .primary-content {
    min-width: 670px !important;
    width: 670px !important;
    margin-left: 20px;
  }
}

@media screen and (max-width: 1023px) {
  .old-theme .banner-page {
    height: auto;

    .box-text {
      width: 100%;
      background-color: $orange;
      position: relative;
      left: 0;
      top: 0 !important;
      bottom: auto !important;
      padding: 40px 20px;
      transform: translateY(0);
      font-size: 20px;

      .text-large & {
        font-size: 22px;
      }

      h1 {
        line-height: 0.8;
      }

      .breadcrumbs {
        a {
          font-size: 1em;
        }
      }

      a {
        margin-top: 20px;
        font-size: 1em;

        &::before {
          width: 24px;
          height: 24px;
          margin-right: 10px;
        }
      }
    }
  }

  .old-theme [class*="layout-col"] {
    .design-row-style {
      min-width: inherit;
      width: 100%;
      margin-top: 40px;
    }

    .menu-left {
      width: 100%;
      position: relative;
      z-index: 13;

      .btn-active {
        display: flex;
      }

      a {
        padding-left: 0;

        &::before {
          display: none !important;
        }
      }

      .group-btn {
        width: 100%;
        display: none;
        background-color: #fff;
        border: 1px solid #dce1e5;
        position: absolute;
        left: 0;
        padding: 14px 10px;
        margin-top: -1px;
      }
    }
  }

  .old-theme .list-content.col3 {
    width: calc(100% + 15px);
    margin: -15px 0 0 -15px;

    .item {
      width: calc(33.33% - 15px);
      margin: 15px 0 0 15px;
    }
  }

  .old-theme .content-logo {
    .list-logo {
      width: auto;

      .item {
        width: calc(50% - 15px);
      }
    }
  }

  .old-theme .group-box-content {
    & > div {
      height: auto;
      flex-wrap: wrap;

      .img,
      .detail {
        width: 100%;
      }

      .detail {
        padding: 40px !important;

        .scroll,
        .jspContainer,
        .jspPane {
          width: 100% !important;
          height: auto !important;
          position: relative !important;
        }

        section {
          padding: 0 !important;
        }
      }
    }
    & > div:nth-of-type(even) {
      .img {
        order: 0;
      }
    }
  }

  .old-theme .primary-content {
    min-width: inherit !important;
    width: 100% !important;
    margin-top: 40px !important;
    margin-left: 0 !important;
  }
}

@media screen and (max-width: 767px) {
  .old-theme .banner-page {
    .box-text {
      h1 {
        font-size: 2em;
      }
    }
  }

  .old-theme .section {
    .page-center::after {
      top: 66px;
    }
  }

  .old-theme .title-section {
    font-size: 50px;

    .text-large & {
      font-size: 52px;
    }
  }

  .old-theme [class*="layout-col"] {
    .title-page {
      font-size: 70px;

      .text-large & {
        font-size: 72px;
      }
    }

    .heading {
      p {
        width: 100%;
      }
    }

    .menu-left {
      .btn-active {
        font-size: 22px;

        .text-large & {
          font-size: 24px;
        }
      }

      .group-btn {
        a {
          font-size: 22px;

          .text-large & {
            font-size: 24px;
          }
        }
        a + a {
          margin-top: 15px;
        }
      }
    }
  }

  .old-theme .list-content.col3 {
    .item {
      width: calc(50% - 15px);
    }
  }

  .old-theme .box-under-construction {
    padding-top: 60px;

    h1 {
      font-size: 4em;
    }
  }

  .old-theme .content-logo {
    .list-logo {
      .item {
        a {
          .text {
            padding: 15px 75px 15px 15px;
            font-size: 18px;

            .text-large & {
              font-size: 20px;
            }
          }
        }
      }
    }
  }

  .old-theme .primary-content {
    margin-top: 20px !important;
  }
}

@media screen and (max-width: 479px) {
  .old-theme .title-section {
    font-size: 44px;
    line-height: 0.8;

    .text-large & {
      font-size: 46px;
    }
  }

  .old-theme .banner-page {
    height: auto;

    img {
      height: 220px;
    }

    .box-text {
      .breadcrumbs {
        margin-bottom: 10px;
        font-size: 0.9em;
      }

      h1 {
        margin-bottom: 10px;
        font-size: 1.7em;
      }

      br {
        display: none !important;
      }
    }
  }

  .old-theme .btn-view-all {
    height: 30px;

    &::before {
      width: 30px;
      height: 30px;
      margin: 0;
    }

    span {
      display: none;
    }
  }

  .old-theme .btn-load-more {
    width: 100%;
  }

  .old-theme .design-row-style {
    font-size: 18px;

    .text-large & {
      font-size: 20px;
    }

    .-play {
      padding-left: 40px;
    }
  }

  .old-theme [class*="layout-col"] {
    padding-top: 40px;

    .title-page {
      margin-bottom: 0;
      font-size: 44px;

      .text-large & {
        font-size: 46px;
      }

      p {
        font-size: 0.55em;
      }
    }

    .heading {
      font-size: 44px;

      .text-large & {
        font-size: 46px;
      }
    }

    .menu-left {
      .btn-active {
        font-size: 20px;

        .text-large & {
          font-size: 22px;
        }
      }

      .group-btn {
        a {
          font-size: 20px;

          .text-large & {
            font-size: 22px;
          }
        }
        a + a {
          margin-top: 10px;
        }
      }
    }
  }

  .old-theme .list-content.col3 {
    width: 100%;
    margin: -15px 0 0 0;

    .item {
      width: 100%;
      margin: 15px 0 0 0;
    }
  }

  .old-theme .img-banner {
    height: 220px;
  }

  .old-theme .box-under-construction {
    width: calc(100% - 30px);

    h1 {
      font-size: 2.2em;
    }
  }

  .old-theme .content-logo {
    .list-logo {
      margin-left: 0;

      .item {
        width: 100%;
        margin-left: 0;

        a {
          .text {
            font-size: 20px;

            .text-large & {
              font-size: 22px;
            }
          }
        }
      }
    }
  }

  .old-theme .slide-group-btn {
    margin-bottom: 50px;

    .owl-nav {
      bottom: -15px;
    }
  }

  .old-theme .group-box-content {
    & > div {
      .detail {
        padding: 20px !important;
        font-size: 18px;

        .text-large & {
          font-size: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 320px) {
  .old-theme .text-coming-soon {
    font-size: 50px;

    .text-large & {
      font-size: 52px;
    }
  }
}
