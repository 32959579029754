@font-face {
  font-family: "db_ozone_x";
  src: url("./fonts/db_ozone_x/db_ozone_x_v3.2.1-webfont.eot");
  src: url("./fonts/db_ozone_x/db_ozone_x_v3.2.1-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/db_ozone_x/db_ozone_x_v3.2.1-webfont.woff2") format("woff2"),
    url("./fonts/db_ozone_x/db_ozone_x_v3.2.1-webfont.woff") format("woff"),
    url("./fonts/db_ozone_x/db_ozone_x_v3.2.1-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "db_ozone_x-italic";
  src: url("./fonts/db_ozone_x/db_ozone_x_it_v3.2.1-webfont.eot");
  src: url("./fonts/db_ozone_x/db_ozone_x_it_v3.2.1-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/db_ozone_x/db_ozone_x_it_v3.2.1-webfont.woff2") format("woff2"),
    url("./fonts/db_ozone_x/db_ozone_x_it_v3.2.1-webfont.woff") format("woff"),
    url("./fonts/db_ozone_x/db_ozone_x_it_v3.2.1-webfont.ttf")
      format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "db_ozone_x-medium";
  src: url("./fonts/db_ozone_x/db_ozone_x_med_v3.2.1-webfont.eot");
  src: url("./fonts/db_ozone_x/db_ozone_x_med_v3.2.1-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/db_ozone_x/db_ozone_x_med_v3.2.1-webfont.woff2")
      format("woff2"),
    url("./fonts/db_ozone_x/db_ozone_x_med_v3.2.1-webfont.woff") format("woff"),
    url("./fonts/db_ozone_x/db_ozone_x_med_v3.2.1-webfont.ttf")
      format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "db_ozone_x-medium_italic";
  src: url("./fonts/db_ozone_x/db_ozone_x_med_it_v3.2.1-webfont.eot");
  src: url("./fonts/db_ozone_x/db_ozone_x_med_it_v3.2.1-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/db_ozone_x/db_ozone_x_med_it_v3.2.1-webfont.woff2")
      format("woff2"),
    url("./fonts/db_ozone_x/db_ozone_x_med_it_v3.2.1-webfont.woff")
      format("woff"),
    url("./fonts/db_ozone_x/db_ozone_x_med_it_v3.2.1-webfont.ttf")
      format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "db_ozone_x-bold";
  src: url("./fonts/db_ozone_x/db_ozone_x_bd_v3.2.1-webfont.eot");
  src: url("./fonts/db_ozone_x/db_ozone_x_bd_v3.2.1-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/db_ozone_x/db_ozone_x_bd_v3.2.1-webfont.woff2") format("woff2"),
    url("./fonts/db_ozone_x/db_ozone_x_bd_v3.2.1-webfont.woff") format("woff"),
    url("./fonts/db_ozone_x/db_ozone_x_bd_v3.2.1-webfont.ttf")
      format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "db_ozone_x-bold_italic";
  src: url("./fonts/db_ozone_x/db_ozone_x_bd_it_v3.2.1-webfont.eot");
  src: url("./fonts/db_ozone_x/db_ozone_x_bd_it_v3.2.1-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/db_ozone_x/db_ozone_x_bd_it_v3.2.1-webfont.woff2")
      format("woff2"),
    url("./fonts/db_ozone_x/db_ozone_x_bd_it_v3.2.1-webfont.woff")
      format("woff"),
    url("./fonts/db_ozone_x/db_ozone_x_bd_it_v3.2.1-webfont.ttf")
      format("truetype");
  font-weight: normal;
  font-style: normal;
}

* {
  -webkit-tap-highlight-color: transparent;
  text-rendering: optimizeLegibility;
  outline: none;
}

html .old-theme {
  font-size: 62.5%;
}
html.text-large .old-theme {
  font-size: 72.5%;
}

// body {
// 	background-color: #fff;
// 	margin: 0;
// 	padding: 0;
// 	font: normal 1.8rem/1 'db_ozone_x', Arial, Helvetica, sans-serif;
// 	color: $black;
// 	-webkit-font-smoothing: antialiased;
// 	-moz-osx-font-smoothing: grayscale;
// }

.old-theme {
  #wrapper * {
    box-sizing: border-box;
    &::before,
    &::after {
      box-sizing: border-box;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    font-weight: normal;
    font-size: 1em;
    line-height: 1;
  }

  ul,
  ol {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  blockquote {
    margin: 0 0 18px 18px;
    color: #666;
    font-style: italic;
  }

  strong {
    font-weight: normal !important;
  }
  em {
    font-style: italic;
  }

  p {
    margin: 0;

    & + p {
      margin-top: 15px;
    }
  }

  a {
    transition: color 0.15s ease;
    overflow-wrap: break-word;
    cursor: pointer;

    &:link,
    &:visited {
      color: inherit;
      text-decoration: none;
    }

    .desktop &:hover,
    &:active {
      color: $orange;
    }
  }

  label,
  button {
    cursor: pointer;
  }

  img {
    max-width: 100%;
    display: block;
    margin: 0 auto;
  }

  .img-left {
    float: left;
    margin: 20px 20px 20px 0;
  }
  .img-right {
    float: right;
    margin: 20px 0 20px 20px;
  }

  .align-right {
    text-align: right;
  }
  .align-left {
    text-align: left;
  }
  .align-center {
    text-align: center;
  }
  .align-justify {
    text-align: justify;
  }

  .flex-column {
    flex-direction: column;
    align-items: center;
  }

  .flex-center {
    justify-content: center;
  }

  .flex-end {
    display: flex;
    justify-content: flex-end;

    .btn-style {
      margin: 0;
    }
  }

  .clear-bold {
    font-weight: 400;
  }

  .clear {
    content: "";
    height: 0px;
    display: block;
    padding: 0;
    margin: 0;
    line-height: 0;
    clear: both;
    overflow: hidden;
    visibility: hidden;
  }
  .clear-float::after {
    @extend .clear;
  }

  .desktop-style {
    display: block !important;
  }
  .mobile-style {
    display: none !important;
  }

  .page-center {
    width: 1180px;
    max-width: $max-width-responsive;
    height: 100%;
    position: relative;
    margin: 0 auto;
  }

  #wrapper {
    min-height: 100vh;
    height: 100%;
    position: relative;
    margin: 0 auto;
    overflow: hidden;
  }

  #header,
  #container,
  #footer {
    width: 100%;
    position: relative;
    padding: 0;
    margin: 0;
    overflow: hidden;
  }
  #container {
    min-height: calc(100vh - 136px);
    margin-top: 136px;
    overflow: visible;
  }

  // Header
  #header {
    height: 142px;
    max-height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 131313;
    overflow: visible;
    transition: 0.25s ease;

    &::before {
      content: "";
      width: 100%;
      height: 94px;
      background-color: #fff;
      position: absolute;
      left: 0;
      top: 42px;
      z-index: 13;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16);
    }

    .page-center {
      width: calc(100% - 180px);
      height: 94px;
      z-index: 13;
    }

    .head-top {
      width: 100%;
      height: 42px;
      background-color: $orange;
      position: relative;
      z-index: 13131313;

      .page-center {
        height: 100%;
        display: flex;
        align-items: center;
      }
    }

    .menu-top {
      max-width: calc(100% - 154px);
      height: 100%;
      position: relative;
      padding-right: 30px;
      margin-left: 0;
      font-size: 20px;

      .group-btn {
        width: 100%;
        display: flex;
        position: relative;
        overflow: hidden;
        overflow-x: auto;
      }

      &::after {
        content: "\68";
        width: 30px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        background-color: $orange;
        position: absolute;
        right: 0;
        top: 0;
        // @extend .add-font-icon;
        color: #fff;
      }

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        white-space: nowrap;
        color: $white;

        &::after {
          content: "";
          width: 0;
          height: 0;
          display: none;
          border-bottom: 10px solid $white;
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          position: absolute;
          left: 50%;
          bottom: -3px;
          transform: translateX(-50%);
        }
      }
      a.active::after {
        display: block;
      }

      a + a {
        margin-left: 40px;
      }
    }

    .text-size,
    .language {
      display: flex;

      a {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid rgba(255, 255, 255, 0.5);
        position: relative;
        font-size: 17px;
        color: $white;
        transition: 0.125s ease;
        overflow: hidden;

        &::before {
          content: "";
          width: 100%;
          height: 100%;
          background-color: $white;
          position: absolute;
          left: 0;
          top: 100%;
          transition: 0.1s ease;
        }

        span {
          display: block;
          position: relative;
          z-index: 13;
        }

        .desktop &:hover,
        &.active {
          border-color: $white;
          color: $orange;

          &::before {
            top: 0;
          }
        }
      }

      a + a {
        margin-left: -1px;
      }
    }

    .language {
      margin-left: 16px;
    }

    h1 {
      height: 100%;
      display: inline-block;
      font-size: 30px;

      a {
        height: 100%;
        display: flex;
        align-items: center;

        &::before {
          content: "";
          width: 94px;
          height: 67px;
          display: block;
          background: url("../img/share/logo-tij.png") 0 0/100% no-repeat;
        }
      }

      span {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        position: relative;
        padding: 5px 0 0 22px;
        margin-left: 28px;

        &::before {
          content: "";
          width: 1px;
          height: 42px;
          display: block;
          background-color: #eee;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }

        small {
          width: 100%;
          display: block;
          font-size: 0.7em;
        }
      }
    }

    .group-btn {
      height: 100%;
      display: flex;
      align-items: center;
      position: absolute;
      right: 0;
      top: 0;

      .btn-search {
        max-width: 18px;
        height: 20px;
        position: absolute;
        right: 146px;
        top: 37px;
        padding-top: 2px;
        padding-left: 20px;
        font-size: 22px;
        font-family: "db_ozone_x-bold";
        color: #000;
        white-space: nowrap;
        transition: 0.25s $ease-out-back;
        overflow: hidden;

        &::before {
          position: absolute;
          left: 0;
          top: 2px;
          font-size: 18px;
          color: $orange;
        }

        .desktop &:hover {
          max-width: 100%;
          padding-left: 32px;
        }
      }

      .hamburger {
        width: 123px;
        height: 24px;
        position: relative;
        margin-left: 22px;

        &::before {
          content: "";
          width: 1px;
          height: 42px;
          display: block;
          background-color: #eee;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }

        i {
          height: 23px;
          display: block;
          position: absolute;
          left: 22px;
          top: 0;
          font-size: 24px;
          color: $orange;
          transition: 0.15s ease;
        }
        .icon-hamburger-open {
          transform: scale(0);
        }
        .open-menu & .icon-hamburger {
          transform: scale(0);
        }
        .open-menu & .icon-hamburger-open {
          transform: scale(1);
        }

        span {
          display: block;
          position: absolute;
          left: 64px;
          top: 2px;
          font-size: 22px;
          transition: 0.15s ease;
        }
        span.open {
          transform: translateX(-10px);
          opacity: 0;
        }
        .open-menu & span {
          transform: translateX(-10px);
          opacity: 0;
        }
        .open-menu & span.open {
          transform: translateX(0);
          opacity: 1;
        }
      }
    }

    .box-mainmenu {
      width: 100vw;
      min-height: calc(100vh - 94px);
      display: flex;
      justify-content: space-between;
      background-color: #fff;
      position: relative;
      transform: translateY(-100%);
      overflow: hidden;
      transition: 0.35s $ease-in-out-expo;

      .img {
        width: calc(100% - 510px);
        position: relative;

        div {
          width: 100%;
          height: 100%;
          background: 50% 0 / cover no-repeat;
          position: absolute;
          left: 0;
          top: 0;
          opacity: 0;
          transform: scale(1.1);
          visibility: hidden;
          transition: 0.25s ease;

          &.show {
            opacity: 1;
            transform: scale(1);
            visibility: visible;
          }
        }
      }

      .mainmenu {
        min-width: 510px;
        background-color: #6e7175;
        position: relative;
        font-size: 30px;
        font-family: "db_ozone_x-medium";
        color: #fff;

        ul {
          width: calc(100% - 176px);
          margin: 60px auto;

          li {
            text-transform: uppercase;
          }

          li > a {
            width: 100%;
            display: block;
            position: relative;
            padding: 15px 0;

            &::before {
              content: "\67";
              position: absolute;
              left: -45px;
              top: 16px;
              font-size: 24px !important;
              // @extend .add-font-icon;
              color: $orange;
              opacity: 0;
              visibility: hidden;
              transition: 0.5s $ease-out-back;
            }

            &::after {
              content: "";
              width: 30px;
              height: 2px;
              display: none;
              background-color: #c2c2c2;
              position: absolute;
              right: 20px;
              top: 50%;
              margin-top: -1px;
              transition: 0.25s ease;
            }
          }
          .desktop & a:hover,
          a.active {
            color: $orange;

            &::after {
              width: 50px;
              background-color: #fff;
              right: 0;
            }
          }
        }

        .submenu {
          width: 260px;
          height: 100%;
          background-color: #898989;
          position: absolute;
          left: -240px;
          top: 0;
          padding: 140px 25px 0 25px;
          font-size: 2.4rem;
          opacity: 0;
          visibility: hidden;
          transition: 0.25s ease;

          a {
            display: block;
          }
          a + a {
            margin-top: 40px;
          }
        }

        .show-sub {
          & > a {
            color: $orange;

            &::before {
              left: -55px;
              opacity: 1;
              visibility: visible;
            }
            &::after {
              width: 50px;
              background-color: #fff;
              right: 0;
            }
          }

          .submenu {
            left: -260px;
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }

    .section-search {
      width: 100vw;
      height: calc(100vh - 94px);
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #6e7175;
      position: absolute;
      left: 0;
      top: 94px;
      transform: translateY(-100%);
      overflow: hidden;
      transition: 0.35s $ease-in-out-expo;

      .form-style {
        width: 920px;
        max-width: calc(100% - 30px);
      }

      .inputtext-style {
        background: none;
        border: none;
        border-bottom: 1px solid #fff;
        border-radius: 0;
        padding: 0;
        text-align: center;
        font-size: 60px;
        line-height: 1.6;
        font-family: "db_ozone_x-medium";
        color: #fff;
      }

      .inputtext-style::-webkit-input-placeholder {
        color: #fff;
      }

      .inputtext-style::-moz-placeholder {
        color: #fff;
      }

      .inputtext-style:-ms-input-placeholder {
        color: #fff;
      }

      .inputtext-style:-moz-placeholder {
        color: #fff;
      }

      .textarea-style::-webkit-input-placeholder {
        color: #fff;
      }

      .textarea-style::-moz-placeholder {
        color: #fff;
      }

      .textarea-style:-ms-input-placeholder {
        color: #fff;
      }

      .textarea-style:-moz-placeholder {
        color: #fff;
      }
    }
  }

  .open-menu {
    overflow: hidden;

    #header {
      height: 100%;
      overflow: hidden;
      overflow-y: auto;

      .box-mainmenu {
        transform: translateY(0);
      }
    }
  }

  .open-search {
    #header {
      .section-search {
        transform: translateY(0);
      }
    }
  }
  // End Header

  // Footer
  #footer {
    background-color: #333;
    padding: 0;
    margin-top: 95px;
    color: #fff;
    z-index: 1313;

    .page-center {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 40px 0;
    }

    .menu-footer ul {
      display: flex;
      align-items: center;
      font-size: 22px;

      li + li {
        margin-left: 38px;
      }

      .active {
        color: $orange;
      }
    }

    .group-social {
      display: flex;
      align-items: center;

      a {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $orange;
        border-radius: 50%;
        transition: 0.15s ease;

        &::before {
          display: block;
          font-size: 20px;
        }

        .desktop &:hover {
          background-color: $orange;
          color: #333;
        }
      }
      a + a {
        margin-left: 12px;
      }
    }

    .section-bottom {
      position: relative;

      &::before {
        content: "";
        width: 200vw;
        height: 1px;
        background-color: rgba(255, 255, 255, 0.5);
        position: absolute;
        left: -100vw;
        top: 0;
      }

      p {
        font-size: 18px;
      }

      p + p {
        margin: 8px 0 0;
      }
    }
  }
  // End Footer

  @media only screen and (min-width: 1400px) {
    #header {
      .box-mainmenu {
        .mainmenu {
          ul {
            margin: 120px auto;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    #header {
      .page-center {
        width: 100%;
      }
    }
  }

  @media only screen and (max-width: 1023px) {
    #header {
      h1 {
        font-size: 2.4rem;

        a::before {
          width: 84px;
          height: 60px;
        }
      }

      .img {
        display: none;
      }

      .box-mainmenu {
        .mainmenu {
          width: 100%;
          min-width: inherit;

          li > a::before {
            display: none;
          }

          .submenu {
            width: 100%;
            display: none;
            position: relative;
            left: 0;
            top: 0;
            padding: 15px;
            margin: 10px 0 40px;

            a + a {
              margin-top: 20px;
            }
          }

          .show-sub .submenu {
            display: block;
            left: 0;
          }
        }
      }
    }

    #footer {
      .page-center {
        flex-direction: column;
        text-align: center;
      }

      .menu-footer {
        width: 80%;

        ul {
          flex-wrap: wrap;
          justify-content: center;

          li {
            margin: 5px 0;
          }
        }
      }

      .group-social {
        margin-top: 20px;
      }

      .copyright {
        width: 100%;
        margin-top: 8px;
      }
    }
  }

  @media only screen and (max-width: 767px) {
    #header {
      h1 {
        span {
          display: none;
        }
      }

      .menu-top {
        a + a {
          margin-left: 20px;
        }
      }

      .box-mainmenu {
        .mainmenu {
          ul {
            width: calc(100% - 30px);
            margin: 15px auto;
          }
        }
      }

      .section-search {
        .inputtext-style {
          font-size: 40px;
          line-height: 1;
        }
      }
    }

    #footer {
      .menu-footer {
        width: 85%;

        ul {
          flex-wrap: wrap;
          justify-content: center;

          li {
            margin: 5px 0;
          }
        }
      }
    }
  }

  @media screen and (max-width: 479px) {
    #header {
      h1 {
        a::before {
          width: 74px;
          height: 53px;
        }
      }

      .menu-top {
        max-width: calc(100% - 122px);
        margin: 0;
        font-size: 18px;

        a + a {
          margin-left: 15px;
        }
      }

      .text-size,
      .language {
        a {
          width: 26px;
          height: 26px;
          font-size: 16px;
        }
      }

      .language {
        margin-left: 10px;
      }

      .box-mainmenu {
        .mainmenu {
          font-size: 20px;

          .submenu {
            font-size: 1.8rem;
          }
        }
      }

      .group-btn {
        .btn-search {
          right: 68px;
        }

        .hamburger {
          width: 48px;

          span {
            display: none;
          }
        }
      }
    }

    #footer {
      .menu-footer ul {
        flex-direction: column;
        font-size: 20px;

        li + li {
          margin: 15px 0 0;
        }
      }

      .group-social {
        margin-top: 30px;
      }

      .section-bottom {
        font-size: 16px;

        p {
          font-size: 16px;
        }
      }
    }
  }
}
