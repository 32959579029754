.about-us-page {
  .old-theme {
    #footer {
      margin-top: 0;
    }

    .content-overview {
      .section-navigation {
        height: 800px;
        background: url("../img/about-us/bg.jpg") 50% 50% / cover no-repeat;
        padding: 62px 0;

        .title {
          width: 570px;
          max-width: 100%;
          position: relative;
          padding-bottom: 40px;
          margin: 0 auto;
          font-size: 26px;
          color: #9e9a9a;
          text-align: center;

          .text-large & {
            font-size: 28px;
          }

          &::before,
          &::after {
            content: "";
            width: 44px;
            height: 9px;
            display: block;
            background-color: #282828;
            position: absolute;
            left: 50%;
            bottom: 0;
            margin-left: -43px;
          }

          &::after {
            background-color: #fff;
            margin-left: 1px;
          }
        }

        .group-nav {
          width: 940px;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          margin: 50px auto 0;

          a {
            width: 235px;
            height: 240px;
            display: block;
            position: relative;
            text-align: center;

            &::before {
              content: "";
              width: 100%;
              height: 100%;
              display: block;
              background-color: rgba(0, 0, 0, 0.5);
              position: absolute;
              left: 0;
              top: 0;
            }

            span {
              width: 100%;
              display: block;
              position: absolute;
              left: 0;
              bottom: 26px;
              font-size: 30px;
              line-height: 0.8;
              font-family: "db_ozone_x-bold";
              color: $white;

              .text-large & {
                font-size: 32px;
              }
            }
          }
        }
      }

      .list-number {
        counter-reset: number;

        li + li {
          margin-top: 16px;
        }

        & > li {
          padding: 65px 0 0;

          &::before {
            content: counter(number, decimal-leading-zero);
            counter-increment: number;
            width: 55px;
            height: 55px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $orange;
            border-radius: 50%;
            font-size: 37px;
            font-family: "db_ozone_x-bold";
            color: $white;

            .text-large & {
              font-size: 39px;
            }
          }
        }
      }

      .nav-page {
        width: 100%;
        height: 65px;
        background-color: #2b2b2b;
        position: fixed;
        left: 0;
        bottom: -65px;
        color: $white;
        transition: 0.25s ease;
        z-index: 1313;

        &.show-nav {
          bottom: 0;
        }

        .page-center {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        a {
          display: block;
          position: relative;
          padding-left: 20px;
          font-size: 18px;

          .text-large & {
            font-size: 20px;
          }

          &::before {
            content: "";
            width: 5px;
            height: 5px;
            display: block;
            background-color: $white;
            border-radius: 50%;
            position: absolute;
            left: 0;
            top: 10px;
          }
        }

        a + a {
          margin-left: 22px;
        }

        a.active {
          color: $orange;

          &::before {
            background-color: $orange;
          }
        }
      }
    }

    html & .jspVerticalBar {
      &::before,
      &::after {
        background-color: #fff !important;
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .about-us-page {
    .old-theme .content-overview {
      .section-navigation {
        height: auto;

        .group-nav {
          width: 100%;

          a {
            width: 25%;
            height: auto;
          }
        }
      }

      .nav-page {
        display: none !important;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .about-us-page {
    .old-theme .content-overview {
      .section-navigation {
        .group-nav {
          a {
            width: 33.33%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 479px) {
  .about-us-page {
    .old-theme .content-overview {
      .section-navigation {
        .group-nav {
          a {
            width: 50%;

            span {
              left: 10px;
              bottom: 10px;
              font-size: 22px;

              .text-large & {
                font-size: 22px;
              }
            }
          }
        }
      }
    }
  }
}

.-overview #main-content {
  padding-bottom: 0;
}
