.management-page {
  #footer {
    margin-top: -108px;
  }

  .banner-page {
    .box-text {
      min-height: 350px;
    }

    .box-text h1 {
      font-size: 72px;

      .text-large & {
        font-size: 74px;
      }
    }
  }

  .group-link {
    width: 400px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-30%, -30%);
    text-align: left;

    a {
      min-height: 32px;
      display: block;
      position: relative;
      padding-left: 62px;
      font-size: 36px;
      color: $white;

      .text-large & {
        font-size: 38px;
      }

      &::before {
        content: "\68";
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $white;
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 0;
        @extend .add-font-icon;
        font-size: 20px;
        transition: 0.25s ease;
      }

      .desktop &:hover::before {
        border-width: 3px;
      }
    }

    a + a {
      margin-top: 30px;
    }
  }
}

@media screen and (min-width: 1400px) {
  .banner-page {
    .group-link {
      transform: translate(-50%, -30%) scale(1.2);
    }
  }
}

@media screen and (max-width: 1024px) {
  .management-page {
    .group-link {
      left: 84px;
      transform: translate(0, -20%);
    }
  }
}

@media screen and (max-width: 1023px) {
  .management-page {
    #container {
      min-height: auto;
    }

    .box-text {
      min-height: auto !important;

      h1 {
        margin-bottom: 0;
      }
    }

    .group-link {
      width: 100%;
      background-color: $orange;
      position: relative;
      left: 0;
      top: 0;
      padding: 0 20px 110px;
      transform: translate(0, 0);
    }
  }
}

@media screen and (max-width: 767px) {
  .management-page {
    .group-link {
      padding-bottom: 60px;
    }
  }
}

@media screen and (max-width: 479px) {
  .management-page {
    .box-text {
      h1 {
        font-size: 50px !important;

        .text-large & {
          font-size: 52px !important;
        }
      }
    }

    .group-link {
      a {
        padding: 1px 0 0 35px;
        font-size: 33px;

        .text-large & {
          font-size: 35px;
        }

        &::before {
          width: 22px;
          height: 22px;
          font-size: 14px;
        }
      }
    }
  }
}
