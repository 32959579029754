.corporate-materials-page {
  .content-corporate-materials {
    .content-booklet {
      margin-bottom: -95px;

      background: #2f3844 url('../img/corporate-materials/bg-booklet.jpg') 50% 0 no-repeat;
      padding: 58px 0 75px;
      position: relative;

      &::before {
        content: '';
        width: 1109px;
        height: 82px;
        display: block;
        background: url('../img/corporate-materials/booklet-obj-01.png') 0 0/100% no-repeat;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-65%);
      }

      .page-center {
        display: flex;
        justify-content: space-between;
      }

      .detail {
        padding-top: 74px;
        font-size: 2.4rem;
        color: #fff;

        h2 {
          margin-bottom: 40px;
          font-size:  2em;
          line-height: 0.8;
          font-family: 'db_ozone_x-bold';
          color: $orange;
        }

        p {
          line-height: 1.3;
        }
      }

      .link {
        width: 605px;
        min-width: 605px;
        position: relative;
        margin: 0 56px 0 100px;

        &::before {
          content: '';
          width: 100%;
          height: calc(100% - 134px);
          display: block;
          background-color: $orange;
          position: absolute;
          right: -56px;
          bottom: 0;
        }

        .img {
          display: block;
          position: relative;

          img {
            position: relative;
          }
        }

        .text {
          min-height: 64px;
          display: flex;
          align-items: center;
          position: relative;
          padding: 5px 0 0 155px;
          font-size: 2rem;
          font-family: 'db_ozone_x-medium';
          color: #fff;
          z-index: 13;

          &::before {
            content: '';
            width: 31px;
            min-width: 31px;
            height: 35px;
            display: block;
            background: url('../img/icons/file-pdf-white.png') no-repeat;
            position: relative;
            top: -2px;
            margin-right: 16px;
          }
        }
      }
    }



    .content-newsletter {
      .page-center {
        display: flex;
        flex-wrap: wrap;
      }

      .list-newsletter {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin: 70px 0 0 -15px;

        .item {
          width: calc(33.33% - 15px);
          margin: 15px 0 0 15px;
          box-shadow: 0 6px 16px rgba(0, 0, 0, 0.17);

          a {
            width: 100%;
            height: 100%;
            display: block;
            position: relative;

            &::before {
              content: '';
              width: 100%;
              height: 185px;
              display: block;
              background: url('../img/areas-of-work/overlay.png') repeat-x;
              position: absolute;
              left: 0;
              bottom: 0;
              z-index: 13;
            }

            .text {
              width: calc(100% - 40px);
              height: 50px;
              display: flex;
              align-items: center;
              position: absolute;
              left: 20px;
              bottom: 40px;
              padding-right: 75px;
              font-size: 2.5rem;
              font-family: 'db_ozone_x-bold';
              color: #fff;
              z-index: 13;

              &::after {
                content: '\68';
                width: 38px;
                height: 38px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $orange;
                border-radius: 50%;
                position: absolute;
                right: 15px;
                top: 50%;
                margin-top: -19px;
                @extend .add-font-icon;
              }
            }
          }
        }
      }
    }
  }
}



.content-corporate-materials .owl-stage {
  min-width: 100% !important;
}










@media screen and (max-width:1024px) {
  .corporate-materials-page {
    .content-corporate-materials {
      .content-booklet {
        .link {
          width: 500px;
          min-width: 500px;
          margin-right: 0;
          margin-left: 50px;

          &::before {
            right: 0;
          }

          .text {
            padding-left: 20px;
          }
        }
      }

      .content-newsletter {
        .list-newsletter {
          .item {
            a {
              .text {
                height: auto;
                min-height: 40px;

                br {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}





@media screen and (max-width:1023px) {
  .corporate-materials-page {
    .content-corporate-materials {
      .content-booklet {
        .page-center {
          flex-wrap: wrap;
        }

        .detail {
          padding-top: 0;
        }

        .link {
          width: 100%;
          min-width: inherit;
          margin: 40px 0 0;

          img {
            width: 100%;
          }
        }
      }

      .content-newsletter {
        .list-newsletter {
          .item {
            a {
              .text {
                width: 100%;
                left: 0;
                bottom: 20px;
                padding-left: 20px;
                font-size: 2rem;
              }
            }
          }
        }
      }
    }
  }
}





@media screen and (max-width:767px) {
  .corporate-materials-page {
    .content-corporate-materials {
      .content-newsletter {
        .list-newsletter {
          width: auto;

          .item {
            width: calc(50% - 15px);
          }
        }
      }
    }
  }
}





@media screen and (max-width:479px) {
  .corporate-materials-page {
    .content-corporate-materials {
      .content-booklet {
        margin-top: 40px;

        .detail {
          font-size: 2rem;
        }

        .link {
          a {
            .text {
              padding-right: 10px;
              padding-left: 10px;
              font-size: 1.8rem;
            }
          }
        }
      }

      .content-newsletter {
        .list-newsletter {
          margin-left: 0;
          margin-top: 40px;

          .item {
            width: 100%;
            margin-left: 0;
          }
        }
      }
    }
  }
}