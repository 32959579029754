html .fancybox-overlay {
  background: rgba(0, 0, 0, 0.8);
  z-index: 131313;
}

html .fancybox-wrap {
  z-index: 13131313;

  .fancybox-skin {
    background: none;
    border-radius: 0;

    .fancybox-inner {
      overflow: visible !important;
    }
  }
}

html .fancybox-close {
  width: 28px;
  height: 28px;
  display: block;
  background: none;
  border: 2px solid $black;
  border-radius: 50%;
  right: 26px;
  top: 16px;
  transition: 0.15s ease;

  &::before {
    content: "\6b";
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @extend .add-font-icon;
    font-size: 24px;
    color: $black;
  }
}

html.desktop .fancybox-close:hover {
  transform: scale(1.2);
}

html .fancybox-nav {
  span {
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none !important;
    border: 1px solid #fff;
    border-radius: 50%;
    margin-top: -14px;
    color: #fff !important;

    &::before {
      content: "";
      display: block;
    }
  }

  &.fancybox-prev span {
    left: 10px;

    &::before {
      content: "\67";
      @extend .add-font-icon;
    }
  }

  &.fancybox-next span {
    right: 10px;

    &::before {
      content: "\68";
      @extend .add-font-icon;
    }
  }
}

@media only screen and (max-width: 479px) {
  html .fancybox-close {
    right: 10px;
    top: 10px;
  }
}
