.our-partners-page {
  .tabs {
    .tab {
      display: none;
    }

    .tab.active {
      display: block;
    }


    .section+.section {
      margin-top: 66px;
    }
  }

  .nav-tab {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 72px;

    a {
      width: 252px;
      height: 72px;
      background-color: #dee0de;

      &.active {
        background-color: $orange;
      }
    }
  }

  .list-partners {
    display: flex;
    flex-wrap: wrap;

    .item {
      width: 20%;
      border: 1px solid #eceff1;
      margin: -1px 0 0 -1px;
    }
  }
}










@media only screen and (max-width:767px) {
  .our-partners-page {
    .list-partners {
      .item {
        width: 25%;
      }
    }
  }
}





@media only screen and (max-width:767px) {
  .our-partners-page {
    .list-partners {
      .item {
        width: 33.33%;
      }
    }

    .nav-tab {
      width: 100%;
      margin-top: 20px;

      a {
        min-width: inherit;
        width: 50%;
        height: 50px;
        padding: 0;
        font-size: 1.8rem;
      }
    }
  }
}