.old-theme {
  .form-style {
    .items {
      margin-top: -32px;
      overflow: hidden;

      .item {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 32px;

        &::after {
          @extend .clear;
        }

        .col {
          width: 100%;
        }

        &.col2 {
          .col {
            width: 50%;
          }

          label {
            margin-bottom: 0;
          }
        }

        &.col3 {
          .col {
            width: 33.33%;
          }

          label {
            margin-bottom: 0;
          }
        }
      }
    }

    label {
      display: inline-block;
      margin-bottom: 18px;
      font-size: 20px;
      font-family: "db_ozone_x-medium";
      color: #333;

      .text-large & {
        font-size: 22px;
      }
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type="number"] {
      -moz-appearance: textfield;
    }

    .inputtext-style,
    .textarea-style,
    .select-style {
      width: 100%;
      display: block;
      background: $white;
      border: 1px solid #7d7d7d;
      border-radius: 0;
      padding: 12px 30px;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      font-size: 20px;

      .text-large & {
        font-size: 22px;
      }
    }

    .inputtext-style::-webkit-input-placeholder {
      color: #817f7f;
    }

    .inputtext-style::-moz-placeholder {
      color: #817f7f;
    }

    .inputtext-style:-ms-input-placeholder {
      color: #817f7f;
    }

    .inputtext-style:-moz-placeholder {
      color: #817f7f;
    }

    .textarea-style::-webkit-input-placeholder {
      color: #817f7f;
    }

    .textarea-style::-moz-placeholder {
      color: #817f7f;
    }

    .textarea-style:-ms-input-placeholder {
      color: #817f7f;
    }

    .textarea-style:-moz-placeholder {
      color: #817f7f;
    }

    .inputtext-style::-ms-clear {
      display: none;
    }

    .textarea-style {
      height: 148px;
      resize: none;
    }

    .select-style {
      cursor: pointer;
    }

    .select-box {
      position: relative;

      &::before {
        content: "\71";
        width: 46px;
        height: 46px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 0;
        bottom: 0;
        @extend .add-font-icon;
        pointer-events: none;
      }

      .ie &:before {
        display: none;
      }
    }

    .checkbox-style {
      input[type="checkbox"] {
        display: none;
      }

      label {
        position: relative;
        padding: 0 0 0 32px;
        line-height: 1.3;
        font-family: "db_ozone_x";

        &::before {
          content: "";
          width: 20px;
          height: 20px;
          display: block;
          background-color: #fff;
          border: 1px solid #7d7d7d;
          position: absolute;
          left: 0;
          top: 0;
        }

        &::after {
          content: "\6c";
          display: block;
          position: absolute;
          left: 2px;
          top: -1px;
          @extend .add-font-icon;
          font-size: 18px;
          color: #7d7d7d;
          transform: scale(0);
          transition: 0.2s $ease-out-back;
        }
      }

      input[type="checkbox"]:checked + label::after {
        transform: scale(1);
      }
    }

    .radio-style {
      input[type="radio"] {
        display: none;
      }

      label {
        position: relative;
        padding: 0 0 0 32px;
        line-height: 1.3;
        font-family: "db_ozone_x";

        &::before {
          content: "";
          width: 20px;
          height: 20px;
          display: block;
          background-color: #fff;
          border: 1px solid #7d7d7d;
          border-radius: 50%;
          position: absolute;
          left: 0;
          top: 0;
        }

        &::after {
          content: "\6c";
          display: block;
          position: absolute;
          left: 2px;
          top: -1px;
          @extend .add-font-icon;
          font-size: 18px;
          color: #7d7d7d;
          transform: scale(0);
          transition: 0.2s $ease-out-back;
        }
      }

      input[type="radio"]:checked + label::after {
        transform: scale(1);
      }
    }

    .error {
      .inputtext-style,
      .textarea-style,
      .select-style {
        border-color: red !important;
      }

      .checkbox-style,
      &.checkbox-style {
        label::before {
          border-color: red !important;
        }
      }

      .radio-style,
      &.radio-style {
        label::before {
          border-color: red !important;
        }
      }
    }

    .text-validate-error {
      display: none;
      margin-top: 5px;
      font-size: 0.9em !important;
      font-family: "db_ozone_x" !important;
      color: red !important;
    }

    .error .text-validate-error {
      display: block;
    }
  }

  .form-style.design-border-bottom {
    .col {
      min-height: 45px;
      position: relative;
    }

    label {
      position: absolute;
      left: 0;
      top: 16px;
      margin: 0;
      z-index: 13;
      transition: 0.25s ease;
    }

    .inputtext-style:focus + label,
    .inputtext-style.not-empty + label,
    .textarea-style:focus + label,
    .textarea-style.not-empty + label,
    .select-style:focus + label,
    .select-style.not-empty + label {
      top: 0;
    }

    .-select label {
      pointer-events: none;
    }

    .inputtext-style,
    .textarea-style,
    .select-style {
      border: none;
      border-bottom: 1px solid #ebebeb;
      padding: 0;
      margin-top: 24px;
    }
  }

  .frm_purchase {
    display: flex;
    margin-bottom: 48px;

    .box-left,
    .box-right {
      float: none !important;
    }

    .box-right {
      display: flex;
    }
  }
}

@media screen and (max-width: 479px) {
  .old-theme .form-style {
    .items {
      .item {
        .col {
          width: 100% !important;
        }
      }

      .item.col2,
      .item.col3 {
        .col {
          margin-top: 10px;
        }
      }
    }
  }
}
