$black: #000;
$white: #fff;
$orange: #ed7725;
$orange-60: rgba(237, 119, 37, 0.6);
$dark-orange: #e37835;
$dark-blue: #002a64;
$dark-gray: #777779;
$gray: #b3b3b3;
$light-gray: #ccc;
$dark: #2c2c2c;

$max-width-responsive: calc(100% - 30px);


$ease-in-expo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
$ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);
$ease-in-out-expo: cubic-bezier(1, 0, 0, 1);

$ease-in-back: cubic-bezier(0.6, -0.28, 0.735, 0.045);
$ease-out-back: cubic-bezier(0.175, 0.885, 0.32, 1.275);
$ease-in-out-back: cubic-bezier(0.68, -0.55, 0.265, 1.55);