.board-of-directors-page {
  .content-board-of-directors {
    padding-top: 0;
  }

  .slide-group-btn {
    width: 100%;
    border-bottom: 1px solid #dce1e5;
    margin-bottom: 90px;
    font-size: 21px;

    a {
      height: 110px;
      display: block;
      position: relative;
      padding: 40px 5px 90px;
      color: #000;
      text-align: center;
      white-space: nowrap;

      &::after {
        content: "";
        width: 100%;
        height: 3px;
        display: block;
        background-color: $orange;
        position: absolute;
        left: 0;
        bottom: -1px;
        transform: scale(0);
        transition: 0.25s ease;
      }

      &.active {
        font-family: "db_ozone_x-bold";

        &.active::after {
          transform: scale(1);
        }
      }
    }

    .owl-nav {
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: -40px;

      & > div {
        width: 30px;
        height: 30px;
        border: 1px solid #000;
        border-radius: 50%;
        position: relative;
        left: 0;
        top: 0;
      }

      & > div + div {
        margin-left: 5px;
      }
    }

    .owl-nav.disabled {
      display: none !important;
    }
  }

  .heading {
    h2 {
      padding-bottom: 40px;
      line-height: 0.9;
    }
  }

  .list-board {
    .row {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    .row + .row {
      border-top: 1px dashed $light-gray;
      padding-top: 32px;
      margin-top: 32px;
    }

    .item {
      width: 170px;
      margin: 10px 30px;
      text-align: center;

      a {
        display: inline-block;
        position: relative;

        &::before {
          width: 50px;
          height: 50px;
          display: none;
          align-items: center;
          justify-content: center;
          background-color: $white;
          border-radius: 50%;
          position: absolute;
          right: 0;
          top: 110px;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16);
          font-size: 28px;
          color: $orange;
          z-index: 13;
        }

        &:not([href]) {
          cursor: default;
          color: #000;
        }
      }

      .img {
        width: 170px;
        height: 170px;
        display: block;
        border-radius: 50%;
        position: relative;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          object-fit: cover;
        }
      }

      .detail {
        width: 100%;
        display: block;
        position: relative;
        margin: 45px auto 0;
        text-align: left;
        font-size: 24px;
        font-family: "db_ozone_x-bold";

        .text-large & {
          font-size: 26px;
        }

        &::before {
          content: "";
          width: 7px;
          height: 7px;
          display: none;
          border: 1px solid $black;
          border-radius: 50%;
          position: absolute;
          left: -12px;
          top: 2px;
        }

        small {
          display: block;
          font-size: 0.8em;
          font-family: "db_ozone_x";
          color: #8a8a8a;
        }
      }
    }

    .row:first-child .item {
      width: 100%;

      a {
        width: 224px;
      }

      a::before {
        width: 68px;
        height: 68px;
        top: 145px;
        font-size: 34px;
      }

      .img {
        width: 224px;
        height: 224px;
      }

      .detail {
        width: 100%;
        font-size: 26px;

        .text-large & {
          font-size: 28px;
        }

        &::before {
          width: 8px;
          height: 8px;
        }
      }
    }
  }

  .remark {
    font-size: 22px;

    .text-large & {
      font-size: 24px;
    }

    li {
      font-size: 1em;
    }

    li + li {
      margin-top: 15px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .board-of-directors-page {
    .heading {
      font-size: 50px;

      .text-large & {
        font-size: 52px;
      }
    }

    .list-board {
      .row {
        flex-wrap: wrap;
      }

      .item {
        margin-left: 20px;
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .board-of-directors-page {
    .heading {
      font-size: 40px;

      .text-large & {
        font-size: 42px;
      }
    }

    .list-board {
      .row + .row {
        margin-top: 80px;
      }

      .item {
        margin: 35px 35px 0;
      }

      .item:first-child {
        margin-left: 35px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .board-of-directors-page {
    .heading {
      br {
        display: none !important;
      }
    }
  }
}

@media screen and (max-width: 479px) {
  .board-of-directors-page {
    .heading {
      font-size: 32px;

      .text-large & {
        font-size: 34px;
      }

      h2 {
        padding-bottom: 30px;
        line-height: 1;
      }
    }

    .slide-group-btn {
      font-size: 17px;
    }
  }
}
