.popup-old-theme {
  max-width: calc(100vw - 40px);
  margin: 20px 0;

  * {
    box-sizing: border-box;
  }

  .inner {
    width: 680px;
    background-color: #fff;
    padding: 76px 36px 60px;

    h2 {
      margin-bottom: 76px;
      font-size: 32px;
      font-family: "db_ozone_x-bold";
      text-align: center;

      .text-large & {
        font-size: 34px;
      }
    }
  }

  &.-intro {
    margin: 50px 0;

    .inner {
      width: auto;
      padding: 0;
    }
  }

  &.-subscribe {
    .col3 + .col3 {
      margin-top: 10px;
    }

    .item:last-child {
      margin-top: 10px;
    }

    .btn-style {
      width: 100%;
    }
  }

  &.-job-apply {
    .inner {
      width: 1100px;
      max-width: 100%;
      overflow: hidden;
    }

    .items {
      overflow: visible;
    }

    .item:after {
      display: none !important;
    }

    label {
      margin-bottom: 18px !important;

      .remark {
        font-size: 18px;
        color: #817f7f;

        .text-large & {
          font-size: 20px;
        }
      }
    }

    .col2 {
      justify-content: space-between;

      & > .col {
        width: calc(50% - 14px) !important;
      }
    }

    .sub-col {
      display: flex;
      justify-content: space-between;

      .col {
        width: calc(50% - 7px) !important;
      }

      &.col3 .col {
        width: calc(33.33% - 7px) !important;
      }
    }

    .title-name {
      .col:nth-of-type(1) {
        width: calc(30% - 7px) !important;
      }
      .col:nth-of-type(2) {
        width: calc(70% - 7px) !important;
      }
    }

    .attach-file {
      .col > div + div {
        margin-left: 12px;
      }

      input {
        position: absolute;
        left: -9999px;
      }

      .btn-style {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-bottom: 0 !important;
        text-align: center;
        overflow: hidden;

        &:before {
          content: "";
          display: block;
          background: 50% 50%/100% no-repeat;
          margin-right: 14px;
        }

        &.-letter:before {
          width: 17px;
          height: 23px;
          background-image: url("../img/icons/letter.svg");
        }
        &.-resume:before {
          width: 20px;
          height: 24px;
          background-image: url("../img/icons/resume.svg");
        }
      }

      .icon-android-done {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $white;
        border-radius: 50%;
        position: absolute;
        left: 50%;
        top: 50%;
        margin: -15px 0 0 -15px;
        transform: translateY(60px);
        opacity: 0;
        color: $orange;
      }

      .is-change .icon-android-done {
        animation: animateChange 2s;
      }
    }

    .item:last-child {
      position: relative;
      padding-top: 40px;
      margin-top: 40px;

      &:before {
        content: "";
        width: 200%;
        height: 1px;
        display: block;
        background-color: #ebebeb;
        position: absolute;
        left: -50%;
        top: 0;
      }
    }
  }

  &.-board-of-directors {
    max-width: calc(100vw - 80px);
    margin: 30px 0;

    .inner {
      width: 990px;
      max-width: 100%;
      padding: 0;
    }

    .content {
      display: flex;
    }

    .img {
      min-width: 450px;
    }

    img {
      margin: 0;
    }

    .detail {
      width: 100%;
      padding: 48px 0;

      .title {
        border-bottom: 1px solid #dce1e5;
        padding: 0 40px 35px 40px;
        margin-bottom: 35px;
      }

      .desription {
        padding: 0 40px;
        font-size: 22px;

        .text-large & {
          font-size: 24px;
        }

        h3 {
          font-size: 1.2em;
        }

        strong {
          font-family: "db_ozone_x-medium";
        }
      }

      .scroll {
        height: 356px;

        .jspVerticalBar {
          width: 8px;

          &::before,
          &::after {
            background-color: #fff;
            left: -1px;
          }
        }
      }

      .editor {
        padding-right: 40px;

        ul {
          li::before {
            background-color: $orange;
          }
        }

        h3 {
          font-family: "db_ozone_x-bold";
        }
      }

      h2 {
        position: relative;
        margin: 0;
        font-size: 32px;
        text-align: left;

        .text-large & {
          font-size: 34px;
        }

        &::before {
          content: "";
          width: 10px;
          height: 10px;
          display: none;
          border: 2px solid $orange;
          border-radius: 50%;
          position: absolute;
          left: -22px;
          top: 0;
        }

        small {
          display: block;
          font-size: 0.8em;
          font-family: "db_ozone_x";
          color: #8a8a8a;
        }
      }

      .list-bullet {
        & > li {
          padding-left: 32px;
          line-height: 1.2;
        }

        & > li::before {
          top: 9px;
        }
      }
    }
  }

  .btn-orange {
    min-width: 216px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $dark-orange;
    border: none;
    border-radius: 0;
    padding: 15px 30px;
    margin: 0 auto;
    font-size: 22px;
    color: $white !important;
    transition: 0.25s ease;

    .desktop &:hover {
      background-color: $black;
    }

    &.-orange {
      background-color: $orange;
    }

    &.-dark-gray {
      background-color: $dark-gray;
    }
  }
}

html .popup-intro {
  .fancybox-close {
    border-color: $white;
    right: 0;
    top: -40px;

    &::before {
      color: $white;
    }
  }
}

html .popup-board-of-directors {
  .fancybox-close {
    width: 55px;
    height: 55px;
    background-color: $orange;
    border: none;
    right: -27px;
    top: -27px;

    &::before {
      font-size: 30px;
      color: $white;
    }
  }
}

.popup-gallery {
  .fancybox-close {
    border-color: #fff;
    right: 10px;
    top: 10px;

    &::before {
      color: #fff;
    }
  }
}

.popup-video {
  .inner {
    height: 28.2vw;
    padding: 0;
  }

  .fancybox-close {
    border-color: #fff;
    right: 10px;
    top: 10px;

    &::before {
      color: #fff;
    }
  }
}

@media screen and (max-width: 1023px) {
  .popup-old-theme {
    &.-board-of-directors {
      .content {
        flex-wrap: wrap;
      }

      .img,
      .detail {
        width: 100%;
      }

      .img {
        min-width: inherit;

        img {
          width: 100%;
        }
      }
    }

    &.-job-apply {
      .col2 {
        display: block;

        & > .col {
          width: 100% !important;
        }
        & > .col + .col {
          margin-top: 32px;
        }
      }

      .attach-file {
        .col > div {
          width: 100%;
        }

        .btn-style {
          min-width: 100%;
          white-space: nowrap;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .popup-old-theme {
    &.-subscribe {
      .inner {
        width: 100%;
      }

      .col3 {
        label {
          padding-top: 2px;
          font-size: 15px;

          .text-large & {
            font-size: 17px;
          }
        }
      }
    }

    &.-board-of-directors {
      ul > li::before {
        top: 6px;
      }
    }

    &.-job-apply {
      .attach-file {
        .col {
          flex-wrap: wrap;
        }
        .col > div {
          width: 100%;

          & + div {
            margin: 10px 0 0;
          }
        }
      }

      .btn-style {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 479px) {
  .popup-old-theme {
    &.-board-of-directors {
      max-width: calc(100vw - 40px);

      .detail {
        padding: 30px 0;

        h2 {
          font-size: 28px;

          .text-large & {
            font-size: 30px;
          }
        }

        .title {
          padding: 0 30px 35px;
        }

        .desription {
          padding: 0 20px;
          font-size: 18px;

          .text-large & {
            font-size: 20px;
          }
        }

        ul > li::before {
          top: 4px;
        }
      }
    }

    &.-subscribe {
      .inner {
        padding: 76px 20px 60px;

        h2 {
          margin-bottom: 40px;
        }

        .col3 {
          label {
            font-size: 18px;

            .text-large & {
              font-size: 20px;
            }
          }
        }
        .col3 + .col3 {
          margin-top: 0;
        }
      }
    }

    &.-job-apply {
      .inner {
        padding: 50px 20px 30px;
      }

      .sub-col {
        display: block;

        &.col3 .col {
          width: 100% !important;
        }
      }
      .sub-col .col {
        width: 100% !important;

        & + .col {
          margin-top: 32px;
        }
      }

      .title-name .col:nth-of-type(1),
      .title-name .col:nth-of-type(2) {
        width: 100% !important;
      }
    }
  }

  html .popup-board-of-directors .fancybox-close {
    width: 40px;
    height: 40px;
    right: -18px;
    top: -18px;
  }
}
