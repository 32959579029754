.complaint-page {
  .section-definition {
    background-color: #2f3844;
    position: relative;
    padding: 60px 0 50px;
    color: $white;
    z-index: 1;

    .page-center {
      justify-content: flex-start;
    }

    .heading {
      display: flex;
      align-items: center;
      font-size: 42px;

      .text-large & {
        font-size: 44px;
      }
    }

    .detail {
      width: 710px;
      margin-left: 100px;
      font-size: 22px;
      line-height: 1.6;

      .text-large & {
        font-size: 24px;
      }

      strong {
        font-size: 1.85em;
        line-height: 0.8;
      }

      p + p {
        margin-top: 15px;
      }

      .remark {
        border-top: 1px solid #ccc;
        padding-top: 10px;
        font-size: 19px;
        color: #ccc;

        .text-large & {
          font-size: 21px;
        }
      }

      a {
        color: $orange;
      }

      li::before {
        background-color: $orange;
        top: 14px;
      }
    }
  }

  .section-form-complaint {
    background: url("../img/complaint/bg-form.jpg") 50% 50% / cover no-repeat;
    position: relative;
    padding: 76px 0 114px;

    img[class^="obj-"] {
      position: absolute;
      left: 50%;
      top: 0;
    }

    img.obj-01 {
      margin-left: -820px;
    }

    img.obj-02 {
      top: 150px;
      margin-left: 550px;
    }

    img.obj-03 {
      top: auto;
      bottom: 40px;
      margin-left: -780px;
      z-index: 13;
    }

    img.obj-04 {
      top: auto;
      bottom: 0;
      margin-left: 170px;
    }

    .form-complaint {
      width: 1080px;
      max-width: calc(100% - 30px);
      position: relative;
      padding: 58px 0 0;
      margin: 0 auto;

      &::before {
        content: "";
        width: 100%;
        height: 100%;
        display: block;
        background-color: $white;
        box-shadow: 0 20px 50px rgba(0, 0, 0, 0.38);
        position: absolute;
        left: -15px;
        top: 20px;
        transform: rotate(-8deg);
      }

      &::after {
        content: "";
        width: 100%;
        height: 100%;
        display: block;
        background-color: $white;
        box-shadow: 0 20px 50px rgba(0, 0, 0, 0.38);
        position: absolute;
        left: 0;
        top: 0;
      }

      form {
        display: flex;
        justify-content: space-between;
        position: relative;
        z-index: 13;

        label,
        p {
          font-size: 24px;
          font-family: "db_ozone_x";

          .text-large & {
            font-size: 26px;
          }
        }

        p {
          width: 100%;
        }

        .inputtext-style,
        .textarea-style {
          font-size: 24px;

          .text-large & {
            font-size: 26px;
          }
        }

        .inputtext-style:focus + label,
        .inputtext-style.not-empty + label,
        .textarea-style:focus + label,
        .textarea-style.not-empty + label,
        .select-style:focus + label,
        .select-style.not-empty + label {
          color: #d4d6d6;
        }

        .textarea-style {
          height: 135px;
        }

        .checkbox-style {
          label {
            font-size: 28px;

            .text-large & {
              font-size: 30px;
            }

            &::before {
              width: 22px;
              height: 22px;
              top: 5px;
            }

            &::after {
              left: 1px;
              top: 2px;
              font-size: 22px;

              .text-large & {
                font-size: 24px;
              }
            }
          }
        }

        .radio-style {
          label {
            color: #d4d6d6;

            &::before {
              width: 22px;
              height: 22px;
              border-color: #ccc;
              top: 3px;
            }

            &::after {
              left: 2px;
              top: 3px;
              color: $orange;
            }
          }

          input[type="radio"]:checked + label {
            color: $orange;
          }
        }

        .box-subject {
          justify-content: flex-start;

          .col:nth-of-type(1) {
            width: 100px !important;
          }
        }

        .items:nth-of-type(1) {
          margin: 0 0 0 84px;

          h2 {
            font-size: 48px;
            line-height: 0.8;
            font-family: "db_ozone_x-medium";

            .text-large & {
              font-size: 50px;
            }

            &::before {
              width: 100px;
              height: 3px;
              background-color: $orange;
            }
          }
        }

        .items:nth-of-type(2) {
          width: 600px;
          border-left: 2px solid #efefef;

          .item {
            padding: 0 56px 0 32px;
          }

          .item:last-child {
            padding: 0;
            margin-top: 100px;
          }

          .btn-style {
            width: 100%;
            height: 68px;
            background-color: $black;
          }
        }
      }
    }

    .remark {
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 40px;
      font-size: 22px;
      text-align: center;

      .text-large & {
        font-size: 24px;
      }
    }
  }

  .img-banner {
    margin-top: 0;
  }

  .section-download {
    .page-center {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 30px;
    }

    .remark {
      font-size: 22px;

      .text-large & {
        font-size: 24px;
      }

      li {
        font-size: 1em;
      }

      li + li {
        margin-top: 15px;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .complaint-page {
    .section-definition {
      .detail {
        width: 620px;
      }
    }

    .section-form-complaint {
      .form-complaint {
        form {
          .items:first-of-type {
            margin-left: 30px;
          }

          .items:nth-of-type(2) {
            .item {
              padding: 0 30px 0 30px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .complaint-page {
    .section-definition {
      .detail {
        width: 100%;
        margin: 20px 0 0;
      }
    }

    .section-form-complaint {
      .form-complaint {
        form {
          .items {
            width: 40%;
          }

          .items:first-child {
            h2 {
              font-size: 40px;

              .text-large & {
                font-size: 42px;
              }
            }
          }

          .items:nth-of-type(2) {
            width: 60%;
            margin-left: 30px;
          }
        }
      }
    }

    .banner-page .box-text .btn-style {
      background-color: $black !important;
    }
  }
}

@media screen and (max-width: 767px) {
  .complaint-page {
    .banner-page {
      a {
        background-color: #000 !important;
      }
    }

    .section-form-complaint {
      .form-complaint {
        form {
          flex-wrap: wrap;

          .items {
            width: 100%;

            .item {
              padding: 0 30px 0 0;
            }

            .checkbot {
              position: absolute;
              left: 0;
              bottom: 130px;
              padding-left: 30px;
            }
          }

          .items:first-child {
            margin: 0;

            h2 {
              margin: 0 30px;
            }
          }

          .items:nth-of-type(2) {
            width: 100%;
            border: none;
            margin: 0 0 0;

            .item:last-child {
              margin-top: 150px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 479px) {
  .complaint-page {
    .section-form-complaint {
      .form-complaint {
        form {
          .items:first-child {
            h2 {
              font-size: 32px;

              .text-large & {
                font-size: 34px;
              }
            }
          }

          .box-subject {
            .col {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
