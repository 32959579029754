.home-page {
  .section.-highlight-banner {
    width: 100%;
    height: calc(100vh - 136px);
    overflow: hidden;

    .owl-carousel,
    .owl-stage-outer,
    .item,
    .img {
      height: calc(100vh - 180px);
    }

    .slide-highlight-banner {
      height: 100%;
      position: relative;

      .owl-nav {
        display: none !important;
      }
    }

    .item {
      position: relative;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      margin: 0;
    }

    .box-text {
      width: 600px;
      display: block;
      background-color: rgba(0,0,0,0.5);
      position: absolute;
      left: 0;
      bottom: 0;
      padding: 72px;
      font-size: 5rem;
      color: $white;

      &::before,
      &::after {
        content: '';
        width: 35px;
        height: 35px;
        display: block;
        background-color: #d7d7d7;
        position: absolute;
        left: 0;
        top: -70px;
      }
      &::after {
        background-color: #e58345;
        left: 35px;
        top: -35px;
      }

      h2 {
        position: relative;
        font-size: 1em;
        line-height: 1em;
        font-family: 'db_ozone_x-bold';
        text-transform: uppercase;

        &::before {
          content: '';
          width: 50px;
          height: 12px;
          display: block;
          background-color: $orange;
          position: absolute;
          left: -72px;
          top: 12px;
        }

        small {
          display: block;
          font-size: 0.8em;
        }
      }

      h3 {
        font-size: 0.66em;
        font-family: 'db_ozone_x-medium';
      }

      p {
        margin-top: 26px;
        font-size: 0.5em;
      }

      .btn-read-more {
        margin-top: 72px;
        font-size: 0.56em;
      }
    }

    .owl-dots {
      position: relative;
      bottom: 0;
      margin-top: 17px;

      .owl-dot {
        background-color: #c7c5c6;
      }
      .owl-dot.active {
        background-color: $orange;
      }
    }
  }


  .section.-resources .page-center {
    width: 1240px;
    overflow: hidden;

    .head {
      margin: 0 30px;
    }
  }

  .section.-resources .page-center::before {
    background-color: $orange;
  }

  .list-resources {
    width: 1180px;
    max-width: 100%;
    margin: 60px auto 0;

    .owl-stage-outer {
      overflow: visible;
    }

    .item {
      width: 100%;

      a {
        width: 100%;
        display: block;
        position: relative;
        margin-bottom: 50px;
        box-shadow: 0 24px 35px rgba(0,0,0,.17);
      }

      img {
        width: 100%;
        margin: 0;
      }

      .text {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        background-color: $white;
        padding: 32px 0;

        span {
          width: 100%;
          display: block;
          padding: 0 20px;
          font-size: 3rem;
          font-family: 'db_ozone_x-bold';
          color: #555;
          text-align: center;
          text-transform: uppercase;
        }

        .text-view-all {
          min-width: 142px;
          max-width: 142px;
          height: 52px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $orange;
          margin-left: 32px;
          font-size: 1.8rem;
          font-family: 'db_ozone_x';
          color: $white;
          box-shadow: 0 6px 9px rgba(0,0,0,.17);
        }
      }

      .desktop & a:hover .text-view-all::after {
        margin-left: 15px;
      }
    }

    .owl-dots {
      position: relative;
      bottom: 0;
      margin-top: 10px;

      .owl-dot {
        width: 7px;
        height: 7px;
        background-color: #c6c5c5;
      }
      .owl-dot.active {
        background-color: $orange;
      }
    }
  }



  .section.-highlights {
    .page-center::before {
      background-color: $dark-blue;
    }

    .content-highlights {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
    }

    .slide-highlights {
      min-width: 558px;
      width: 558px;
      position: relative;

      .item {
        .img {
          position: relative;

          span {
            width: 176px;
            height: 47px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $dark-blue;
            position: absolute;
            right: 0;
            bottom: 0;
            padding-top: 5px;
            font-size: 2.4rem;
            color: $white;

            &::before {
              position: relative;
              top: -2px;
              margin-right: 12px;
              font-size: 18px;
            }
          }
        }

        .text {
          margin-top: 36px;

          .date {
            margin-bottom: 8px;
            font-size: 2.4rem;
            color: #c6c5c5;
          }

          h3 {
            margin: 0 0 26px;
            font-size: 3.8rem;
          }

          a {
            font-size: 2.4rem;
            color: $orange;

            .desktop &:hover {
              color: $black;
            }
          }
        }
      }

      .owl-nav {
        display: none !important;
      }

      .nav-control {
        display: none !important;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 0;
        bottom: 0;

        .prev,
        .next {
          width: 20px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 2px solid $orange;
          border-radius: 50%;
          transition: .3s $ease-out-back;

          .desktop &:hover {
            transform: scale(1.2);
          }

          &::before {
            content: '\67';
            @extend .add-font-icon;
            font-size: 13px;
            color: $orange;
          }

          &.next::before {
            content: '\68';
            text-indent: 2px;
          }
        }

        .dots {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 10px;
        }

        .owl-dot {
          width: 4px;
          height: 4px;
          background-color: $light-gray;
          border-radius: 50%;
          cursor: pointer;

          &.active {
            background-color: $orange;
          }
        }

        .owl-dot+.owl-dot {
          margin-left: 6px;
        }
      }
    }

    .list-highlights {
      width: calc(100% + 22px);
      justify-content: space-between;
      margin: 0;

      .item {
        width: calc(50% - 22px);
        border-left: 1px solid $light-gray;
        padding: 0 0 28px 22px;
        margin: 0 0 0 22px;

        h3 {
          min-height: 38px;
        }
      }
    }
  }



  .section.-upcoming-events {
    min-height: 720px;
    // border-top: 1px solid $light-gray;
    position: relative;
    margin-top: 50px;

    &::before {
      content: '';
      width: 2058px;
      height: 535px;
      background: url('../img/home/bg-upcoming-events.jpg') no-repeat;
      display: block;
      position: absolute;
      left: 50%;
      top: 130px;
      margin-left: -552px;
      z-index: 1;
    }

    .page-center::before {
      background-color: $dark-gray;
    }

    .nav-control {
      display: flex;

      div {
        width: 38px;
        height: 38px;
        display: block;
        border: 3px solid $orange;
        border-radius: 50%;
        cursor: pointer;
        transition: .25s ease;

        .desktop &:hover {
          transform: scale(1.2);
        }

        &::before {
          content: '\67';
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          @extend .add-font-icon;
          font-size: 24px;
          color: $orange;
        }

        &.owl-next::before {
          content: '\68';
          text-indent: 2px;
        }

        &.disabled {
          opacity: 0.2;
        }
      }

      div+div {
        margin-left: 6px;
      }
    }

    .owl-nav {
      display: none !important;
    }

    .content-upcoming-events {
      margin-top: 102px;

      .slide-upcoming-events {

        .owl-stage-outer {
          overflow: visible;
        }

        .item {
          @extend .box-shadow;
        }

        .img {
          width: 545px;
          height: 340px;
          display: block;
          position: relative;
          overflow: hidden;

          &::before {
            content: '';
            width: 100%;
            height: 100%;
            display: block;
            background-color: #363636;
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0.5;
            transition: .25s ease;
            z-index: 13;
          }

          span {
            width: 200px;
            height: 46px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $dark-orange;
            position: absolute;
            left: 38px;
            bottom: 0;
            font-size: 2.4rem;
            color: $white;
            z-index: 13;

            &::before {
              display: block;
              margin-right: 12px;
            }
          }
        }

        .text {
          position: absolute;
          left: 38px;
          bottom: 74px;
          font-size: 3.6rem;
          padding-right: 38px;
          color: $white;
          font-family: 'db_ozone_x-bold';
          z-index: 13;

          h3 {
            line-height: 0.7;
          }

          h4 {
            margin-bottom: 15px;
            font-size: 0.68em;
            color: $orange;
          }
        }

        /* .desktop & .item a:hover .img::before {
          opacity: 0;
        } */
      }
    }

    .dots {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 1313;

      .owl-dot {
        width: 46px;
        height: 2px;
        background-color: $light-gray;

        span {
          width: 0;
          height: 100%;
          display: block;
          background-color: $black;
          transition: .25s ease;
        }

        &.active span {
          width: 100%;
        }
      }
    }
  }



  .section.-key-projects {
    background: url('../img/home/bg-key-projects.png') 50% 0 no-repeat;
    margin-top: 80px;

    .page-center {
      width: 100%;
      max-width: inherit;
      padding-top: 162px;

      &::before,
      &::after {
        display: none;
      }
    }

    .nav-control {
      display: flex;
      position: absolute;
      left: 50%;
      top: 60px;
      margin-left: 500px;

      div {
        width: 38px;
        height: 38px;
        display: block;
        border: 3px solid $orange;
        border-radius: 50%;
        cursor: pointer;
        transition: .25s ease;

        .desktop &:hover {
          transform: scale(1.2);
        }

        &::before {
          content: '\67';
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          @extend .add-font-icon;
          font-size: 24px;
          color: $orange;
        }

        &.owl-next::before {
          content: '\68';
          text-indent: 2px;
        }

        &.disabled {
          opacity: 0.2;
        }
      }

      div+div {
        margin-left: 6px;
      }
    }

    .slide-key-projects .owl-stage-outer {
      overflow: visible;
    }

    .item {
      width: 1180px;
      max-width: 100%;
      display: flex;
      justify-content: space-between;
      margin: 0 auto;

      &::before {
        content: '';
        width: 59px;
        height: 30px;
        background-color: $gray;
        position: absolute;
        left: 50%;
        top: 138px;
        margin-left: -675px;
      }

      &::after {
        content: '';
        width: 52px;
        height: 9px;
        background-color: #ccc;
        position: absolute;
        left: 50%;
        top: 138px;
        margin-left: -590px;
      }

      h2 {
        margin-bottom: 62px;
        font-size: 9.27rem;
        font-family: 'db_ozone_x-bold';
        color: $orange;

        small {
          display: block;
          margin-top: -20px;
          font-size: 0.47em;
          color: $black;
        }
      }
    }

    .detail {
      width: 570px;

      .scroll {
        height: 350px;

        .jspVerticalBar {
          &::before,
          &::after {
            background-color: #fff;
          }
        }
      }

      .area-content {
        padding-right: 40px;
      }

      p,
      li {
        font-size: 2.2rem;
        line-height: 1.3;
      }

      p+p {
        margin-top: 38px;
      }

      .list-number {
        margin-top: 5px;
      }

      li+li {
        margin-top: 5px;
      }
    }

    .box-img {
      width: 580px;
      min-width: 580px;
      height: 515px;
      background-color: $white;
      position: relative;
      margin: 44px 4px 0 0;
      @extend .box-shadow;

      h2 {
        display: none;
      }

      .img {
        position: relative;
        right: -60px;
        top: -74px;
      }

      .text {
        width: 100%;
        height: 72px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        left: 0;
        bottom: 0;
        padding: 0 5px 0 20px;
        font-family: 'db_ozone_x-medium';
      }

      .text.video {
        &>a {
          width: calc(100% - 88px);
          display: flex;
          align-items: center;

          &::before {
            content: '\6a';
            width: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 2px solid $black;
            border-radius: 50%;
            margin-right: 15px;
            @extend .add-font-icon;
            font-size: 12px;
            text-indent: 4px;
            color: $black;
          }
        }
      }

      .control {
        min-width: 88px;
        display: flex;
        align-items: center;
        margin-left: 20px;

        p {
          margin-right: 10px;
          font-size: 2.2rem;
        }

        a {
          &::before {
            content: '\67';
            @extend .add-font-icon;
            font-size: 22px;
            color: #6d7174;
          }

          &.next::before {
            content: '\68';
          }
        }
      }
    }
  }



  .section.-subscribe {
    margin-bottom: 170px;

    .page-center {
      &::before {
        width: 544px;
        height: 490px;
        top: 120px;
      }

      &::after {
        display: none;
      }

      .content-subscribe {
        position: relative;
        z-index: 13;
      }

      .box-subscribe {
        height: 400px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: $white;
        position: absolute;
        right: 64px;
        top: 50%;
        transform: translateY(-50%);
        padding: 0 30px;
        font-size: 4.2rem;
        text-align: center;

        h3 {
          font-size: 1em;
          line-height: 1.1;

          strong {
            font-size: 1.18em;
            font-family: 'db_ozone_x';
          }
        }

        .btn-style {
          margin-top: 40px;
        }
      }
    }
  }



  .section.-movements {
    .page-center::before {
      background-color: $orange;
    }

    .content-movements {
      position: relative;
      margin-top: 20px;
      z-index: 13;
    }
  }
}










@media screen and (min-width:1400px) {
  .home-page {
    .section.-highlight-banner {

      .box-text,
      .nav-control {
        // transform: translateY(-50%) scale(1.2);
      }
    }
  }
}





@media screen and (max-width:1024px) {
  .home-page {
    .section {
      &.-highlight-banner {
        .box-text {
          width: 500px;
          padding: 40px;
          font-size: 4rem;

          &::before,
          &::after {
            width: 30px;
            height: 30px;
            left: 0;
            top: -60px;
          }
          &::after {
            left: 30px;
            top: -30px;
          }

          h2::before {
            width: 28px;
            top: 9px;
            left: -40px;
          }
        }
      }



      &.-highlights {
        .slide-highlights {
          width: 50%;
          min-width: 50%;
        }
      }



      &.-resources .page-center {
        overflow: visible;

        .head {
          margin: 0;
        }
      }

      .list-resources {
        .item .text {
          span {
            font-size: 2.6rem;
          }

          .text-view-all {
            margin-left: 10px;
            font-size: 1.8rem;
          }
        }
      }



      &.-key-projects {
        .page-center {
          padding-top: 72px;
        }

        .nav-control {
          left: auto;
          right: 15px;
          margin-left: 0;
        }

        .content-key-projects {
          width: calc(100% - 30px);
          margin: 0 auto;
          overflow: hidden;
        }

        .detail {
          width: 400px;

          h2 {
            margin-bottom: 40px;
            font-size: 8rem;
          }


          .scroll {
            height: 400px;
          }
        }

        .box-img {
          width: 540px;
          min-width: inherit;
          height: auto;
          align-self: baseline;
          box-shadow: none;

          .img {
            left: 0;
            top: 0;
          }

          .text {
            position: relative;
            bottom: 0;
          }
        }
      }



      &.-subscribe {
        margin-bottom: 140px;

        .page-center {
          &::before {
            height: 420px;
          }
        }
      }
    }
  }
}





@media only screen and (min-width:1024px) and (max-width:1366px) and (orientation:landscape) and (-webkit-min-device-pixel-ratio:1.5) {
  .home-page {
    .section.-highlight-banner {
      width: 100%;
      height: auto;
      overflow: hidden;

      .owl-carousel,
      .owl-stage-outer,
      .item,
      .img {
        height: auto;
      }
    }
  }
}





@media only screen and (min-width:1024px) and (max-width:1366px) and (orientation:portrait) and (-webkit-min-device-pixel-ratio:1.5) {
  .home-page {
    .section.-highlight-banner {
      width: 100%;
      height: auto;
      overflow: hidden;

      .owl-carousel,
      .owl-stage-outer,
      .item,
      .img {
        height: auto;
      }
    }
  }
}





@media only screen and (max-width:1023px) {
  .home-page {
    .section.-highlight-banner {
      width: 100%;
      height: auto;
      overflow: hidden;

      .owl-carousel,
      .owl-stage-outer,
      .item,
      .img {
        height: auto;
      }
    }
    
    .section {
      &.-highlight-banner {
        .nav-control {
          right: 20px;
          top: auto;
          bottom: 20px;
          transform: translateY(0);
        }
      }



      &.-highlights {
        .slide-highlights {
          width: 45%;
          min-width: 45%;

          .item {
            .text {
              h3 {
                font-size: 3rem;
              }
            }
          }
        }
      }



      &.-key-projects {
        .page-center {
          padding-top: 30px;
        }

        .content-key-projects {
          width: calc(100% - 30px);
          margin: 0 auto;
          overflow: hidden;
        }

        .item {
          flex-wrap: wrap;

          &::before,
          &::after {
            display: none;
          }
        }

        .detail {
          width: 100%;
          order: 1;
          margin-top: 20px;

          h2 {
            display: none;
          }
        }

        .box-img {
          width: 100%;
          min-width: inherit;
          background: none;

          h2 {
            display: block;
            margin-bottom: 10px;
          }
        }

        .nav-control {
          top: 20px;
          z-index: 13;
        }
      }



      &.-subscribe {
        margin-bottom: 140px;

        .page-center {
          &::before {
            height: 320px;
          }

          .box-subscribe {
            right: 0;
          }
        }
      }
    }
  }
}





@media only screen and (max-width:767px) {
  .home-page {
    .section {
      &.-highlight-banner {
        .box-text {
          width: 100%;
          position: relative;
          left: 0;
          bottom: 0;

          &::before,
          &::after {
            display: none;
          }
        }
      }



      &.-highlights {
        .content-highlights {
          flex-wrap: wrap;
        }

        .slide-highlights {
          width: 100%;
          min-width: 100%;
        }

        .list-highlights {
          margin: 18px 0 0 -22px;

          .item {
            border: none;
            padding: 0;
            margin-top: 22px;
          }
        }
      }



      .list-resources {
        .item .text {
          flex-direction: column;
          align-items: center;
          justify-content: center;

          span {
            text-align: center;
          }

          .text-view-all {
            width: calc(100% - 30px);
            max-width: inherit;
            margin: 20px 0 0;
          }
        }
      }



      &.-upcoming-events {
        min-height: auto;
        padding-bottom: 80px;
        overflow: hidden;

        .page-center {
          &::after {
            display: none;
            top: 78px;
          }
        }

        .content-upcoming-events {
          margin-top: 20px;

          .slide-upcoming-events {
            .img {
              width: 400px;
              height: 250px;
            }
            .text {
              font-size: 3rem;
            }
          }
        }

        .dots {
          background-color: #fff;
          padding: 20px 0;
        }
      }



      &.-subscribe {
        margin-bottom: 0;

        .page-center {
          &::before {
            display: none;
          }

          .box-subscribe {
            width: 100%;
            height: auto;
            position: relative;
            right: auto;
            left: 0;
            top: 0;
            transform: translateY(0);
            padding: 40px 0;
          }
        }
      }
    }
  }
}





@media only screen and (max-width:479px) {
  .home-page {
    .section {
      &.-highlight-banner {
        .box-text {
          padding: 20px 40px;
          font-size: 2.8rem;

          h2::before {
            top: 6px;
          }
        }

        .nav-control {
          width: calc(100% - 40px);
          position: relative;
          right: 0;
          bottom: 0;
          margin: 0 auto;
          margin-top: 20px;
          font-size: 1.8rem;
          color: #000;

          .progress {
            background-color: $gray;

            .bar {
              background-color: $dark;
            }
          }

          .control {
            .prev,
            .next {
              width: 30px;
              height: 30px;
              border-color: $gray;

              &::before {
                font-size: 16px;
                color: $gray;
              }
            }

            .dots {
              .owl-dot {
                background-color: $gray;
              }
            }
          }
        }
      }



      &.-highlights {
        .list-highlights {
          width: 100%;
          margin-left: 0;

          .item {
            width: 100%;
            margin-left: 0;
          }
        }
      }



      &.-upcoming-events {
        &::before {
          top: 160px;
        }

        .page-center {
          &::after {
            display: none;
            top: 78px;
          }
        }

        .content-upcoming-events {
          .slide-upcoming-events {
            .img {
              width: 290px;
              height: 181px;

              span {
                left: 0;
                font-size: 1.8rem;
              }
            }
            .text {
              left: 10px;
              bottom: 61px;
              padding-right: 10px;
              font-size: 2rem;

              h3 {
                line-height: 1;
              }
            }
          }
        }
      }



      &.-key-projects {
        .item {
          h2 {
            margin-bottom: 30px;
            font-size: 6rem;
            line-height: 0.7;

            small {
              margin-top: 10px;
              font-size: 0.55em;
            }
          }
        }

        .box-img {
          .text {
            flex-wrap: wrap;
            padding: 0;
            margin-top: 10px;
          }

          .control {
            margin: 0;
          }
        }

        .detail {
          margin-top: 20px;

          li,
          p {
            font-size: 1.8rem;
          }
        }
      }



      &.-subscribe {
        margin-bottom: 0;

        .page-center {
          &::before {
            display: none;
          }

          img {
            height: 180px;
            object-fit: cover;
          }

          .box-subscribe {
            font-size: 3.6rem;

            .btn-style {
              width: 100%;
            }
          }
        }
      }
    }
  }
}