.accordion {
  min-width: 800px;
  width: 800px;

  & > .item {
    width: 100%;
    border-bottom: 1px solid #dce1e5;
    padding-bottom: 28px;

    & > a {
      display: block;
      position: relative;
      padding-right: 50px;
      margin-bottom: 0;
      font-size: 30px;
      font-family: "db_ozone_x-medium";
      color: #8d959f;

      .text-large & {
        font-size: 32px;
      }

      &::after {
        content: "+";
        width: 22px;
        height: 30px;
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        font-size: 47px;
        line-height: 30px;
        text-align: center;
        color: $orange;
      }
    }

    .item-content {
      display: none;
      margin-top: 25px;
      color: #919195;
      overflow: hidden;
    }

    &.active {
      & > a {
        color: $orange;
      }

      & > a::after {
        content: "";
        width: 22px;
        height: 3px;
        background-color: $orange;
        right: 0;
        top: 14px;
      }

      .item-content {
        display: block;
      }
    }
  }

  .item + .item {
    margin-top: 28px;
  }
}

@media screen and (max-width: 1024px) {
  .accordion {
    min-width: 670px;
    width: 670px;
  }
}

@media screen and (max-width: 1023px) {
  .accordion {
    min-width: inherit;
    width: 100%;
    margin-top: 40px;
  }
}

@media screen and (max-width: 479px) {
  .accordion {
    & > .item > a {
      font-size: 24px;

      .text-large & {
        font-size: 26px;
      }
    }
    & > .item > a::after {
      font-size: 36px;
      line-height: 24px;
    }
    & > .item.active > a::after {
      width: 14px;
      right: 4px;
      top: 12px;
    }
  }
}
