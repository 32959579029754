.areas-of-work-page {
  .content-areas-of-work {
    .page-center {
      display: flex;
      flex-wrap: wrap;
    }



    .list-areas-of-work {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin: 85px 0 0 -15px;

      .item {
        width: calc(33.33% - 15px);
        margin: 15px 0 0 15px;
        box-shadow: 0 6px 5px rgba(0, 0, 0, 0.17);

        img {
          width: 100%;
        }

        a {
          width: 100%;
          height: 100%;
          display: block;
          position: relative;

          &::before {
            content: '';
            width: 100%;
            height: 185px;
            display: block;
            background: url('../img/areas-of-work/overlay.png') repeat-x;
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: 13;
          }

          .text {
            width: calc(100% - 40px);
            height: 50px;
            display: flex;
            align-items: center;
            position: absolute;
            left: 20px;
            bottom: 40px;
            padding-right: 75px;
            font-size: 2.5rem;
            font-family: 'db_ozone_x-bold';
            color: #fff;
            z-index: 13;

            &::after {
              content: '\68';
              width: 38px;
              height: 38px;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: $orange;
              border-radius: 50%;
              position: absolute;
              right: 15px;
              top: 50%;
              margin-top: -19px;
              @extend .add-font-icon;
            }
          }
        }
      }
    }



    .slide-related-areas-of-work {
    	margin: 0;

      .item {
      	padding-bottom: 10px;

      	a {
	        box-shadow: 0 6px 5px rgba(0, 0, 0, 0.17);

	        &::before {
	        	content: '';
	        	width: 100%;
	        	height: 100%;
	        	display: block;
	        	background-color: rgba(54,54,54,0.3);
	        	position: absolute;
	        	left: 0;
	        	bottom: 0;
	        	transition: .25s ease;
	        	opacity: 1;
	        	z-index: 13;
	        }

	        .desktop &:hover::before {
	        	opacity: 0;
	        }
      	}
      }

      .text {
        position: absolute;
        left: 0;
        bottom: 0;
        padding: 24px;
        font-family: 'db_ozone_x-bold';
        color: #fff;
        z-index: 13;
        pointer-events:none;

        h3 {
          margin: 0;
          font-size: 2.8rem;
        }
      }

      .owl-dots {
        position: relative;
        bottom: 0;
        margin-top: 30px;

        .owl-dot {
          background-color: #c7c5c6;
        }
        .owl-dot.active {
          background-color: $orange;
        }
      }
    }
  }
}










@media screen and (max-width:1024px) {
  .areas-of-work-page {
    .content-areas-of-work {
      .list-areas-of-work {
        .item {
          a {
            .text {
              height: auto;
              min-height: 40px;

              br {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}





@media screen and (max-width:1023px) {
  .areas-of-work-page {
    .content-areas-of-work {
      .list-areas-of-work {
        .item {
          a {
            .text {
              width: 100%;
              left: 0;
              bottom: 20px;
              padding-left: 20px;
              font-size: 2rem;
            }
          }
        }
      }
    }
  }
}





@media screen and (max-width:767px) {
  .areas-of-work-page {
    .content-areas-of-work {
      .list-areas-of-work {
        width: auto;
        margin-top: 40px;

        .item {
          width: calc(50% - 15px);
        }
      }
    }
  }
}





@media screen and (max-width:479px) {
  .areas-of-work-page {
    .content-areas-of-work {
      .list-areas-of-work {
        margin-left: 0;

        .item {
          width: 100%;
          margin-left: 0;
        }
      }
    }
  }
}