.contact-us-page {
  #footer {
    margin-top: 0;
  }



  .content-contact-us {
    margin-top: 94px;
    display: flex;

    .location,
    .contact {
      width: 50%;
    }



    .location {
      .map {
        height: 520px;
        position: relative;

        .btn-style {
          width: 220px;
          height: 62px;
          position: absolute;
          right: 0;
          bottom: 0;

          &::before {
            margin-right: 15px;
          }
        }
      }

      .detail {
        height: 100%;
        background-color: $orange;
        padding: 42px 72px 42px 90px;
        color: $white;
        font-size: 2.4rem;

        h2 {
          font-size: 1.25em;
          font-family: 'db_ozone_x-medium';
        }

        p {
          position: relative;
          padding-left: 25px;
          margin-top: 40px;
          line-height: 1.4;

          &::before {
            position: absolute;
            left: 0;
            top: 5px;
            font-size: 18px;
          }

          &.icon-telephone::before {
            font-size: 20px;
          }
        }
      }
    }



    .contact {
      padding: 76px 0 0 90px;

      h2 {
        font-size: 4.8rem;
        font-family: 'db_ozone_x-bold';
      }

      .form-contact {
        width: 420px;
        max-width: 100%;
        margin-top: 50px;

        .agree {
          justify-content: flex-start;

          .col {
            width: 100px;
            margin: 10px 0 20px;
          }

          label {
            font-family: 'db_ozone_x-bold';
          }

          p {
            width: 100%;
            font-size: 2rem;
            font-family: 'db_ozone_x-medium';

            strong {
              font-size: 1.1em;
              font-family: 'db_ozone_x-bold';
            }
          }
        }

        .btn-style {
          width: 216px;
          margin: 0;
        }
      }
    }
  }
}










@media screen and (max-width:1024px) {
  .contact-us-page {
    .content-contact-us {
      .location {
        .detail {
          padding: 40px;

          p {
            margin-top: 20px;
          }
        }
      }
    }
  }
}





@media screen and (max-width:1023px) {
  .contact-us-page {
    .content-contact-us {
      flex-wrap: wrap;

      .location {
        width: 100%;

        .detail {
          height: auto;
        }
      }

      .contact {
        width: 100%;
        padding: 40px;

        .form-contact {
          width: 100%;
        }
      }
    }
  }
}





@media screen and (max-width:479px) {
  .contact-us-page {
    .content-contact-us {
      .location {
        .map {
          height: 300px;
        }

        .detail {
          padding: 30px 20px;
          font-size: 1.8rem;

          p::before {
            font-size: 10px;
          }
          p.icon-telephone::before {
            font-size: 14px;
          }
        }
      }

      .contact {
        padding: 30px 20px;

        h2 {
          font-size: 4rem;
        }

        .form-contact {
          margin-top: 20px;

          .btn-style {
            width: 100%;
          }
        }
      }
    }
  }
}