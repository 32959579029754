#wrapper.-detail {
  #container .page-center {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 82px 0 40px;
  }



  .secondary {
    width: 318px;
    min-width: 318px;
    font-size: 1.8rem;

    &>div+div {
      margin-top: 52px;
    }

    h3 {
      padding-bottom: 22px;
      margin-bottom: 22px;
      font-size: 2em;
      font-family: 'db_ozone_x-bold';
    }

    .box-share {
      .group-btn {
        display: flex;
        flex-wrap: wrap;
      }

      a {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #909090;
        border-radius: 50%;
        color: #888;

        .desktop &:hover {
          background-color: $orange;
          border-color: $orange;
          color: #fff;
        }
      }

      a+a {
        margin-left: 22px;
      }
    }

    .box-most-viewed {
      .items {
        counter-reset: number;
        margin-top: 30px;

        .item {
          border-bottom: 1px solid #dce1e5;
          position: relative;
          padding: 4px 0 15px 46px;
          margin-bottom: 25px;

          &::before {
            counter-increment: number;
            content: counters(number, '.');
            position: absolute;
            left: 12px;
            top: 0;
            font-size: 2.7em;
            color: #888;
          }

          h4 {
            margin-bottom: 6px;
            font-size: 1.35em;
            font-family: 'db_ozone_x-bold';
          }

          p.icon-clock {
            min-height: 18px;
            position: relative;
            padding-left: 25px;

            &::before {
              position: absolute;
              left: 0;
              top: 1px;
              font-size: 15px;
              color: $dark-blue;
            }
          }
        }

        .item:first-child {
          &::before {
            color: #000;
          }
        }
      }
    }
  }



  .primary {
    width: calc(100% - 366px);

    .editor {
      font-size: 2.2rem;
      line-height: 1.6;
      color: #2c2c2d;

      h3 {
      	margin-bottom: 15px;
      	font-size: 1.36em;
      	font-family: 'db_ozone_x-bold';
      }

      p {
      	margin: 0;
        font-size: 1em;
      }

      strong {
        font-family: 'db_ozone_x-medium';
      }

      ul {
      	margin: 0;

      	li {
      		padding-left: 20px;

      		&::before {
	      		background-color: $orange;
	      		top: 13px;
	      	}
      	}
      	li + li {
      		margin-top: 0;
      	}
      }

      ol {
      	margin: 0;
      }
    }
  }



  .gallery {
    width: 100%;
    margin-top: 60px;

    h3 {
      padding-bottom: 20px;
      margin-bottom: 36px;
      font-size: 3.6rem;
      font-family: 'db_ozone_x-bold';
    }

    .items {
      display: flex;
      flex-wrap: wrap;
      margin: -15px 0 0 -15px;
    }

    .item {
      width: calc(25% - 15px);
      position: relative;
      margin: 15px 0 0 15px;

      a {
        border: 3px solid #ccc;

        .desktop &:hover {
          border-color: $orange;
          transition: .25s ease;
        }

        .number {
        	width: 100%;
        	height: 100%;
        	display: none;
        	flex-direction: column;
        	justify-content: center;
        	position: absolute;
        	left: 0;
        	top: 0;
        	text-align: center;
        	font-size: 3.7rem;
        	font-family: 'db_ozone_x-medium';
        	color: #fff;
        	z-index: 13;

        	strong {
        		display: block;
        		margin-bottom: -15px;
        		font-size: 2.2em;
        	}
        }
      }
    }

    .item+.item+.item+.item+.item {
      display: none;
    }

    .item:nth-of-type(4) {
      a {
        position: relative;

        &::before {
          content: '';
          width: 100%;
          height: 100%;
          display: block;
          background-color: rgba(54, 54, 54, 0.3);
          position: absolute;
          left: 0;
          top: 0;
          opacity: 1;
          z-index: 13;
        }

        .number {
        	display: flex;
        }
      }
    }
  }



  .related {
  	width: 100%;
  	border-top: 1px solid #dce1e5;
  	padding-top: 60px;
  	margin-top: 60px;

  	h2 {
  		margin-bottom: 34px;
  		font-size: 6rem;
  		font-family: 'db_ozone_x-bold';
  	}

  	.list-content {
  		position: relative;

  		&::before {
  		  content: '';
  		  width: 74px;
  		  height: 37px;
  		  display: block;
  		  background-color: $orange;
  		  position: absolute;
  		  left: -108px;
  		  top: 0;
  		}
  	}
  }
}










@media screen and (max-width:1023px) {
  #wrapper.-detail {
    .primary {
      width: 100%;
    }

    .secondary {
      width: 100%;
      display: flex;
      order: 1;
      margin-top: 60px;

      .box-most-viewed {
        margin-top: 0;
        margin-left: 40px;
      }
    }


    .gallery {
      .item {
        a {
          .number {
            font-size: 2.4rem;
          }
        }
      }
    }



    .related {
      order: 1;
    }
  }
}





@media screen and (max-width:767px) {
  #wrapper.-detail {
    #container {
      .page-center {
        padding-top: 40px;
      }
    }


    .banner-page {
      .box-text {
        a {margin-top: 0;}
      }

      .btn-learn-more {margin-top: 20px !important;}
    }


    .secondary {
      h3 {
        font-size: 1.8em;
      }

      .box-share {
        width: 300px;
      }
    }



    .gallery {
      .item {
        a {
          .number {
            font-size: 1.8rem;
          }
        }
      }
    }
  }
}





@media screen and (max-width:479px) {
  #wrapper.-detail {
    #container {
      .page-center {
        padding-top: 40px;
      }
    }

    .secondary {
      min-width: inherit;
      flex-wrap: wrap;

      h3 {
        font-size: 1.8em;
      }

      .box-share {
        width: 100%;
      }

      .box-most-viewed {
        width: 100%;
        margin: 40px 0 0;
      }
    }



    .gallery {
      .item {
        width: calc(50% - 15px);

        a {
          .number {
            font-size: 1.8rem;
          }
        }
      }
    }
  }
}